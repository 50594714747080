// @flow

import React, { PureComponent } from 'react';
import {
  TransformWrapper,
  TransformComponent,
} from '../ZoomPanPinch';

import { MapMarker } from '../MapMarker';
import { MapBackground } from '../MapBackground';
import { StateTooltip } from '../StateTooltip';

import {
  MapWrapper,
  TransformContainer,
  SvgContainer,
  SvgBackground,
  SvgWater,
  MarkerButton,
  SvgHawaii,
} from './style';

import mapBackground from '../../assets/map-background-countries-compressor-s.png';
import mapHawaii from '../../assets/hawaii.png';
import mapWater from '../../assets/map-background-water-compressor.jpg';

import { ARTISTS_MARKERS_ARRAY } from '../../constants/artist';

type State = {
  shouldTransition: boolean,
  active: string,
  shouldPinTransition: boolean,
  filter: string[],
  activeRef: any,
  loaded: boolean,
};

type Props = {
  handleActiveChange: Function,
  shouldResetMap: boolean,
  shouldFilterResetMap: boolean,
  handleAfterMapReset: Function,
  handleAfterFilterReset: Function,
  selectedTraditionsFilter: string[],
  selectedCelebrationsFilter: Object,
}

export default class MapComponent extends PureComponent<Props, State> {
  markerRefs: { [key: string]: HTMLElement | null } = {};

  state = {
    shouldTransition: true,
    active: '',
    shouldPinTransition: false,
    filter: ['all'],
    activeRef: null,
    loaded: false,
  };

  componentDidMount() {
    const imageList = [mapBackground, mapWater];
    imageList.forEach((image) => {
      const img = new Image();
      img.src = image;
    });

    this.setState(() => ({
      loaded: true,
    }));
  }


  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      handleActiveChange,
      shouldResetMap,
      handleAfterMapReset,
      shouldFilterResetMap,
      handleAfterFilterReset,
      selectedCelebrationsFilter,
      selectedTraditionsFilter,
    } = this.props;

    const MOBILE_SIZE = 720;
    const isMobile = window.innerWidth <= MOBILE_SIZE;

    if (shouldResetMap && !prevProps.shouldResetMap) {
      this.resetActiveArtist();
      handleActiveChange('');
      handleAfterMapReset();
    }

    if (shouldFilterResetMap && !prevProps.shouldFilterResetMap) {
      handleAfterFilterReset();
    }


    if (selectedCelebrationsFilter !== prevProps.selectedCelebrationsFilter) {
      this.updateFilterState();
      // Adjust the comparison to match the property of the celebration objects
      const firstVisibleMarkerInFilter = ARTISTS_MARKERS_ARRAY.find(
        (artist) => artist.celebrations
          && artist.celebrations.includes(selectedCelebrationsFilter.filterValue),
      );
      this.panToMarkerForCelebrations(firstVisibleMarkerInFilter);
    }


    if (
      prevProps.selectedTraditionsFilter !== selectedTraditionsFilter
      || prevProps.selectedCelebrationsFilter !== selectedCelebrationsFilter
    ) {
      this.updateFilterState();
    }

    if (isMobile) {
      const { filter } = this.state;
      if (filter !== prevState.filter) {
        let firstVisibleMarkerInFilter = null;
        if (!filter.includes('all')) {
          firstVisibleMarkerInFilter = ARTISTS_MARKERS_ARRAY
            .find((artist) => filter.some((tradition) => artist.tradition.includes(tradition)));
        }
        this.panToMarker(firstVisibleMarkerInFilter);
      }
    }
  }

  panToMarker = (markerId: Object) => {
    if (!markerId) return;

    const markerRef = this.markerRefs[markerId.name];
    if (markerRef) {
      const container = document.getElementById('react-transform-element');
      if (container) {
        const { x, y } = markerRef.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const newPositionX = containerRect.width / 2 - x - markerRef.offsetWidth / 2;
        const newPositionY = containerRect.height / 2 - y - markerRef.offsetHeight / 2;

        container.style.transform = `translate3d(${newPositionX}px, ${newPositionY}px, 0)`;
      }
    }
  };


// Adjust panToMarkerForCelebrations to handle resetting
panToMarkerForCelebrations = (markerId: Object) => {
  if (!markerId) return;

  const { selectedCelebrationsFilter } = this.props;
  const markerRef = this.markerRefs[markerId.name];
  if (markerRef) {
    const container = document.getElementById('react-transform-element');
    if (container) {
      const { x } = markerRef.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      let newPositionX = containerRect.width / 2 - x - markerRef.offsetWidth / 2;
      const newPositionY = parseFloat(container.style.transform.split(',')[1].trim());

      if (selectedCelebrationsFilter.filterValue !== 'all' && ['navaratri', 'lunarNewYear', 'internationalChildrensDay', 'fullMoonFestival', 'kingQuangTrungFestival'].includes(selectedCelebrationsFilter.filterValue)) {
        newPositionX -= 100;
      } else {
        newPositionX = 0; // reset to default position if not a specific offset celebration
      }

      container.style.transform = `translate3d(${newPositionX}px, ${newPositionY}px, 0)`;
    }
  }
};


  updateFilterState = () => {
    const { selectedTraditionsFilter, selectedCelebrationsFilter } = this.props;

    let newFilter = [];

    if (selectedCelebrationsFilter.filterValue !== 'all') {
      newFilter = [selectedCelebrationsFilter.filterValue];
    } else if (!selectedTraditionsFilter.includes('all')) {
      newFilter = selectedTraditionsFilter;
    } else {
      newFilter = ['all'];
    }

    this.setState({ filter: newFilter });
  };

  resetActiveArtist = () => {
    this.setState({
      active: '',
      filter: ['all'],
      activeRef: '',
    });
  };

  handleMarkerClick = (artist: Object) => {
    const { handleActiveChange } = this.props;
    this.setState({
      shouldPinTransition: true,
      active: artist.name,
      activeRef: this.markerRefs[artist.name],
    });
    handleActiveChange(artist.name);
  };

  handleTransitionStart = () => this.setState(() => ({
    shouldTransition: false,
  }));

  handleTransitionEnd = () => this.setState(() => ({
    shouldTransition: true,
    shouldPinTransition: false,
  }));


  render() {
    const {
      shouldTransition,
      shouldPinTransition,
      active,
      filter,
      activeRef,
      loaded,
    } = this.state;

    const {
      shouldResetMap,
      shouldFilterResetMap,
      selectedTraditionsFilter,
      selectedCelebrationsFilter,
    } = this.props;

    let filteredMarkers;

    if (selectedCelebrationsFilter.filterValue !== 'all') {
      filteredMarkers = ARTISTS_MARKERS_ARRAY.filter((artist) => {
        if (!artist.celebrations) return false;
        return artist.celebrations.includes(selectedCelebrationsFilter.filterValue);
      });
    } else if (selectedTraditionsFilter.includes('all')) {
      filteredMarkers = ARTISTS_MARKERS_ARRAY;
    } else {
      filteredMarkers = ARTISTS_MARKERS_ARRAY.filter((artist) => selectedTraditionsFilter
        .some((tradition) => artist.tradition.includes(tradition)));
    }

    return (
      <MapWrapper Loaded={loaded}>
        <TransformContainer shouldTransition={shouldTransition}>
          <TransformWrapper
            onWheelStart={this.handleTransitionStart}
            onWheelStop={this.handleTransitionEnd}
            onPanningStart={this.handleTransitionStart}
            onPanningStop={this.handleTransitionEnd}
            onPinchingStart={this.handleTransitionStart}
            onPinchingStop={this.handleTransitionEnd}
            activeRef={activeRef}
            shouldReset={shouldResetMap}
            shouldFilterResetMap={shouldFilterResetMap}
          >
            <TransformComponent>
              <SvgContainer
                Active={active}
              >
                {
                  filteredMarkers.map((artist) => (
                    <MarkerButton
                      key={artist.name}
                      Artist={artist.name}
                      onClick={() => this.handleMarkerClick(artist)}
                          // $FlowFixMe
                      ref={(el) => (this.markerRefs[artist.name] = el)} //eslint-disable-line
                      shouldPinTransition={shouldPinTransition}
                      Active={active}
                      Filter={filter}
                      Program={artist.tradition}
                      celebrations={artist.celebrations}
                    >
                      <MapMarker
                        id={artist.name}
                        image={artist.image}
                        name={artist.fullName}
                        genre={artist.genre}
                      />
                    </MarkerButton>
                  ))
                }
                {
                  ARTISTS_MARKERS_ARRAY.map((artist) => (
                    artist.region.map((genre, index) => (
                      <StateTooltip
                        key={genre}
                        text={genre}
                        classname={
                          // eslint-disable-next-line max-len
                          `state-tooltip state-tooltip--${artist.name} state-tooltip--${artist.name}-${index + 1}`
                        }
                      />
                    ))
                  ))
              }
                <SvgBackground
                  src={mapBackground}
                  alt='background'
                  rel='preload'
                />
                <SvgHawaii
                  src={mapHawaii}
                  alt='background'
                  rel='preload'
                />
                <SvgWater
                  src={mapWater}
                  alt='background'
                  rel='preload'
                />
                <MapBackground />
              </SvgContainer>
            </TransformComponent>
          </TransformWrapper>
        </TransformContainer>
      </MapWrapper>
    );
  }
}
