// @flow

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --scaleValueNormal: 1;
    --scaleValueLarge: 1.2;
  }

  html {
    box-sizing: border-box;
    height: 100%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamily1};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    color: #000000;
    height: 100%;
  }

  main {
    height: 100%;
  }

  #root {
    height: 100%;
  }

  button {
    cursor: pointer;
  }
`;
