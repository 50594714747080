// @flow

import React from 'react';

import { CelebrationHeader } from '../CelebrationHeader';
import { CelebrationPerformances } from '../CelebrationPerformances';
import { ExploreButtonContainer } from '../Hotspot/style';
import { ExploreButton } from '../CelebrationHeader/style';

  type Props = {
    activeData: Object,
    selectedCelebration: string,
    handleExploreClick: Function,
  };

export const CelebrationArtistHotspot = ({
  activeData,
  selectedCelebration,
  handleExploreClick,
}: Props) => {
  if (!activeData) {
    return null;
  }

  return (
    <>
      {activeData.introVideo && (
      <CelebrationHeader
        headline={activeData.introVideo.headline}
        selectedCelebration={selectedCelebration}
        handleExploreClick={handleExploreClick}
        artistName={activeData.name}
        headerImage={activeData.headerImages[1]}
        description={activeData.traditionBlurb}
      />
      )}
      {activeData.celebrationVideos && (
        <CelebrationPerformances
          artistName={activeData.name}
          performances={activeData.celebrationVideos}
          selectedCelebration={selectedCelebration}
        />
      )}
      <ExploreButtonContainer>
        <ExploreButton onClick={handleExploreClick}>
          Meet {activeData.name}
        </ExploreButton>
      </ExploreButtonContainer>
    </>
  );
};
