import smallHeaderImage from '../assets/hotspot/headerImages/qian-yi-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/qian-yi-l.jpg';
import mus1 from '../assets/hotspot/pins/michael.jpg';
import mus2 from '../assets/hotspot/pins/layth.jpg';
import introImage from '../assets/hotspot/intro/qian_yi.png';
import postcard from '../assets/hotspot/postcardPdf/Qian_Yi_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/QianYiPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/qianyi_concert.png';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/qian-yi-gong-xi.png';

export const qianYi = {
  name: 'Qian Yi',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Qian Yi, who sings Chinese traditional music!',
    details: '',
    vimeoLink: 'https://vimeo.com/188192544',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/ODKQNw8djqs',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Six/Qian-Yi',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Qian Yi sing “Mo Li Hua”',
      link: 'Learn “Mo Li Hua”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Six/Qian-Yi/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/603905091%3Fsecret_token%3Ds-Fr97a&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Qian Yi sing “Gong Xi, Gong Xi”',
      link: 'Learn “Gong Xi, Gong Xi”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Six/Qian-Yi/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/603905073%3Fsecret_token%3Ds-Sertf&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Qian Yi appears on stage with',
    artists: [
      {
        id: 1,
        image: mus1,
        name: 'Michael',
        query: 'michael',
      },
      {
        id: 2,
        image: mus2,
        name: 'Layth',
        query: 'layth',
      },
    ],
    linkOutText: 'Learn more about Qian Yi in Musical Explorers Program Six.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Six/',
  },
  traditionBlurb: 'Chinese traditional music refers to an array of musical traditions performed primarily in rural areas and communities. These traditions included styles of Chinese opera that were often unique from the operas performed for the emperors. Even when performed by folk troupes in rural towns, Chinese opera brings music together with dance, pantomime, acting, costuming, and staging.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/Yq8I-_bK4sU',
      videoImage: celebrationThumbnail,
      songTitle: 'Gong Xi, Gong Xi',
      artistName: 'Qian Yi',
      videoTags: [
        {
          tag: 'Lunar New Year',
        },
      ],
      songDescription: 'The song “Gong Xi, Gong Xi” is most often associated with the Lunar New Year in China. While the lyrics are celebratory in nature, the original composer, Chen Ge Xin, actually wrote this song at the culmination of the Second Sino-Japanese War in 1945. The references to the “snow melting” and “time for spring” are allegories to the end of a difficult time of turmoil in China, with hope for starting over again. Because of these lyrics, the song became a part of the Chinese New Year repertoire.',
    },
  ],
};
