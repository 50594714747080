import styled from 'styled-components/macro';
import { Button } from '../../Controls/style';

export const ZoomContainer = styled.div`
  display: none;

  ${Button} {
    top: 0;

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      right: 0;
    }
  }

  ${({ theme: { media } }) => media.hotspotPositionChange`
    display: block;
    position: absolute;
    bottom: 60px;
    right: 60px;
    z-index: 1;
    width: 99px;
    height: 33px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  `}
`;

export const PanContainer = styled.div`
  display: none;

  ${Button} {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    &:nth-child(1) {
      --transform: translate(-50%, -50%) rotate(270deg);
      top: 33px;
      left: 0;
    }

    &:nth-child(2) {
      --transform: translate(-50%, -50%) rotate(90deg);
      top: 33px;
      right: 0;
    }

    &:nth-child(3) {
      left: 33px;
      top: 0;
    }

    &:nth-child(4) {
      --transform: translate(-50%, -50%) rotate(180deg);
      bottom: 0;
      left: 33px;
    }
  }

  ${({ theme: { media } }) => media.hotspotPositionChange`
    display: block;
    position: absolute;
    top: 92px;
    right: 60px;
    z-index: 1;
    width: 99px;
    height: 99px;
  `}
`;

export const SvgWrapper = styled.div`
  width: 1586px;
  height: 893px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
