import coverPhoto from '../assets/hotspot/celebrationCover/fiestas-de-santiago-apostol-fiestas-de-loiza.jpg';
import concertThumbnail1 from '../assets/hotspot/celebrationPerformances/juanjulia_estoy_video.png';
import concertThumbnail2 from '../assets/hotspot/celebrationPerformances/juanjulia_estoybuscando_concert.png';
import juanJuliaImage from '../assets/hotspot/headerImages/juan-l.jpg';

export const fiestasDeSantiagoApostol = {
  celebrationTitle: 'Fiestas de Santiago Apostol / Fiestas de Loiza',
  startDate: '07-25',
  endDate: '07-25',
  filterValue: 'fiestasDeSantiagoApostol',
  displayDate: 'Mid July',
  celebrationImage: coverPhoto,
  description: 'The town of Loiza celebrates Fiestas de Santiago Apostol over several days every July. During these celebrations you are sure to find colorful parades, people in costumes and masks, and bomba music.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/kYO_xQItHO0',
      videoImage: concertThumbnail1,
      songTitle: 'Estoy Buscando un Árbol',
      artistName: 'Juan and Julia',
      videoTags: [
        {
          tag: 'Fiestas de la Calle San Sebastián',
        },
        {
          tag: 'Fiestas de Santiago Apostol / Fiestas de Loiza',
        },
      ],
      songDescription: '“Estoy Buscando un Árbol” is a popular bomba song meaning “I Am in Search of a Tree.” All bomba songs are conversations (with call and response) between the dancers and the musicians, and the dancers lead the way.',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=JDtf1YoXe_E',
      videoImage: concertThumbnail2,
      songTitle: 'Estoy Buscando un Árbol',
      artistName: 'Juan and Julia',
      videoTags: [
        {
          tag: 'Fiestas de la Calle San Sebastián',
        },
        {
          tag: 'Fiestas de Santiago Apostol / Fiestas de Loiza',
        },
      ],
      songDescription: '“Estoy Buscando un Árbol” is a popular bomba song meaning “I Am in Search of a Tree.” All bomba songs are conversations (with call and response) between the dancers and the musicians, and the dancers lead the way.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Juan and Julia',
      artistId: 'juan-julia',
      artistImage: juanJuliaImage,
    },
  ],
};
