/* eslint-disable */
import {
  roundNumber, boundLimiter, calculateBoundingArea, checkIsNumber,
} from './utils';
import { initialState } from './InitialState';
import { handleCentering } from './_pan';

export function checkZoomBounds(zoom, minScale, maxScale) {
  if (!isNaN(maxScale) && zoom >= maxScale) return maxScale;
  if (!isNaN(minScale) && zoom <= minScale) return minScale;
  return zoom;
}

export function checkPositionBounds(positionX, positionY, bounds, limitToBounds) {
  const {
    minPositionX, minPositionY, maxPositionX, maxPositionY,
  } = bounds;
  const xBound = boundLimiter(positionX, minPositionX, maxPositionX, limitToBounds);
  const yBound = boundLimiter(positionY, minPositionY, maxPositionY, limitToBounds);

  const x = xBound.value;
  const y = yBound.value;

  const documentEl = document.documentElement;

  let controlXMin = false;
  let controlXMax = false;
  let controlYMin = false;
  let controlYMax = false;

  if (xBound.limit === 'minBound') {
    controlXMin = true;
    controlXMax = false;
  } else if (xBound.limit === 'maxBound') {
    controlXMin = false;
    controlXMax = true;
  } else {
    controlXMin = false;
    controlXMax = false;
  }

  if (yBound.limit === 'minBound') {
    controlYMin = true;
    controlYMax = false;
  } else if (yBound.limit === 'maxBound') {
    controlYMin = false;
    controlYMax = true;
  } else {
    controlYMin = false;
    controlYMax = false;
  }

  return {
    x, y, controlXMin, controlXMax, controlYMin, controlYMax,
  };
}

export function getDelta(event, customDelta) {
  const deltaY = event ? (event.deltaY < 0 ? 1 : -1) : 0;
  const delta = checkIsNumber(customDelta, deltaY);
  return delta;
}

export function wheelMousePosition(event, contentComponent, scale) {
  const contentRect = contentComponent.getBoundingClientRect();

  // mouse position x, y over wrapper component
  const mouseX = (event.clientX - contentRect.left) / scale;
  const mouseY = (event.clientY - contentRect.top) / scale;

  if (isNaN(mouseX) || isNaN(mouseY)) return console.warn('No mouse or touch offset found');

  return {
    mouseX,
    mouseY,
  };
}

export function activePosition(x, y, contentComponent, scale) {
  const contentRect = contentComponent.getBoundingClientRect();

  // mouse position x, y over wrapper component
  const mouseX = (x - contentRect.left) / scale;
  const mouseY = (y - contentRect.top) / scale;

  if (isNaN(mouseX) || isNaN(mouseY)) return console.warn('No mouse or touch offset found');

  return {
    mouseX,
    mouseY,
  };
}

export function getComponentsSizes(wrapperComponent, contentComponent, newScale, svgContentComponentDimensions) {
  const wrapperRect = wrapperComponent.getBoundingClientRect();

  const wrapperWidth = wrapperRect.width;
  const wrapperHeight = wrapperRect.height;

  const newContentWidth = wrapperWidth * newScale;
  const newContentHeight = wrapperHeight * newScale;
  const newDiffWidth = wrapperWidth - newContentWidth;
  const newDiffHeight = wrapperHeight - newContentHeight;

  const svgContentWidth = svgContentComponentDimensions.width * newScale;
  const svgContentHeight = svgContentComponentDimensions.height * newScale;

  return {
    wrapperWidth,
    wrapperHeight,
    newContentWidth,
    newDiffWidth,
    newContentHeight,
    newDiffHeight,
    svgContentWidth,
    svgContentHeight,
  };
}

export function calculateZoom(zoomStep, delta, customScale) {
  const { scale, maxScale, minScale } = this.state;

  if (typeof customScale === 'number' && customScale === scale) return scale;
  if (typeof customScale === 'number') return checkZoomBounds(customScale, minScale, maxScale);

  const newScale = scale + zoomStep * delta * (scale / 100);

  const calculatedScale = checkZoomBounds(roundNumber(newScale, 2), minScale, maxScale);
  if (scale === calculatedScale) return scale;

  return calculatedScale;
}

export function calculateTransformation(mouseX, mouseY, scaleDifference, bounds) {
  if (typeof mouseX !== 'number' || typeof mouseY !== 'number') { return console.error('Mouse X and Y position were not provided!'); }
  const {
    positionX, positionY, limitToBounds, transformEnabled,
  } = this.state;

  if (!transformEnabled) return { newPositionX: positionX, newPositionY: positionY };

  const calculatedPositionX = positionX - mouseX * scaleDifference;
  const calculatedPositionY = positionY - mouseY * scaleDifference;

  const { x, y } = checkPositionBounds(
    calculatedPositionX,
    calculatedPositionY,
    bounds,
    limitToBounds,
  );

  return { newPositionX: x, newPositionY: y };
}

export function handleZoomWheel(event, customMousePosition, customDelta, customStep) {
  const {
    isDown,
    zoomingEnabled,
    disabled,
    wrapperComponent,
    contentComponent,
    svgContentComponentDimensions,
    scale,
    enableZoomedOutPanning,
    wheelStep,
  } = this.state;

  if (isDown || !zoomingEnabled || disabled) return;
  event.preventDefault();
  event.stopPropagation();


  // Scale transformation
  const delta = getDelta(event, customDelta);
  const newScale = calculateZoom.bind(this, customStep || wheelStep, delta)();
  if (newScale === scale) return;

  // Get new element sizes to calculate bounds
  const {
    wrapperWidth,
    wrapperHeight,
    newContentWidth,
    newDiffWidth,
    newContentHeight,
    newDiffHeight,
    svgContentWidth,
    svgContentHeight,
  } = getComponentsSizes(wrapperComponent, contentComponent, newScale, svgContentComponentDimensions);

  // Position transformation
  const { mouseX, mouseY } = customMousePosition || wheelMousePosition(event, contentComponent, scale);

  const scaleDifference = newScale - scale;

  const bounds = calculateBoundingArea(
    wrapperWidth,
    newContentWidth,
    newDiffWidth,
    wrapperHeight,
    newContentHeight,
    newDiffHeight,
    enableZoomedOutPanning,
    svgContentWidth,
    svgContentHeight,
  );

  // console.log('‼ ZOOM', bounds);
  // Save last zoom bounds, to speed up panning function
  this.bounds = bounds;

  // Calculate transformations
  const { newPositionX, newPositionY } = calculateTransformation.bind(
    this,
    mouseX,
    mouseY,
    scaleDifference,
    bounds,
  )();

  this.setState({
    positionX: newPositionX,
    positionY: newPositionY,
    scale: newScale,
    previousScale: scale,
    controlXMin: false,
    controlXMax: false,
    controlYMin: false,
    controlYMax: false,
  });
}

export function handleActiveZoom() {
  const {
    wrapperComponent,
    contentComponent,
    scale,
    enableZoomedOutPanning,
    svgContentComponentDimensions,
    wheelStep,
    activeRef,
  } = this.state;

  // Scale transformation
  const newScale = calculateZoom.bind(this, 200 || wheelStep, 1)();
  if (newScale === scale) return;

  // Get new element sizes to calculate bounds
  const {
    wrapperWidth,
    wrapperHeight,
    newContentWidth,
    newDiffWidth,
    newContentHeight,
    newDiffHeight,
    svgContentWidth,
    svgContentHeight,
  } = getComponentsSizes(wrapperComponent, contentComponent, newScale, svgContentComponentDimensions);

  const activeBounds = activeRef.getBoundingClientRect();

  // Position transformation
  const { mouseX, mouseY } = activePosition(activeBounds.right, activeBounds.bottom, contentComponent, scale);

  const scaleDifference = newScale - scale;

  const bounds = calculateBoundingArea(
    wrapperWidth,
    newContentWidth,
    newDiffWidth,
    wrapperHeight,
    newContentHeight,
    newDiffHeight,
    enableZoomedOutPanning,
    svgContentWidth,
    svgContentHeight,
  );

  // Save last zoom bounds, to speed up panning function
  this.bounds = bounds;

  // Calculate transformations
  const { newPositionX, newPositionY } = calculateTransformation.bind(
    this,
    mouseX,
    mouseY,
    scaleDifference,
    bounds,
  )();

  this.setState({
    zoomScale: newScale,
    zoomPositionX: newPositionX,
    zoomPositionY: newPositionY,
    positionX: newPositionX,
    positionY: newPositionY,
    scale: newScale,
    previousScale: scale,
    controlXMin: false,
    controlXMax: false,
    controlYMin: false,
    controlYMax: false,
  });

  setTimeout(() => handleCentering.bind(this)(), 700);
}

export function handleZoomControls(event, customDelta, customStep) {
  const {
    positionX, positionY, wrapperComponent, scale,
  } = this.state;
  // calculate zoom center
  const wrapperWidth = wrapperComponent.offsetWidth;
  const wrapperHeight = wrapperComponent.offsetHeight;
  const mouseX = (Math.abs(positionX) + wrapperWidth / 2) / scale;
  const mouseY = (Math.abs(positionY) + wrapperHeight / 2) / scale;
  handleZoomWheel.bind(this, event, { mouseX, mouseY }, customDelta, customStep)();
}

export function handleZoomDbClick(event) {
  const { dbClickMode, dbClickStep } = this.state;

  if (dbClickMode === 'reset') {
    return resetTransformations.bind(this, event)();
  }
  const delta = dbClickMode === 'zoomOut' ? -1 : 1;
  handleZoomWheel.bind(this, event, null, delta, dbClickStep)();
}

export function resetTransformations() {
  const { defaultScale, defaultPositionX, defaultPositionY } = this.props.defaultValues;
  const {
    scale,
    positionX,
    positionY,
    disabled,
    wrapperComponent,
    contentComponent,
    svgContentComponentDimensions,
    enableZoomedOutPanning,
  } = this.state;
  if (disabled) return;
  if (scale === defaultScale && positionX === defaultPositionX && positionY === defaultPositionY) { return; }

  const newScale = checkIsNumber(defaultScale, initialState.scale);
  const newPositionX = checkIsNumber(defaultPositionX, initialState.positionX);
  const newPositionY = checkIsNumber(defaultPositionY, initialState.positionY);

  // Get new element sizes to calculate bounds
  const {
    wrapperWidth,
    wrapperHeight,
    newContentWidth,
    newDiffWidth,
    newContentHeight,
    newDiffHeight,
    svgContentWidth,
    svgContentHeight,
  } = getComponentsSizes(wrapperComponent, contentComponent, newScale, svgContentComponentDimensions);

  const bounds = calculateBoundingArea(
    wrapperWidth,
    newContentWidth,
    newDiffWidth,
    wrapperHeight,
    newContentHeight,
    newDiffHeight,
    enableZoomedOutPanning,
    svgContentWidth,
    svgContentHeight,
  );

  // Save last zoom bounds, to speed up panning function
  this.bounds = bounds;

  this.setState({
    scale: newScale,
    positionX: newPositionX,
    positionY: newPositionY,
    controlXMin: false,
    controlXMax: false,
    controlYMin: false,
    controlYMax: false,
  });
}
