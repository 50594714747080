import coverPhoto from '../assets/hotspot/celebrationCover/christmas-orthodox.jpg';
import ilushaImage from '../assets/hotspot/headerImages/ilusha-l.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/ilusha_shinavorgil_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/ilusha_shinavorgil_concert.png';

export const christmasOrthodox = {
  celebrationTitle: 'Christmas (Orthodox)',
  startDate: '01-07',
  endDate: '01-07',
  filterValue: 'christmasOrthodox',
  displayDate: 'January 7',
  celebrationImage: coverPhoto,
  description: 'During Orthodox Christmas in the country of Georgia, a huge parade goes through several towns with costumes, Georgian flags, and Christmas carols.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/bSoc5tECE9c',
      videoImage: celebrationThumbnail1,
      songTitle: 'Shina Vorgil',
      artistName: 'Ilusha',
      videoTags: [
        {
          tag: 'Mariamoba',
        },
        {
          tag: 'Christmas (Orthodox)',
        },
      ],
      songDescription: 'Although Georgia is a very small country, the regions within it have their own distinct identities. “Shina Vorgil” comes from Svaneti, a mountainous region with a long tradition of polyphonic music. The Svan language is only spoken by a handful of people today. So while the music still survives, the lyrics often cannot be translated.',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=MOGzwyXGuYc&t=5s',
      videoImage: celebrationThumbnail2,
      songTitle: 'Shina Vorgil',
      artistName: 'Ilusha',
      videoTags: [
        {
          tag: 'Mariamoba',
        },
        {
          tag: 'Christmas (Orthodox)',
        },
      ],
      songDescription: 'Although Georgia is a very small country, the regions within it have their own distinct identities. “Shina Vorgil” comes from Svaneti, a mountainous region with a long tradition of polyphonic music. The Svan language is only spoken by a handful of people today. So while the music still survives, the lyrics often cannot be translated.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Ilusha',
      artistId: 'ilusha',
      artistImage: ilushaImage,
    },
  ],
};
