// @flow

import { colors } from './colors';
import { media } from './media-queries';
import { typography } from './typography';
import { layout } from './layout';

const appTheme = {
  colors: { ...colors },
  media,
  ...typography,
  ...layout,
};

export default appTheme;
