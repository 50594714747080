// @flow

import React, { useState, useRef, useEffect } from 'react';
import { FILTER_ITEMS } from '../../constants/filters';

import {
  FilterContainer,
  FilterToggle,
  FilterToggleText,
  FilterToggleIcon,
  FilterList,
  FilterItem,
  FilterButton,
  FilterName,
  FilterListContainer,
  FilterHeading,
  ResetButton,
  ButtonContainer,
  ApplyButton,
} from './style';

type Props = {
  selectedFilter: string[],
  setSelectedFilter: Function,
  handleFilterResetClick: Function,
}

export const Filter = ({
  selectedFilter,
  setSelectedFilter,
  handleFilterResetClick,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempSelectedFilter, setTempSelectedFilter] = useState(selectedFilter);
  const toggleRef = useRef(null);
  const filterListRef = useRef(null);
  const container = document.getElementById('react-transform-element');

  const clickOutsideHandler = (event) => {
    // $FlowFixMe
    if (filterListRef.current.contains(event.target) || toggleRef.current.contains(event.target)) {
      return;
    }
    setIsOpen();
  };

  useEffect(() => {
    setTempSelectedFilter(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    if (isOpen) {
      // $FlowFixMe
      document.addEventListener('mouseup', clickOutsideHandler);
      // $FlowFixMe
    } else {
      // $FlowFixMe
      document.removeEventListener('mouseup', clickOutsideHandler);
    }

    return () => {
      // $FlowFixMe
      document.removeEventListener('mouseup', clickOutsideHandler);
    };
  }, [isOpen]);

  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };

  // Temporarily store filter changes until Apply is clicked
  const handleFilterClick = (filter) => {
    let result;

    if (filter === 'all') {
      result = ['all'];
    } else if (tempSelectedFilter.includes('all')) {
      result = [filter];
    } else {
      result = tempSelectedFilter.includes(filter)
        ? tempSelectedFilter.filter((f) => f !== filter)
        : [...tempSelectedFilter, filter];

      if (!result.length) {
        result.push('all');
      }
    }

    setTempSelectedFilter(result);
  };

  const wrapKeyHandler = (event) => {
    if (event.key === 'Escape' && isOpen) {
      setIsOpen(false);
      // $FlowFixMe
      toggleRef.current.focus();
    }
  };

  const handleApplyClick = () => {
    setSelectedFilter(tempSelectedFilter);
    setIsOpen(false);
  };

  const handleResetClick = () => {
    setSelectedFilter(['all']);
    setIsOpen(false);
    handleFilterResetClick();
    if (container) {
      container.style.transform = 'translate3d(0,0, 0)';
    }
  };

  return (
    <FilterContainer
      onKeyUp={wrapKeyHandler}
    >
      <FilterToggle
        aria-haspopup='menu'
        aria-controls='tradition-filter'
        onClick={handleToggleClick}
        // $FlowFixMe
        ref={toggleRef}
        isOpen={isOpen}
        aria-expanded={isOpen ? 'true' : 'false'}
      >
        <FilterToggleText>
          Musical Traditions
        </FilterToggleText>
        <FilterToggleIcon
          xmlns='http://www.w3.org/2000/svg'
          width='12'
          height='7'
          fill='none'
          viewBox='0 0 12 7'
          strokeWidth={1.5}
          isOpen={isOpen}
        >
          <path stroke='#fff' d='M1 1l5 5 5-5' />
        </FilterToggleIcon>
      </FilterToggle>
      {isOpen && (
      <FilterListContainer
        ref={filterListRef}
      >
        <FilterHeading>Musical Traditions</FilterHeading>
        <FilterList
          id='tradition-filter'
          role='list'
          tabIndex='-1'
        >
          {FILTER_ITEMS
            .filter((item) => item.tradition !== 'all')
            .sort((a, b) => a.traditionName.localeCompare(b.traditionName))
            .map((item) => (
              <FilterItem key={item.tradition}>
                <FilterButton
                  onClick={() => handleFilterClick(item.tradition)}
                  selectedFilter={tempSelectedFilter}
                  filter={item.tradition}
                >
                  <FilterName>{item.traditionName}</FilterName>
                  {item.artist ? item.artist.join(', ') : null}
                </FilterButton>
              </FilterItem>
            ))}

        </FilterList>
        <ButtonContainer>
          <ResetButton type='button' onClick={handleResetClick}>
            Reset
          </ResetButton>
          <ApplyButton type='button' onClick={handleApplyClick}>
            Apply
          </ApplyButton>
        </ButtonContainer>

      </FilterListContainer>
      )}
    </FilterContainer>
  );
};
