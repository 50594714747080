/* eslint-disable max-len */
import styled from 'styled-components/macro';

export const PostcardSectionContainer = styled.div`
  background-color: #f0f0f0;
  padding: 24px 20px;
  margin: 24px 16px;

  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    margin: 32px 0;
`}
`;

export const ThumbnailContainer = styled.div`
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  padding: 15px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.4) 70%,
    rgba(0, 0, 0, 0.9) 100%
  );

  ${({ theme: { media } }) => media.tablet`
    padding: 25px;
`}
`;

export const PostcardCta = styled.p`
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #e22237;
  font-family: ${({ theme }) => theme.fontFamily2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 25px;
  margin: 0;

  ${({ theme: { media } }) => media.desktop`
    font-size: 30px;
`}
`;

export const Subtitle = styled.p`
  font-size: 12px;
  color: #fff;
  margin: 10px 0 0 0;

  ${({ theme: { media } }) => media.desktop`
    font-size: 15.5px;
`}
`;
