import concertThumbnail from '../assets/hotspot/celebrationPerformances/kalani_enakini_video.png';
import kalaniImage from '../assets/hotspot/headerImages/kalani-l.jpg';
import coverPhoto from '../assets/hotspot/celebrationCover/kamehameha-day-parade.jpg';

export const kamehamehaDayParade = {
  celebrationTitle: 'Kamehameha Day Parade',
  startDate: '06-11',
  endDate: '06-11',
  filterValue: 'kamehamehaDayParade',
  displayDate: 'June 11',
  celebrationImage: coverPhoto,
  description: 'The Kamehameha Day Parade occurs every year on June 11 to honor first king of Hawai‘i who united the eight Hawaiian Islands under one rule in 1810. Celebrating peace and unity, the parade includes vibrant costumes, flowers, traditional music, and hula.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/SweNj30zsC0',
      videoImage: concertThumbnail,
      songTitle: 'E Nā Kini',
      artistName: 'Kalani',
      videoTags: [
        {
          tag: 'Kamehameha Day Parade',
        },
        {
          tag: 'Merrie Monarch Festival',
        },
        {
          tag: 'Hawaiian Independence Day',
        },
      ],
      songDescription: '“E Nā Kini,” meaning “Oh, People of the Land,” is a coming together of people during life’s challenges. The song has origins as a rallying anthem for healing, and today it continues to be a call for pride and dignity among the natives.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Kalani',
      artistId: 'kalani',
      artistImage: kalaniImage,
    },
  ],
};
