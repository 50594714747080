import coverPhoto from '../assets/hotspot/celebrationCover/eid-al-fitr.jpg';
import farahImage from '../assets/hotspot/headerImages/farah-l.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/farah-reedaha.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/farah_reedaha_concert.png';
import laythImage from '../assets/hotspot/headerImages/layth-l.jpg';
import celebrationThumbnail3 from '../assets/hotspot/celebrationPerformances/layth_talamin_video.png';
import celebrationThumbnail4 from '../assets/hotspot/celebrationPerformances/layth_talamin_concert.png';

export const eidAlFitr = {
  celebrationTitle: 'Eid al-Fitr',
  startDate: '03-31',
  endDate: '03-31',
  filterValue: 'eidAlFitr',
  displayDate: 'Dates Vary Each Year',
  celebrationImage: coverPhoto,
  description: 'Eid al-Fitr marks the end of Ramadan, the holy month when Muslims fast from dawn to dusk. Eid al-Fitr means “festival of breaking the fast” in Arabic. On the first day of Eid al-Fitr, Muslims wear new clothes, pray, enjoy feasts with loved ones, and give to the less fortunate.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/sHtuXCGTX-8',
      videoImage: celebrationThumbnail1,
      songTitle: 'Reedaha” / “Ya Khayal il Zarga ',
      artistName: 'Farah',
      videoTags: [
        {
          tag: 'Arab American History Month',
        },
        {
          tag: 'Eid al-Fitr',
        },
        {
          tag: 'Jerash Festival',
        },
      ],
      songDescription: '“Reedaha” is a very old song that has been passed down orally through generations. Farah’s version folds in another folk song called “Ya Khayal il Zarga." Both songs are ghazals, a type of love song. Depending on the song, the ghazal might express flirtation, longing, or the loss of love.',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=smmucpluixY',
      videoImage: celebrationThumbnail3,
      songTitle: 'Tal’a Min Beit Abouha',
      artistName: 'Layth',
      videoTags: [
        {
          tag: 'Eid al-Fitr',
        },
      ],
      songDescription: 'The melodic system is based on the maqam, a scale system incorporating microtones that provides the foundation for Arabic music. There are 42 maqamat (the plural of maqam) altogether in the system; each is said to have a different character and emotion. Melodies are sung and played in unison by both singers and instrumentalists, with a unique form of ornamentation.',
    },
    {
      videoLink: 'https://youtu.be/wjY2pMyJr1U',
      videoImage: celebrationThumbnail2,
      songTitle: 'Reedaha” / “Ya Khayal il Zarga',
      artistName: 'Farah',
      videoTags: [
        {
          tag: 'Arab American History Month',
        },
        {
          tag: 'Eid al-Fitr',
        },
        {
          tag: 'Jerash Festival',
        },
      ],
      songDescription: '“Reedaha” is a very old song that has been passed down orally through generations. Farah’s version folds in another folk song called “Ya Khayal il Zarga." Both songs are ghazals, a type of love song. Depending on the song, the ghazal might express flirtation, longing, or the loss of love.',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=QM22t9eo-EY',
      videoImage: celebrationThumbnail4,
      songTitle: 'Tal’a Min Beit Abouha',
      artistName: 'Layth',
      videoTags: [
        {
          tag: 'Eid al-Fitr',
        },
      ],
      songDescription: 'The melodic system is based on the maqam, a scale system incorporating microtones that provides the foundation for Arabic music. There are 42 maqamat (the plural of maqam) altogether in the system; each is said to have a different character and emotion. Melodies are sung and played in unison by both singers and instrumentalists, with a unique form of ornamentation.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Farah',
      artistId: 'farah',
      artistImage: farahImage,
    },
    {
      artistName: 'Layth',
      artistId: 'layth',
      artistImage: laythImage,
    },
  ],
};
