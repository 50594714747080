// @flow

import React, {
  useState,
  useEffect,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import MapComponent from '../components/Map';
import { Header } from '../components/Header';
import { HotSpot } from '../components/Hotspot';
import { WelcomeModal } from '../components/WelcomeModal';
import { ExternalLink } from '../components/ExternalLink';
import { CELEBRATIONS_FILTERS } from '../constants/celebrationsFilters';

import { Wrapper } from './style';

type Props = {
  history: Object,
  location: Object,
};

const localStorageVar = 'viewedWelcomeMessage';

export const MapView = ({
  history,
  location,
}: Props) => {
  const [activeArtist, setActiveArtist] = useState('');
  const [showWelcomeModal, setWelcomeModal] = useState(true);
  const [shouldResetMap, setShouldResetMap] = useState(false);
  const [shouldFilterResetMap, setShouldFilterResetMap] = useState(false);
  const [selectedTraditionsFilter, setSelectedTraditionsFilter] = useState(['all']);
  const [selectedCelebrationsFilter, setSelectedCelebrationsFilter] = useState({
    celebrationTitle: 'all',
    startDate: '',
    endDate: '',
    filterValue: 'all',
    data: null,
  });
  const [activeFilter, setActiveFilter] = useState('none');


  useEffect(() => {
    const hasSeenWelcomeModal = localStorage.getItem(localStorageVar);
    if (hasSeenWelcomeModal) setWelcomeModal(false);
  }, []);

  const handleActiveChange = (artist: Object) => {
    setActiveArtist(artist);
  };

  const handleWelcomeModalClose = () => {
    setWelcomeModal(false);
    localStorage.setItem(localStorageVar, 'true');
  };


  const handleAfterMapReset = () => {
    setShouldResetMap(false);
  };


  const handleAfterFilterReset = () => {
    setShouldFilterResetMap(false);
  };

  const handleHeaderClick = () => {
    setActiveArtist('');
    setSelectedTraditionsFilter(['all']);
    setSelectedCelebrationsFilter({
      celebrationTitle: 'all',
      startDate: '',
      endDate: '',
      filterValue: 'all',
      displayDate: '',
      data: null,
    });
  };

  const handleFilterClick = () => {
    setShouldFilterResetMap(true);
    setActiveArtist('');
  };


  const handleHotspotClose = () => {
    const container = document.getElementById('react-transform-element');
    setActiveArtist('');
    setShouldResetMap(true);
    if (activeFilter === 'celebrations' && !activeArtist) {
      setSelectedCelebrationsFilter({
        celebrationTitle: 'all',
        startDate: '',
        endDate: '',
        filterValue: 'all',
        displayDate: '',
        data: null,
      });
      if (container) {
        container.style.transform = 'translate3d(0,0, 0)';
      }
    }
  };

  const flattenedAndSortedCelebrationFilters = CELEBRATIONS_FILTERS
    .flatMap((section) => section.filterItems)
    .sort((a, b) => a.startDate.localeCompare(b.startDate));

  const handleNextCelebration = () => {
    const currentIndex = flattenedAndSortedCelebrationFilters.findIndex(
      (filter) => filter.filterValue === selectedCelebrationsFilter.filterValue,
    );
    const nextIndex = (currentIndex + 1) % flattenedAndSortedCelebrationFilters.length;
    const nextFilter = flattenedAndSortedCelebrationFilters[nextIndex];
    setSelectedCelebrationsFilter({
      ...nextFilter,
    });
  };

  const handlePrevCelebration = () => {
    const currentIndex = flattenedAndSortedCelebrationFilters.findIndex(
      (filter) => filter.filterValue === selectedCelebrationsFilter.filterValue,
    );
    const prevIndex = (currentIndex - 1 + flattenedAndSortedCelebrationFilters.length)
                      % flattenedAndSortedCelebrationFilters.length;
    const prevFilter = flattenedAndSortedCelebrationFilters[prevIndex];
    setSelectedCelebrationsFilter({
      ...prevFilter,
    });
  };

  return (
    <>
      <Header
        history={history}
        location={location}
        handleHeaderClick={handleHeaderClick}
        handleFilterClick={handleFilterClick}
        selectedTraditionsFilter={selectedTraditionsFilter}
        setSelectedTraditionsFilter={setSelectedTraditionsFilter}
        selectedCelebrationsFilter={selectedCelebrationsFilter}
        setSelectedCelebrationsFilter={setSelectedCelebrationsFilter}
        setActiveFilter={setActiveFilter}
        activeArtist={activeArtist}
      />
      <main>
        <Wrapper>
          <CSSTransition
            in={activeArtist !== '' || selectedCelebrationsFilter.filterValue !== 'all'}
            timeout={700}
            classNames='hotspot'
            unmountOnExit
          >
            <HotSpot
              activeArtist={activeArtist}
              setActiveArtist={setActiveArtist}
              history={history}
              location={location}
              handleFilterClick={handleFilterClick}
              onClose={handleHotspotClose}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              selectedCelebration={selectedCelebrationsFilter}
              handleNextCelebration={handleNextCelebration}
              handlePrevCelebration={handlePrevCelebration}
            />
          </CSSTransition>
          <MapComponent
            history={history}
            location={location}
            handleActiveChange={handleActiveChange}
            shouldResetMap={shouldResetMap}
            shouldFilterResetMap={shouldFilterResetMap}
            handleAfterMapReset={handleAfterMapReset}
            handleAfterFilterReset={handleAfterFilterReset}
            selectedTraditionsFilter={selectedTraditionsFilter}
            selectedCelebrationsFilter={selectedCelebrationsFilter}
          />
          <ExternalLink />
        </Wrapper>
        {showWelcomeModal && (
          <WelcomeModal closeModal={handleWelcomeModalClose} />
        )}
      </main>
    </>
  );
};
