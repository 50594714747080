import smallHeaderImage from '../assets/hotspot/headerImages/imani-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/imani-l.jpg';
import ilushaPin from '../assets/hotspot/pins/ilusha.jpg';
import emelinePin from '../assets/hotspot/pins/emeline.jpg';
import introImage from '../assets/hotspot/intro/imani.png';
import postcard from '../assets/hotspot/postcardPdf/Imani_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/ImaniPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/imani_concert.png';

export const imani = {
  name: 'Imani Uzuri',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Imani Uzuri, who sings freedom songs!',
    details: '',
    vimeoLink: 'https://vimeo.com/238976830',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/4LpB-QCVqSU',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-One/Imani',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Imani Sing “Freedom Medley”',
      link: 'Learn “Freedom Medley”',
      linkUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-One/Imani/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/326227376%3Fsecret_token%3Ds-BaEz3&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Imani Sing “Ain\'t Gonna Let Nobody Turn Me Around”',
      link: 'Learn “Ain\'t Gonna Let Nobody Turn Me Around”',
      linkUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-One/Imani/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/327746670%3Fsecret_token%3Ds-evgDJ&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Imani Uzuri appears on stage with',
    artists: [
      {
        id: 1,
        image: emelinePin,
        name: 'Emeline',
        query: 'emeline',
      },
      {
        id: 2,
        image: ilushaPin,
        name: 'Ilusha',
        query: 'ilusha',
      },
    ],
    linkOutText: 'Learn more about Imani Uzuri in Musical Explorers Program One.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-One',
  },
};
