import coverPhoto from '../assets/hotspot/celebrationCover/utamaduni-day.jpg';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/makobi_kothbiro_video.png';
import makobiImage from '../assets/hotspot/headerImages/makobi-l.jpg';

export const utamaduniDay = {
  celebrationTitle: 'Utamaduni Day',
  startDate: '10-10',
  endDate: '10-10',
  filterValue: 'utamaduniDay',
  displayDate: 'October 10',
  celebrationImage: coverPhoto,
  description: 'Meaning “culture” in Swahili, Utamaduni Day is an annual celebration of Kenya’s heritage and its diverse cultures through music and dance performance.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/TSbQc4Fci6k',
      videoImage: celebrationThumbnail,
      songTitle: 'Koth Biro',
      artistName: 'Makobi',
      videoTags: [
        {
          tag: 'Utamaduni Day',
        },
        {
          tag: 'Africa Day',
        },
      ],
      songDescription: 'Sung in Dholuo, the language of the Luo people of Western Kenya, “Koth Biro” is one of the most well known songs by legendary Kenyan musician Ayub Ogada. The song reimagines traditional Kenyan  melodies and rhythm, accompanied by the nyatiti, a plucked lyre prominently played by the Luo people of Western Kenya.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Makobi',
      artistId: 'makobi',
      artistImage: makobiImage,
    },
  ],
};
