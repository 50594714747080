import styled from 'styled-components/macro';

export const Modal = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.7);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalOverlay = styled.div`
  width: 100%;
`;

export const ModalClose = styled.button`
  --top: 16px;
  --right: 16px;

  margin: 0;
  padding: 0;
  line-height: 1;
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  outline: 0;
  position: absolute;
  z-index: 1;
  right: var(--right);
  top: var(--top);
  cursor: pointer;

  path {
    transition: fill 200ms;
  }

  &:focus {
    outline: 1px dashed ${({ theme }) => theme.colors.alizarinCrimson};
    outline-offset: 5px;
  }

  ${({ theme: { media } }) => media.hotspotPositionChange`
    --top: 20px;
    --right: 36px;
  `}

  &:hover {
    ${({ theme: { media } }) => media.desktop`
      path {
        fill: ${({ theme }) => theme.colors.alizarinCrimson};
      }
    `}
  }
`;

export const ModalContent = styled.div`
  &:focus {
    ${({ theme: { media } }) => media.hotspotPositionChange`
      outline: 1px dashed white;
    }
  `}
`;

export const ModalCloseIcon = styled.svg``;
