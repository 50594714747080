// @flow

import React from 'react';

import {
  SvgMarkerContainer,
  SvgMarkerShadow,
  SvgMarkerImage,
  SvgMarkerText,
  Name,
  Genre,
} from './style';

type Props = {
  image: string,
  name: string,
  genre: String,
  id: string,
}

export const MapMarker = ({
  image,
  name,
  genre,
  id,
}: Props) => (
  <>
    <SvgMarkerContainer>
      <SvgMarkerShadow
        xmlns='http://www.w3.org/2000/svg'
        width='58'
        height='88'
        fill='none'
        viewBox='0 0 58 88'
      >
        <ellipse
          cx='28.818'
          cy='82.943'
          fill='#0B163B'
          opacity='0.4'
          rx='18.88'
          ry='5.057'
        />
      </SvgMarkerShadow>
      <SvgMarkerImage
        src={image}
        alt={name}
        id={id}
      />
    </SvgMarkerContainer>
    <SvgMarkerText>
      <Name>{name}</Name>
      <Genre>{genre}</Genre>
    </SvgMarkerText>
  </>
);
