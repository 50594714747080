import smallHeaderImage from '../assets/hotspot/headerImages/layth-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/layth-l.jpg';
import mus1 from '../assets/hotspot/pins/qian-yi.jpg';
import mus2 from '../assets/hotspot/pins/michael.jpg';
import introImage from '../assets/hotspot/intro/layth.png';
import postcard from '../assets/hotspot/postcardPdf/Layth_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/LaythPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/layth_concert.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/layth_talamin_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/layth_talamin_concert.png';

export const layth = {
  name: 'Layth',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Layth, who sings Iraqi folk music!',
    details: '',
    vimeoLink: 'https://vimeo.com/385594254',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/QM22t9eo-EY',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Six/Layth',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Layth sing “Tal\'a Min Beit Abouha”',
      link: 'Learn “Tal\'a Min Beit Abouha”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Six/Layth/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/603905052%3Fsecret_token%3Ds-suijq&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Layth sing “Foag el-Nakhal”',
      link: 'Learn “Foag el-Nakhal”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Six/Layth/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/603904977%3Fsecret_token%3Ds-aFlkz&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Layth appears on stage with',
    artists: [
      {
        id: 1,
        image: mus1,
        name: 'Qian Yi',
        query: 'qian-yi',
      },
      {
        id: 2,
        image: mus2,
        name: 'Micahel',
        query: 'michael',
      },
    ],
    linkOutText: 'Learn more about Layth in Musical Explorers Program Six.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Six/',
  },
  traditionBlurb: 'Iraq is a crossroads connecting the Middle East to North Africa and East Asia, making it a cultural melting pot; its folk music draws from these diverse sources. Unique to Iraqi folk songs is the wordless refrain linking verse and chorus that brings everyone—musicians and audience—together in song.',
  celebrationVideos: [
    {
      videoLink: 'https://www.youtube.com/watch?v=smmucpluixY',
      videoImage: celebrationThumbnail1,
      songTitle: 'Tal’a Min Beit Abouha',
      artistName: 'Layth',
      videoTags: [
        {
          tag: 'Eid al-Fitr',
        },
      ],
      songDescription: 'The melodic system is based on the maqam, a scale system incorporating microtones that provides the foundation for Arabic music. There are 42 maqamat (the plural of maqam) altogether in the system; each is said to have a different character and emotion. Melodies are sung and played in unison by both singers and instrumentalists, with a unique form of ornamentation.',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=QM22t9eo-EY',
      videoImage: celebrationThumbnail2,
      songTitle: 'Tal’a Min Beit Abouha',
      artistName: 'Layth',
      videoTags: [
        {
          tag: 'Eid al-Fitr',
        },
      ],
      songDescription: 'The melodic system is based on the maqam, a scale system incorporating microtones that provides the foundation for Arabic music. There are 42 maqamat (the plural of maqam) altogether in the system; each is said to have a different character and emotion. Melodies are sung and played in unison by both singers and instrumentalists, with a unique form of ornamentation.',
    },
  ],
};
