export const FILTER_ITEMS = [
  { tradition: 'all', traditionName: 'All' },
  { tradition: 'georgianFolk', traditionName: 'Georgian Folk' },
  { tradition: 'freedomSongs', traditionName: 'Freedom Songs' },
  { tradition: 'haitian', traditionName: 'Haitian Song Traditions' },
  { tradition: 'argentineFolk', traditionName: 'Argentine Folk' },
  { tradition: 'southeasternSongs', traditionName: 'Southeastern Songs and Social Dances' },
  { tradition: 'southAfricanZulu', traditionName: 'South African Zulu' },
  { tradition: 'greekFolk', traditionName: 'Greek Folk' },
  { tradition: 'malianTraditional', traditionName: 'Malian Traditional' },
  { tradition: 'indianClassical', traditionName: 'Indian Classical' },
  { tradition: 'jazz', traditionName: 'Jazz' },
  { tradition: 'bombaAndPlena', traditionName: 'Bomba and Plena' },
  { tradition: 'brazilian', traditionName: 'Brazilian' },
  { tradition: 'cumbia', traditionName: 'Cumbia' },
  { tradition: 'armenianFolk', traditionName: 'Armenian Folk' },
  { tradition: 'hipHop', traditionName: 'Hip Hop' },
  { tradition: 'bluegrass', traditionName: 'Bluegrass' },
  { tradition: 'chineseTraditional', traditionName: 'Chinese Traditional' },
  { tradition: 'iraqiFolk', traditionName: 'Iraqi Folk' },
  { tradition: 'sonJarocho', traditionName: 'Son Jarocho' },
  { tradition: 'sicilianFolk', traditionName: 'Sicilian Folk' },
  { tradition: 'calypso', traditionName: 'Calypso' },
  { tradition: 'zimbabweanMbiraMusic', traditionName: 'Zimbabwean Mbira Music' },
  { tradition: 'gullahMusic', traditionName: 'Gullah Music' },
  { tradition: 'jordanianFolk', traditionName: 'Jordanian Folk' },
  { tradition: 'vietnameseFolk', traditionName: 'Vietnamese Folk' },
  { tradition: 'dominicanRoots', traditionName: 'Dominican Roots' },
  { tradition: 'meleHawaii', traditionName: 'Mele Hawai‘i' },
  { tradition: 'kenyan', traditionName: 'Kenyan Song Traditions' },
];
