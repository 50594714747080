import styled from 'styled-components';

export const CelebrationTitle = styled.h3`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  font-family: ${({ theme }) => theme.fontFamily2};
`;

export const DateRange = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.2px;

  &.active {
    color: ${({ theme }) => theme.colors.primaryTeal};
  }
`;

export const PillWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 6px 10px;
  border-radius: 51px;
  border: 2px solid ${({ theme }) => theme.colors.primaryBlue};
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-family: ${({ theme }) => theme.fontFamily1};
  width: fit-content;

  &.selected {
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    color: ${({ theme }) => theme.colors.white};

    ${DateRange} {
      color: ${({ theme }) => theme.colors.primaryLightTeal};
    }
  }

  &:hover {
    cursor: pointer;
    
    ${CelebrationTitle} {
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.primaryBlue};
    }
  }
`;

export const TextContainer = styled.div``;
