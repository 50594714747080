// @flow

import styled from 'styled-components/macro';

export const SvgMarker = styled.div``;

export const SvgMarkerContainer = styled.i`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
`;

export const SvgMarkerShadow = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
`;

export const SvgMarkerImage = styled.img`
  position: absolute;
  transform: translate(-50%, 0) translateZ(0);
  width: 53px;
  left: 50%;
  top: 2px;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-filter: blur(0);
`;

export const SvgMarkerText = styled.span`
  position: absolute;
  background: white;
  padding: 7px 12px;
  bottom: -45px;
  transform: translate(-50%, 0);
  left: 50%;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
  font-family: ${({ theme }) => theme.fontFamily1};
  font-size: 14px;
  line-height: 17px;
`;

export const Name = styled.p`
  color: ${({ theme }) => theme.colors.alizarinCrimson};
  margin: 0;
  white-space: nowrap;
`;

export const Genre = styled.p`
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  white-space: nowrap;
`;
