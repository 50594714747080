import styled from 'styled-components/macro';
import { AudioLink } from '../HotspotLessonAudio/style';

export const ConcertVideoContainer = styled.div`
    width: 100%;
    padding: 56px 20px 28px 20px;
    margin: 0 0 24px 0;
`;

export const HeadlineFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 22px;
`;

export const Headline = styled.h2`
    font-family: ${({ theme }) => theme.fontFamily2};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 20px;
    margin: 0;

    ${({ theme: { media } }) => media.desktop`
    font-size: 20px;
`}
`;

export const ConcertLink = styled(AudioLink)`
    cursor: pointer;
    &:first-of-type {
        margin-right: 0;
      }
`;
