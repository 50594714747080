import coverPhoto from '../assets/hotspot/celebrationCover/hampartsoumm.jpg';
import zulalImage from '../assets/hotspot/headerImages/zulal-l.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/zulal_tamzara_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/zulal_tamzara_concert.png';

export const hampartsoum = {
  celebrationTitle: 'Hampartsoum',
  startDate: '05-01',
  endDate: '05-31',
  filterValue: 'hampartsoum',
  displayDate: '40 Days after Easter',
  celebrationImage: coverPhoto,
  description: 'Hampartsoum (Ascension Day) is a day of love and enjoyment celebrated outside in nature. One of Zulal’s favorite Hampartsoum traditions is a fortune-telling ritual that young girls perform.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/wwP8CLv571Y',
      videoImage: celebrationThumbnail1,
      songTitle: 'Tamzara',
      artistName: 'Zulal',
      videoTags: [
        {
          tag: 'Hampartsoum',
        },
      ],
      songDescription: '“Tamzara” is a song about girls and boys dancing together. The Tamzara is a dance with a distinctive 9/8 rhythm. The strong and weak beats fall in a surprising, unexpected pattern, making the dance feel special.',
    },
    {
      videoLink: 'https://youtu.be/zwiiQYzRrjM',
      videoImage: celebrationThumbnail2,
      songTitle: 'Tamzara',
      artistName: 'Zulal',
      videoTags: [
        {
          tag: 'Hampartsoum',
        },
      ],
      songDescription: '“Tamzara” is a song about girls and boys dancing together. The Tamzara is a dance with a distinctive 9/8 rhythm. The strong and weak beats fall in a surprising, unexpected pattern, making the dance feel special.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Zulal',
      artistId: 'zulal',
      artistImage: zulalImage,
    },
  ],
};
