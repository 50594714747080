import coverPhoto from '../assets/hotspot/celebrationCover/womens-day-south-africa.jpg';
import bongiTshidiImage from '../assets/hotspot/headerImages/bongi-l.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/bongitshidi_thula_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/bongitshidi_thulamntwana_concert.png';

export const womensDaySouthAfrica = {
  celebrationTitle: 'Women’s Day (National Women’s Day in South Africa)',
  startDate: '08-09',
  endDate: '08-09',
  filterValue: 'womensDaySouthAfrica',
  displayDate: 'August 9',
  celebrationImage: coverPhoto,
  description: 'Women’s Day commemorates the 1956 march of approximately 20,000 women protesting South Africa’s apartheid laws. Through today, Women’s Day has continued to bring together South African women to combat inequality.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/AngsGCR0jzQ',
      videoImage: celebrationThumbnail1,
      songTitle: 'Thula Mntwana” / “Nampaya Omame',
      artistName: 'Bongi and Tshidi',
      videoTags: [
        {
          tag: 'Women’s Day (National Women’s Day in South Africa)',
        },
      ],
      songDescription: 'This song is a combination of two traditional South African Zulu songs: “Thula Mntwana,” a lullaby, and “Nampaya Omame,” a well-known children’s folk song. Together the two songs tell a story of a child going to sleep awaiting their mother’s return. In the morning, the mother brings gifts for the child and the child’s joy is expressed in the music.',
    },
    {
      videoLink: 'https://youtu.be/jwwpF8mO8Hg',
      videoImage: celebrationThumbnail2,
      songTitle: 'Thula Mntwana” / “Nampaya Omame',
      artistName: 'Bongi and Tshidi',
      videoTags: [
        {
          tag: 'Women’s Day (National Women’s Day in South Africa)',
        },
      ],
      songDescription: 'This song is a combination of two traditional South African Zulu songs: “Thula Mntwana,” a lullaby, and “Nampaya Omame,” a well-known children’s folk song. Together the two songs tell a story of a child going to sleep awaiting their mother’s return. In the morning, the mother brings gifts for the child and the child’s joy is expressed in the music.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Bongi and Tshidi',
      artistId: 'bongi-tshidi',
      artistImage: bongiTshidiImage,
    },
  ],
};
