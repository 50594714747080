import coverPhoto from '../assets/hotspot/celebrationCover/heritage-day.jpg';
import bongiImage from '../assets/hotspot/headerImages/bongi-l.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/bongi-inqola.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/bongitshidi_inqola_concert.png';

export const heritageDay = {
  celebrationTitle: 'Heritage Day',
  startDate: '09-24',
  endDate: '09-24',
  filterValue: 'heritageDay',
  celebrationImage: coverPhoto,
  displayDate: 'September 24',
  description: ' September 24 is a public holiday in South Africa celebrating the cultural diversity of the country. This day pays tribute to King Shaka Zulu who played an important role in uniting different Zulu tribes into one cohesive Zulu nation.',
  celebrationVideos: [
    {
      videoLink: 'https://www.youtube.com/watch?v=_z1OTljZDjQ',
      videoImage: celebrationThumbnail1,
      songTitle: 'Inqola',
      artistName: 'Bongi and Tshidi',
      videoTags: [
        {
          tag: 'Heritage Day',
        },
      ],
      songDescription: 'The song “Inqola” speaks of the relationship between human and nature. “Inqola” means any type of moving vehicle, and this song honors the idea that we go through many phases in life, always moving, just like nature has its phases.',
    },
    {
      videoLink: 'https://youtu.be/myT21gnyJDs',
      videoImage: celebrationThumbnail2,
      songTitle: 'Inqola',
      artistName: 'Bongi and Tshidi',
      videoTags: [
        {
          tag: 'Heritage Day',
        },
      ],
      songDescription: 'The song “Inqola” speaks of the relationship between human and nature. “Inqola” means any type of moving vehicle, and this song honors the idea that we go through many phases in life, always moving, just like nature has its phases.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Bongi and Tshidi',
      artistId: 'bongi-tshidi',
      artistImage: bongiImage,
    },
  ],
};
