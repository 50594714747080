import largeHeaderImage from '../assets/hotspot/headerImages/yasser-l.jpg';
import smallHeaderImage from '../assets/hotspot/headerImages/yasser-s.jpg';
import introImage from '../assets/hotspot/intro/yasser.png';
import postcard from '../assets/hotspot/postcardPdf/Yasser_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/YasserPostcardThumbnail.jpg';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/yasser-palo-bonito.png';

export const yasser = {
  name: 'Yasser',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Yasser who sings Dominican Roots music!',
    details: '',
    vimeoLink: 'https://vimeo.com/854404018',
    videoImage: introImage,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Artists/Yasser',
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Yasser sing “Palo Bonito”',
      link: 'Learn “Palo Bonito”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Artists/Yasser/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1595589240%3Fsecret_token%3Ds-kqVsxRLcvsM&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Yasser sing "Kikondé"',
      link: 'Learn "Kikondé"',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Artists/Yasser/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1595613207%3Fsecret_token%3Ds-unZF3wmbWv3&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>',
    },
  ],
  traditionBlurb: 'The Dominican Republic is home to a diverse musical culture that has been influenced by West African, European, and indigenous Taíno people. It is probably most known for its globally popular merengue and bachata, but folk music and folk dance, including palos and congos, are as alive as any of the Dominican Republic’s modern styles.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/NC03T1p3JK4',
      videoImage: celebrationThumbnail,
      songTitle: 'Palo Bonito',
      artistName: 'Yasser',
      videoTags: [
        {
          tag: 'Dominican Independence Day',
        },
      ],
      songDescription: '“Palo Bonito” is based on a musical style called palo, a national rhythm and celebration that can be found throughout the Dominican Republic. The rhythms of palo are rooted in traditions from the Congo region of central Africa. This is a sacred tradition, often played at religious ceremonies and on special occasions.',
    },
  ],
};
