import coverPhoto from '../assets/hotspot/celebrationCover/rara-carnival.jpg';
import emelineImage from '../assets/hotspot/headerImages/emeline-l.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/emeline_lakaridad_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/emeline_lakaridad_concert.png';

export const raraCarnival = {
  celebrationTitle: 'Rara',
  startDate: '4-20',
  endDate: '4-21',
  filterValue: 'raraCarnival',
  displayDate: 'Around Easter',
  celebrationImage: coverPhoto,
  description: 'Rara is an Easter week celebration in Haiti that usually begins on Ash Wednesday and culminates on Easter Sunday. During Rara, people dress up in costumes and bands of musicians walk long distances through local towns, bringing music wherever they go.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/0hYpD0rFk38',
      videoImage: celebrationThumbnail1,
      songTitle: 'La Karidad',
      artistName: 'Emeline',
      videoTags: [
        {
          tag: 'Rara',
        },
      ],
      songDescription: 'Emeline’s original song “La Karidad” is in the style of compas, one of the most popular styles of music in Haiti. Compas features the tanbou drum that propels the dance. The basic movement of compas is simple. You step to the right and then to the left, while swishing your hips.',
    },
    {
      videoLink: 'https://youtu.be/eiHB1-MhMt8',
      videoImage: celebrationThumbnail2,
      songTitle: 'La Karidad',
      artistName: 'Emeline',
      videoTags: [
        {
          tag: 'Rara',
        },
      ],
      songDescription: 'Emeline’s original song “La Karidad” is in the style of compas, one of the most popular styles of music in Haiti. Compas features the tanbou drum that propels the dance. The basic movement of compas is simple. You step to the right and then to the left, while swishing your hips.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Emeline',
      artistId: 'emeline',
      artistImage: emelineImage,
    },
  ],
};
