import smallHeaderImage from '../assets/hotspot/headerImages/villalobos-brothers-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/villalobos-brothers-l.jpg';
import juliaPin from '../assets/hotspot/pins/julia.jpg';
import etiennePin from '../assets/hotspot/pins/etienne.jpg';
import introImage from '../assets/hotspot/intro/villalobos-brothers.png';
import postcard from '../assets/hotspot/postcardPdf/Villalobos_Brothers_Postcard_2.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/VillalobosBrothersPostcardThumbnail2.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/villaloos_concert.png';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/villalobos-son-jarocho.png';

export const villalobosBrothers = {
  name: 'Villalobos Brothers',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet the Villalobos Brothers, who sing son jarocho!',
    details: '',
    vimeoLink: 'https://vimeo.com/519068810',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=DF8tKSM60hE',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Villalobos-Brothers',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to the Villalobos Brothers Sing “La Guacamaya”',
      link: 'Learn “La Guacamaya”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Eight/Villalobos-Brothers/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/965567509%3Fsecret_token%3Ds-wQhVXs8GiHS&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to the Villalobos Brothers Sing “El Colás”',
      link: 'Learn “El Colás”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Eight/Villalobos-Brothers/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/965567428%3Fsecret_token%3Ds-GhDFpXoTp4C&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'The Villalobos Brothers appear on stage with',
    artists: [
      {
        id: 1,
        image: juliaPin,
        name: 'Julia',
        query: 'julia',
      },
      {
        id: 2,
        image: etiennePin,
        name: 'Etienne',
        query: 'etienne',
      },
    ],
    linkOutText: 'Learn more about the Villalobos Brothers in Musical Explorers Program Eight. ',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Eight',
  },
  traditionBlurb: 'Son jarocho, “the sound of Veracruz,” was born in the south of Mexico as an amalgamation of Spanish, West African, and indigenous influences, and has evolved for more than 200 years. Jarocho refers to people and things that come from the south of Veracruz. ',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/2nSN-gZeQMA?si=Q-KmS7DSbFG5J-jf',
      videoImage: celebrationThumbnail,
      songTitle: 'El Colás',
      artistName: 'Villalobos Brothers',
      videoTags: [
        {
          tag: 'Cinco de Mayo',
        },
      ],
      songDescription: '"El Colás" is a classic son jarocho song from Veracruz, Mexico. The song is traditionally danced by couples, often as part of fandangos. Fandangos are traditional celebrations from rural communities of southern Veracruz. They celebrate harvest cycles, religious holidays, and personal milestones.',
    },
  ],
};
