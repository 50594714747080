import styled from 'styled-components/macro';

export const AudioContainer = styled.div`
--padding: 14px 20px 10px;
  padding: var(--padding);

  ${({ theme: { media } }) => media.hotspotColumnChange`
    --padding: 14px 27px 10px;
  `}
`;

export const AudioHeadline = styled.h3`
  font-size: 20px;
  font-family: ${({ theme }) => theme.fontFamily2}, sans-serif;
  font-weight: 900;
  margin: 0 0 11px 0;
  `;

export const AudioLink = styled.a`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  position: relative;
  width: fit-content;
  display: inline-block;
  padding-bottom: 1px;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.alizarinCrimson};
    position: absolute;
    bottom: 0;
  }

  &:focus {
    outline: 1px dashed ${({ theme }) => theme.colors.alizarinCrimson};
  }

  &:first-of-type {
    margin-right: 20px;
  }
`;

export const AudioEmbed = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
