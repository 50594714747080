// @flow
// http://chir.ag/projects/name-that-color/#E22237

const white = '#ffffff';
const black = '#000000';
const alizarinCrimson = '#e22237';
const alabaster = '#fbfbfb';
const gallery = '#f0f0f0';
const primaryBlue = '#076174';
const primaryBrightBlue = '#007F95';
const primaryTeal = '#1BBE9F';
const primaryLightTeal = '#67E9D1';
const primaryDarkTeal = '#18A58B';
const primaryDarkGray = '#666666';
const primaryLightGray = '#F0F0F0';

export const colors = {
  white,
  black,
  alizarinCrimson,
  alabaster,
  gallery,
  primaryBlue,
  primaryBrightBlue,
  primaryTeal,
  primaryLightTeal,
  primaryDarkTeal,
  primaryDarkGray,
  primaryLightGray,
};
