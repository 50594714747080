/* eslint-disable */

import React, { useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Context } from '../store/StateContext';
import { ControlButton } from '../../Controls';
import caret from '../../../assets/icons/caret.svg';
import plus from '../../../assets/icons/plus.svg';
import minus from '../../../assets/icons/minus.svg';
import {
  PanContainer,
  ZoomContainer,
  SvgWrapper,
} from './styles';

function TransformComponent({ children }) {
  const wrapperRef = useRef(null);
  const contentRef = useRef(null);
  const svgContentRef = useRef(null);
  const { dispatch, state, nodes } = useContext(Context);
  const style = {
    transform: `translate3d(${state.positionX}px, ${state.positionY}px, 0px) scale3d(${state.scale}, ${state.scale}, 1) perspective(1px)`,
  };

  useEffect(() => {
    if (wrapperRef.current) {
      nodes.setWrapperComponent(wrapperRef.current);
    }
    if (contentRef.current) {
      nodes.setContentComponent(contentRef.current);
    }
    if (svgContentRef.current) {
      nodes.setSvgContentComponent(svgContentRef.current);
    }
  }, []);

  const moveXPosition = (
    positionX,
    position,
  ) => (position === 'right' ? dispatch.setPositionX(positionX - 50) : dispatch.setPositionX(positionX + 50));

  const moveYPosition = (
    positionY,
    position,
  ) => (position === 'bottom' ? dispatch.setPositionY(positionY - 50) : dispatch.setPositionY(positionY + 50));

  return (
    <>
      <PanContainer>
        <ControlButton
          handleClick={() => moveXPosition(state.positionX, 'left')}
          text='Move Left'
          mask={caret}
          state={state.controlXMax}
          classname='caret'
        />
        <ControlButton
          handleClick={() => moveXPosition(state.positionX, 'right')}
          text='Move Right'
          mask={caret}
          state={state.controlXMin}
          classname='caret'
        />
        <ControlButton
          handleClick={() => moveYPosition(state.positionY, 'top')}
          text='Move Up'
          mask={caret}
          state={state.controlYMax}
          classname='caret'
        />
        <ControlButton
          handleClick={() => moveYPosition(state.positionY, 'bottom')}
          text='Move Down'
          mask={caret}
          state={state.controlYMin}
          classname='caret'
        />
      </PanContainer>
      <ZoomContainer>
        <ControlButton
          handleClick={(e) => dispatch.zoomOut(e)}
          text='Zoom Out'
          mask={minus}
          isWide
          classname='minus'
          state={state.scale === state.minScale}
        />
        <ControlButton
          handleClick={(e) => dispatch.zoomIn(e)}
          text='Zoom In'
          mask={plus}
          isWide
          state={state.scale === state.maxScale}
        />
      </ZoomContainer>
      <div ref={wrapperRef} className='react-transform-component'>
        <div ref={contentRef} id='react-transform-element' className='react-transform-element' style={style}>
          <SvgWrapper ref={svgContentRef}/>
          {children}
        </div>
      </div>
    </>
  );
}

TransformComponent.propTypes = { children: PropTypes.any };

export default TransformComponent;
