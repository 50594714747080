import gregorioImage from '../assets/hotspot/headerImages/gregorio-l.jpg';
import coverPhoto from '../assets/hotspot/celebrationCover/cumbia-day.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/gregorio-la-piragua.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/gregorio_lapiragua_concert.png';

export const cumbiaDay = {
  celebrationTitle: 'Cumbia Day',
  startDate: '02-02',
  endDate: '02-02',
  filterValue: 'cumbiaDay',
  celebrationImage: coverPhoto,
  displayDate: 'February 2',
  description: 'February 2 is set aside to celebrate and pay homage to the roots of cumbia, and to honor the Black and Indigenous communities who experienced oppression under the Spanish conquerors.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/gmlHV2kBwpE?si=HeJXnarXgl2HHJTM',
      videoImage: celebrationThumbnail1,
      songTitle: 'La Piragua',
      artistName: 'Gregorio',
      videoTags: [
        {
          tag: 'Cumbia Day',
        },
      ],
      songDescription: 'José Benito Barros is perhaps the most well-known and prolific of Colombian composers, having written more than 800 songs! “La Piragua” is one of his most famous. The song features the three layered rhythms that are known as a basic cumbia rhythm, and there\'s a whole dance that goes along with the music. ',
    },
    {
      videoLink: 'https://youtu.be/-9AGEVrQgC8',
      videoImage: celebrationThumbnail2,
      songTitle: 'La Piragua',
      artistName: 'Gregorio',
      videoTags: [
        {
          tag: 'Cumbia Day',
        },
      ],
      songDescription: 'José Benito Barros is perhaps the most well-known and prolific of Colombian composers, having written more than 800 songs! “La Piragua” is one of his most famous. The song features the three layered rhythms that are known as a basic cumbia rhythm, and there\'s a whole dance that goes along with the music. ',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Gregorio',
      artistId: 'gregorio',
      artistImage: gregorioImage,
    },
  ],
};
