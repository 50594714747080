// @flow

import React from 'react';
import {
  ArtistText,
  CelebrationText,
  Header,
  CelebrationHotspotContainer,
  Headline,
  IntroSection,
  TextContainer,
  HeaderImage,
  Description,
  ExploreButton,
} from './style';


type Props = {
  headline: string,
  selectedCelebration: string,
  artistName: string,
  headerImage: string,
  description: string,
  handleExploreClick: Function,
};

export const CelebrationHeader = ({
  headline,
  selectedCelebration,
  artistName,
  headerImage,
  description,
  handleExploreClick,
}: Props) => (
  <CelebrationHotspotContainer>
    <Header>
      <CelebrationText>{selectedCelebration} with:</CelebrationText>
      <ArtistText>{artistName}</ArtistText>
    </Header>
    <IntroSection>
      <HeaderImage src={headerImage} alt={artistName} />
      <TextContainer>
        <Headline>{headline && headline}</Headline>
        <Description>{description}</Description>
        <ExploreButton onClick={handleExploreClick}>Meet {artistName}</ExploreButton>
      </TextContainer>
    </IntroSection>
  </CelebrationHotspotContainer>
);
