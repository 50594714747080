import styled from 'styled-components/macro';

const filterWidthDesktop = 590;

export const FilterContainer = styled.div`
  display: block;
  position: relative;
  z-index: 1;
`;

export const FilterToggle = styled.button`
  --paddingLeftRight: ${({ theme }) => theme.baseSpacing * 3}px;
  --height: 60px;
  margin: 0;
  padding: 0;
  line-height: 1;
  appearance: none;
  border: none;
  background: none;
  font-family: ${({ theme }) => theme.fontFamily2};
  outline: 0;
  font-size: 16px;
  height: var(--height);
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  padding: 0 var(--paddingLeftRight);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  &::before {
    --beforeWidth: calc(100vw - 20px);
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.alizarinCrimson};

    z-index: -1;
    top: 0;
    left: 0;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};

    ${({ theme: { media } }) => media.tablet`
      --beforeWidth: calc(100vw - 120px);
    `}

    ${({ theme: { media } }) => media.desktop`
      --beforeWidth: ${filterWidthDesktop}px;
      font-size: 14px;

    `}
  }

  &:focus {
    ${({ theme: { media } }) => media.desktop`
      outline: 1px dashed ${({ theme }) => theme.colors.white};
      outline-offset: -5px;
    `}
  }

  &:hover {
    ${({ theme: { media } }) => media.desktop`
      text-decoration: underline;
    `}
  }

  ${({ theme: { media } }) => media.desktop`
    --paddingLeftRight: ${({ theme }) => theme.baseSpacing * 5}px;
    --height: 32px;
    --width: 174px;
    font-size: 16px;
  `}
`;

export const FilterToggleText = styled.span``;

export const FilterToggleIcon = styled.svg`
  transform: ${({ isOpen }) => (isOpen ? 'rotate3d(1, 0, 0, 180deg)' : 'rotate3d(1, 0, 0, 0deg)')};
  transition: transform 200ms;
  margin-left: 8px;
`;

export const FilterHeading = styled.span`
  font-family: ${({ theme }) => theme.fontFamily2};
  font-size: 14px;
  display: none;
  margin-bottom: 8px;

  ${({ theme: { media } }) => media.desktop`
    display: block;
`}
`;

export const FilterListContainer = styled.div`
  width: 95vw;
  height: calc(100vh - 120px);
  position: absolute;
  top: 100%;
  left: -100%;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 4px solid ${({ theme }) => theme.colors.primaryBlue};

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e4e4e4;
    border: 13px solid white;
  }

  &.celebration {
    height: unset;
  }

  ${({ theme: { media } }) => media.tablet`
  width: ${filterWidthDesktop}px;
  height: unset;
  max-height: calc(100vh - 72px);
  `}
  
  ${({ theme: { media } }) => media.desktop`
  left: 0;
  width: ${filterWidthDesktop}px;
  `}
  overflow-y: auto;
`;

export const FilterList = styled.ul`
  margin: 0;
  width: 100%;
  height: 92%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 0 0 10px 0;

  list-style-type: none;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: auto;
`;

export const FilterButton = styled.button`
  margin: 0;
  line-height: normal;
  border: 1px solid ${({ theme }) => theme.colors.alizarinCrimson};
  border-radius: 51px;
  appearance: none;
  background: none;
  font-family: inherit;
  color: ${({ theme }) => theme.colors.alizarinCrimson};
  padding: 6px 10px;
  font-size: 18px;
  position: relative;
  letter-spacing: 0.2px;
  cursor: pointer;
  font-weight: ${({ selectedFilter, filter, theme }) => (
    selectedFilter.includes(filter) && `${theme.fontWeight.semiBold}`
  )};
  background-color: ${({ selectedFilter, filter, theme }) => (
    selectedFilter.includes(filter) && `${theme.colors.alizarinCrimson}`
  )};
  color: ${({ selectedFilter, filter, theme }) => (
    selectedFilter.includes(filter) && `${theme.colors.white}`
  )};
  
  ${({ theme: { media } }) => media.desktop`
    font-size: 14px;
  `}

  &:hover {
    ${({ theme: { media } }) => media.desktop`
    text-decoration: underline;
    `}
  }
`;

export const FilterItem = styled.li``;

export const FilterName = styled.span``;

export const ResetButton = styled.button`
  font-size: 16px;
  background: none;
  border: none;
  padding: 8px 10px;
  font-family: ${({ theme }) => theme.fontFamily1};
  color: ${({ theme }) => theme.colors.black};

  ${({ theme: { media } }) => media.desktop`
  border: none;
  font-size: 16px; 
  width: fit-content;

  &:hover {
    text-decoration: underline;
    text-underline-position: under;
  }
`}
`;

export const ApplyButton = styled.button`
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  border: none;
  padding: 8px 10px;
  font-family: ${({ theme }) => theme.fontFamily1};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme: { media } }) => media.desktop`
    font-size: 16px;  
    width: fit-content;

    &:hover {
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.white};
      text-underline-position: under;
    }
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: end;
  padding: 18px 0;
`;

export const SectionHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
    h3 {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 16px 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  ${({ theme: { media } }) => media.desktop`
  &.featured {
    flex-wrap: nowrap;
  }
  `}
`;

export const Section = styled.div`
  &:last-child {
    ${List} {
      padding-bottom: 16px;
    }
    
    &.active {
    ${SectionHeadingContainer} {
        border-bottom: 3px solid ${({ theme }) => theme.colors.primaryBlue};
      }
    }
  }
  &:not(:last-child) {
    ${SectionHeadingContainer} {
      border-bottom: 3px solid ${({ theme }) => theme.colors.primaryBlue};
    }
  }
`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;


export const ListItem = styled.li`
  list-style: none;
`;

export const HappeningNowSection = styled.div``;

export const HappeningNowHeading = styled.h3`
  width: 100%; 
  text-align: center; 
  border-bottom: 2px solid ${({ theme }) => theme.colors.primaryDarkTeal};
  line-height: 0.1em;
  margin: 10px 0 0 0; 
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.primaryDarkTeal};

  span {
    background:#fff; 
    padding: 0 10px; 
  }
`;

export const ListWrappers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SectionDateRange = styled.span`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.022px;
  color: ${({ theme }) => theme.colors.primaryDarkGray};

  &.featured {
    color: ${({ theme }) => theme.colors.primaryDarkTeal};
    font-size: 12px;
    font-weight: 700;
  }
`;

export const SectionHeading = styled.h3`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.032px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

export const FeaturedHeading = styled.h3`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.032px;
  color: ${({ theme }) => theme.colors.alizarinCrimson};
`;

export const SectionDateWrapper = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
`;
