import coverPhoto from '../assets/hotspot/celebrationCover/jerash-festival.jpg';
import farahImage from '../assets/hotspot/headerImages/farah-l.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/farah-reedaha.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/farah_reedaha_concert.png';

export const jerashFestival = {
  celebrationTitle: 'Jerash Festival',
  startDate: '07-20',
  endDate: '08-10',
  filterValue: 'jerashFestival',
  displayDate: 'Late July to Early August',
  celebrationImage: coverPhoto,
  description: 'The Jerash Festival is a spectacular cultural event in Jordan. For aboout a week in the summer, Jordanian artists come together with artists from all around the Arab world (and beyond) to present concerts, ballet, opera, and more in the beautiful Jerash Ruins.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/sHtuXCGTX-8',
      videoImage: celebrationThumbnail1,
      songTitle: 'Reedaha” / “Ya Khayal il Zarga',
      artistName: 'Farah',
      videoTags: [
        {
          tag: 'Arab American History Month',
        },
        {
          tag: 'Eid al-Fitr',
        },
        {
          tag: 'Jerash Festival',
        },
      ],
      songDescription: '“Reedaha” is a very old song that has been passed down orally through generations. Farah’s version folds in another folk song called “Ya Khayal il Zarga." Both songs are ghazals, a type of love song. Depending on the song, the ghazal might express flirtation, longing, or the loss of love.',
    },
    {
      videoLink: 'https://youtu.be/wjY2pMyJr1U',
      videoImage: celebrationThumbnail2,
      songTitle: 'Reedaha” / “Ya Khayal il Zarga',
      artistName: 'Farah',
      videoTags: [
        {
          tag: 'Arab American History Month',
        },
        {
          tag: 'Eid al-Fitr',
        },
        {
          tag: 'Jerash Festival',
        },
      ],
      songDescription: '“Reedaha” is a very old song that has been passed down orally through generations. Farah’s version folds in another folk song called “Ya Khayal il Zarga." Both songs are ghazals, a type of love song. Depending on the song, the ghazal might express flirtation, longing, or the loss of love.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Farah',
      artistId: 'farah',
      artistImage: farahImage,
    },
  ],
};
