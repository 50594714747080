// @flow

import { css } from 'styled-components';

// Media Queries
// Leverage the `media` function inside the theme
// to target specific screen sizes.

export const sizes = {
  hotspotColumnChange: 500,
  tablet: 720,
  hotspotPositionChange: 720,
  desktop: 1024,
  mediumDesktop: 1181, // based on break in the design,
  largeDesktop: 1280,
  headerWidth: 1356 + (20 * 2), // header maxwidth + padding
  extraLargeDesktop: 1370,
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args: any) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});
