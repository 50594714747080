import concertThumbnail from '../assets/hotspot/celebrationPerformances/kalani_enakini_video.png';
import kalaniImage from '../assets/hotspot/headerImages/kalani-l.jpg';
import coverPhoto from '../assets/hotspot/celebrationCover/hawaiian-independence-day.jpg';

export const hawaiianIndependenceDay = {
  celebrationTitle: 'Hawaiian Independence Day',
  startDate: '11-28',
  endDate: '11-28',
  filterValue: 'hawaiianIndependenceDay',
  displayDate: 'November 28',
  celebrationImage: coverPhoto,
  description: 'Hawaiian Independence Day is celebrated every year on November 28, commemorating the day that the independence and sovereignty of Hawai‘i was recognized by Great Britain and France.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/SweNj30zsC0',
      videoImage: concertThumbnail,
      songTitle: 'E Nā Kini',
      artistName: 'Kalani',
      videoTags: [
        {
          tag: 'Kamehameha Day Parade',
        },
        {
          tag: 'Merrie Monarch Festival',
        },
        {
          tag: 'Hawaiian Independence Day',
        },
      ],
      songDescription: '“E Nā Kini,” meaning “Oh, People of the Land,” is a coming together of people during life’s challenges. The song has origins as a rallying anthem for healing, and today it continues to be a call for pride and dignity among the natives.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Kalani',
      artistId: 'kalani',
      artistImage: kalaniImage,
    },
  ],
};
