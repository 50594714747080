import juliaImage from '../assets/hotspot/headerImages/julia-l.jpg';
import coverPhoto from '../assets/hotspot/celebrationCover/valentines-day.jpeg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/julia-cu-ti-lu-dissi.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/julia_cutiluddissi_concert.png';

export const valentinesDay = {
  celebrationTitle: 'Valentine’s Day',
  startDate: '02-14',
  endDate: '02-014',
  filterValue: 'valentinesDay',
  celebrationImage: coverPhoto,
  displayDate: 'February 14',
  description: 'Valentine’s Day originated as a Christian feast day honoring the martyr Valentine, but now has become a significant cultural celebration of romance and love in many parts of the world.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/xoR93RTXfK4',
      videoImage: celebrationThumbnail1,
      songTitle: 'Cu Ti Lu Dissi',
      artistName: 'Julia',
      videoTags: [
        {
          tag: 'Valentine’s Day',
        },
      ],
      songDescription: 'Julia says that there are no Sicilian love songs without heartbreak. Like most Sicilian folk songs, it is in a minor key. “Cu Ti Lu Dissi” she says, is about choosing love over pain.',
    },
    {
      videoLink: 'https://youtu.be/jiachUfKUvI',
      videoImage: celebrationThumbnail2,
      songTitle: 'Cu Ti Lu Dissi',
      artistName: 'Julia',
      videoTags: [
        {
          tag: 'Valentine’s Day',
        },
      ],
      songDescription: 'Julia says that there are no Sicilian love songs without heartbreak. Like most Sicilian folk songs, it is in a minor key. “Cu Ti Lu Dissi” she says, is about choosing love over pain.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Julia',
      artistId: 'julia',
      artistImage: juliaImage,
    },
  ],
};
