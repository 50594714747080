import styled from 'styled-components/macro';

export const ImageContent = styled.div``;

export const ImageHeadline = styled.h3`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin: 0;
`;

export const ImageLessonLink = styled.a`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-bottom: 1px;
  margin-bottom: 10px;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.alizarinCrimson};
    position: absolute;
    bottom: 0;
  }

  &:focus {
    outline: 1px dashed ${({ theme }) => theme.colors.alizarinCrimson};
  }
`;

export const ImageContainer = styled.div`
  display: grid;

  &::before {
    content:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 9"></svg>');
    grid-area: 1 / 1 / 2 / 2;

    ${({ theme: { media } }) => media.hotspotColumnChange`
      height: 106px;
      width: 100%;
    `}
  }

  ${({ theme: { media } }) => media.hotspotColumnChange`
    height: 106px;
    width: 100%;
  `}
`;

export const ImageInner = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  cursor: pointer;

  &:focus {
    outline: 1px dashed ${({ theme }) => theme.colors.alizarinCrimson};
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const LargeImageWrapper = styled.div`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

export const LargeImage = styled.img`
  max-width: 100%;
  vertical-align: bottom;
`;
