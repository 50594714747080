// @flow

import React from 'react';
import {
  PerformancesContainer,
  SectionHeading,
  SongDescription,
  Tag,
  TagsWrapper,
  VideoWrapper,
  VideosContainer,
} from './style';
import { HotspotVideo } from '../HotspotVideo';


type Props = {
  artistName: string,
  performances: Array<Object>,
  selectedCelebration: string,
};

export const CelebrationPerformances = ({
  artistName,
  performances,
  selectedCelebration,
}: Props) => (
  <PerformancesContainer>
    <SectionHeading>{`${artistName}'s Celebration Performances`}</SectionHeading>
    <VideosContainer>
      {performances.filter((performance) => performance.videoTags
        .some((tag) => tag.tag === selectedCelebration))
        .map((performance) => (
          <VideoWrapper>
            <HotspotVideo
              videoImage={performance.videoImage}
              vimeoLink={performance.videoLink}
              artistName={artistName}
              songTitle={performance.songTitle}
            />
            <TagsWrapper>
              {[...performance.videoTags]
                .sort((a, b) => {
                  if (b.tag === selectedCelebration) return 1;
                  if (a.tag === selectedCelebration) return -1;
                  return 0;
                })
                .map((tag) => (
                  <Tag className={tag.tag === selectedCelebration ? 'active' : ''}>{tag.tag}</Tag>
                ))}
            </TagsWrapper>
            <SongDescription>{performance.songDescription}</SongDescription>
          </VideoWrapper>
        ))}
    </VideosContainer>
  </PerformancesContainer>
);
