import largeHeaderImage from '../assets/hotspot/headerImages/makobi-l.jpg';
import introImage from '../assets/hotspot/intro/makobi.jpg';
import postcard from '../assets/hotspot/postcardPdf/Makobi_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/MakobiPostcardThumbnail.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/makobi_kothbiro_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/makobi_mwana_video.png';


export const makobi = {
  name: 'Makobi',
  headerImages: [largeHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Makobi, who sings Kenyan Song Traditions!',
    details: '',
    vimeoLink: 'https://vimeo.com/854404486',
    videoImage: introImage,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Artists/Makobi',
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Makobi sing “Koth Biro”',
      link: 'Learn “Koth Biro”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Artists/Makobi/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1595615967%3Fsecret_token%3Ds-2bgZbNhnZVS&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Makobi sing “Mwana wa Mbeli”',
      link: 'Learn “Mwana wa Mbeli"',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Artists/Makobi/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1595620833%3Fsecret_token%3Ds-AHm1l41ACVa&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>',
    },
  ],
  traditionBlurb: 'Kenya has more than 50 ethnic groups and regional languages. It has drawn musical influences from the Arabian Peninsula, India, Europe, the Americas, and other parts of Africa. These musical traditions have been passed down orally for generations, along with a variety of instruments and dance.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/TSbQc4Fci6k',
      videoImage: celebrationThumbnail1,
      songTitle: 'Koth Biro',
      artistName: 'Makobi',
      videoTags: [
        {
          tag: 'Africa Day',
        },
        {
          tag: 'Utamaduni Day',
        },
      ],
      songDescription: 'Sung in Dholuo, the language of the Luo people of Western Kenya, “Koth Biro” is one of the most well known songs by legendary Kenyan musician Ayub Ogada. The song reimagines traditional Kenyan  melodies and rhythm, accompanied by the nyatiti, a plucked lyre prominently played by the Luo people of Western Kenya.',
    },
    {
      videoLink: 'https://youtu.be/Xo7y1SmKIfE',
      videoImage: celebrationThumbnail2,
      songTitle: 'Mwana wa Mbeli',
      artistName: 'Makobi',
      videoTags: [
        {
          tag: 'Jamhuri Day',
        },
      ],
      songDescription: '“Mwana wa Mbeli” was traditionally performed to celebrate the firstborn of a family. To this day, the song continues to be sung in the spirit of pride, joy, and celebration, and now this song is performed at graduations, weddings, or any happy occasion.',
    },
  ],
};
