import styled from 'styled-components/macro';

export const ExternalWrapper = styled.aside`
  --w: 142px;
  --l: 20px;
  --b: 20px;

  position: absolute;
  bottom: var(--b);
  left: var(--l);
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 4px 0 rgba(0,0,0,0.25);
  width: var(--w);

  ${({ theme: { media } }) => media.tablet`
    --w: 146px;
    --l: 60px;
    --b: 60px;
  `}

  ${({ theme: { media } }) => media.headerWidth`
    --l: calc(50% - 1356px/2); // header width
  `}
`;

export const ExternalLinkTag = styled.a`
  --p: 8px 14px 8px;
  display: block;
  text-decoration: none;
  padding: var(--p);
  color: ${({ theme }) => theme.colors.black};

  &:focus {
    outline: 1px dashed ${({ theme }) => theme.colors.alizarinCrimson};
  }

  ${({ theme: { media } }) => media.tablet`
    --p: 8px 14px 8px;
  `}
`;

export const ExternalText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 1.67;
`;

export const ExternalTextInner = styled.span`
  border-bottom: 1px solid ${({ theme }) => theme.colors.alizarinCrimson};
`;

export const ExternalIcon = styled.i`
  display: block;
  margin-top: 5px;
`;

export const ExternalSvg = styled.svg`
  width: 12px;
`;
