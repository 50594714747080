import coverPhoto from '../assets/hotspot/celebrationCover/festival-de-la-chacarera-santiago.jpg';
import sofiasImage from '../assets/hotspot/headerImages/sofia-l.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/sofias_chacarera_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/sofias_chacarera_concert.png';

export const festivalChacarera = {
  celebrationTitle: 'Festival de la Chacarera in Santiago del Estero',
  startDate: '01-06',
  endDate: '01-06',
  filterValue: 'festivalChacarera',
  displayDate: 'The End of the First Week in January',
  celebrationImage: coverPhoto,
  description: 'This celebration pays tribute to the chacarera, one of the most important dances of Argentina.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/wWGx-Ts2BsI',
      videoImage: celebrationThumbnail1,
      songTitle: 'Chacarera del Rancho',
      artistName: 'Sofía R. and Sofia T.',
      videoTags: [
        {
          tag: 'Mes de la Hispanidad (Hispanic Heritage Month)',
        },
        {
          tag: 'Festival de la Chacarera in Santiago del Estero',
        },
      ],
      songDescription: 'The chacarera is a dance from the state of Santiago del Estero and is celebrated as the rural counterpart to the more cosmopolitan tango. It is danced in pairs, and the song’s rhythm is a combination of two traditional rhythms layered together (the bombo rhythm and the palmas rhythm).',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=n5VF3o2rh4o',
      videoImage: celebrationThumbnail2,
      songTitle: 'Chacarera del Rancho',
      artistName: 'Sofía R. and Sofia T.',
      videoTags: [
        {
          tag: 'Mes de la Hispanidad (Hispanic Heritage Month)',
        },
        {
          tag: 'Festival de la Chacarera in Santiago del Estero',
        },
      ],
      songDescription: 'The chacarera is a dance from the state of Santiago del Estero and is celebrated as the rural counterpart to the more cosmopolitan tango. It is danced in pairs, and the song’s rhythm is a combination of two traditional rhythms layered together (the bombo rhythm and the palmas rhythm).',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Sofía R. and Sofia T.',
      artistId: 'sofia-sofia',
      artistImage: sofiasImage,
    },
  ],
};
