export const getFeaturedEvents = (filterItems) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const sixWeeksLater = new Date(currentDate.getTime() + 42 * 24 * 60 * 60 * 1000); // 6 weeks from now

  const allEvents = filterItems.flatMap((filter) => filter.filterItems);

  const happeningNowEvents = allEvents.filter((item) => {
    if (!item.startDate || !item.endDate) {
      return false;
    }

    const [startMonth, startDay] = item.startDate.split('-').map(Number);
    const [endMonth, endDay] = item.endDate.split('-').map(Number);

    const startDate = new Date(currentYear, startMonth - 1, startDay);
    const endDate = new Date(currentYear, endMonth - 1, endDay);

    return (
      (currentDate >= startDate && currentDate <= endDate)
      || (startDate > currentDate && startDate <= sixWeeksLater)
    );
  });

  happeningNowEvents.sort((a, b) => {
    const [aStartMonth, aStartDay] = a.startDate.split('-').map(Number);
    const [bStartMonth, bStartDay] = b.startDate.split('-').map(Number);

    const aStartDate = new Date(currentYear, aStartMonth - 1, aStartDay);
    const bStartDate = new Date(currentYear, bStartMonth - 1, bStartDay);

    return aStartDate - bStartDate;
  });

  if (happeningNowEvents.length > 3) {
    for (let i = happeningNowEvents.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = happeningNowEvents[i];
      happeningNowEvents[i] = happeningNowEvents[j];
      happeningNowEvents[j] = temp;
    }
  }

  return happeningNowEvents.slice(0, 3); // Return the first three events
};
