// @flow

import React from 'react';
import { HotspotVideo } from '../HotspotVideo';

import {
  VideoIntro,
  VideoIntroContent,
  VideoIntroHeadline,
  VideoIntroVideo,
} from './style';
import { HotspotCTA } from '../Hotspot/style';

type Props = {
  intro: {
    headline: string,
    subline: string,
    details: string,
    vimeoLink: string,
    videoImage: any,
  },
  name: string,
  exploreLink: string,
}

export const HotspotIntroVideo = ({
  intro: {
    headline,
    details,
    vimeoLink,
    videoImage,
  },
  name,
  exploreLink,
}: Props) => (
  <VideoIntro>
    <VideoIntroContent>
      <VideoIntroHeadline>{headline}</VideoIntroHeadline>
      {details && details}
    </VideoIntroContent>
    <HotspotCTA href={exploreLink} target='_blank'>{`Explore More about ${name}`}</HotspotCTA>
    <VideoIntroVideo>
      <HotspotVideo
        vimeoLink={vimeoLink}
        videoImage={videoImage}
      />
    </VideoIntroVideo>
  </VideoIntro>
);
