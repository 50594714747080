import smallHeaderImage from '../assets/hotspot/headerImages/ilusha-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/ilusha-l.jpg';
import imaniPin from '../assets/hotspot/pins/imani.jpg';
import emelinePin from '../assets/hotspot/pins/emeline.jpg';
import marthaPin from '../assets/hotspot/pins/martha.jpg';
import bongiTshidiPin from '../assets/hotspot/pins/bongi-tshidi.jpg';
import introImage from '../assets/hotspot/intro/ilusha.png';
import postcard from '../assets/hotspot/postcardPdf/Ilusha_Postcard_2.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/IlushaPostcardThumbnail2.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/ilusha_concert.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/ilusha_shinavorgil_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/ilusha_shengenatsvale_video.png';
import celebrationThumbnail3 from '../assets/hotspot/celebrationPerformances/ilusha_shinavorgil_concert.png';
import celebrationThumbnail4 from '../assets/hotspot/celebrationPerformances/ilusha_shengenatsvale_concert.png';

export const ilusha = {
  name: 'Ilusha',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Ilusha, who sings Georgian folk music!',
    vimeoLink: 'https://vimeo.com/237083368',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=MOGzwyXGuYc',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Ilusha',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Ilusha Sing “Shina Vorgil”',
      link: 'Learn “Shina Vorgil”',
      linkUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-One/Ilusha/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/326227390%3Fsecret_token%3Ds-NCJ4z&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Ilusha Sing “Shen Genatsvale”',
      link: 'Learn “Shen Genatsvale”',
      linkUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-One/Ilusha/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/884520466%3Fsecret_token%3Ds-aCKTXNvvoIv&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'In Program One, Illusha appears on stage with',
    artists: [
      {
        id: 1,
        image: emelinePin,
        name: 'Emeline',
        query: 'emeline',
      },
      {
        id: 2,
        image: imaniPin,
        name: 'Imani Uzuri',
        query: 'imani',
      },
    ],
    linkOutText: 'Learn more about Ilusha in Musical Explorers Program One.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-One/',
  },
  relatedArtist2: {
    title: 'In Program Seven, Illusha appears on stage with',
    artists: [
      {
        id: 1,
        image: marthaPin,
        name: 'Martha',
        query: 'martha',
      },
      {
        id: 2,
        image: bongiTshidiPin,
        name: 'Bongi and Tshidi',
        query: 'bongi-tshidi',
      },
    ],
    linkOutText: 'Learn more about Illusha in Musical Explorers Program Seven',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Seven',
  },
  celebrationVideos: [
    {
      videoLink: 'https://www.youtube.com/watch?v=bSoc5tECE9c',
      videoImage: celebrationThumbnail1,
      songTitle: 'Shina Vorgil',
      videoTags: [
        {
          tag: 'Mariamoba',
        },
        {
          tag: 'Christmas (Orthodox)',
        },
      ],
    },
    {
      videoLink: 'https://youtu.be/My5KlIFWzb0',
      videoImage: celebrationThumbnail2,
      songTitle: 'Shen Genatsvale',
      videoTags: [
        {
          tag: 'International Women’s Day',
        },
      ],
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=MOGzwyXGuYc&t=5s',
      videoImage: celebrationThumbnail3,
      songTitle: 'Shina Vorgil',
      videoTags: [
        {
          tag: 'Mariamoba',
        },
        {
          tag: 'Christmas (Orthodox)',
        },
      ],
    },
    {
      videoLink: 'https://youtu.be/MV222TpFBKc',
      videoImage: celebrationThumbnail4,
      songTitle: 'Shen Genatsvale',
      videoTags: [
        {
          tag: 'International Women’s Day',
        },
      ],
    },
  ],
};
