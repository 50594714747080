import coverPhoto from '../assets/hotspot/celebrationCover/festa-junina.jpg';
import fabianaImage from '../assets/hotspot/headerImages/fabiana-l.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/fabiana-requebra.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/fabiana_requebra_concert.png';

export const festaJunina = {
  celebrationTitle: 'Festa Junina',
  startDate: '06-01',
  endDate: '06-30',
  filterValue: 'festaJunina',
  displayDate: 'The Month of June',
  celebrationImage: coverPhoto,
  description: 'Festa Junina (June Festival) is a set of religious celebrations that is mostly popular in the country but has also become prevalent throughout Brazilian cities. The most popular of the festas, the festival of São João, is seen as an occasion in urban centers to celebrate rural life.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/irDaDJfymJEs',
      videoImage: celebrationThumbnail1,
      songTitle: 'Requebra',
      artistName: 'Fabiana',
      videoTags: [
        {
          tag: 'Brazilian Carnival',
        },
        {
          tag: 'Festa Junina',
        },
      ],
      songDescription: '“Requebra” was written by the samba school Olodum in Bahia, Brazil. In the middle of the 1980s, the group’s head drummer, Neguinho do Samba, mixed Afro-Caribbean rhythms with the traditional Brazilian samba. The result was a new style of samba that came to be known as samba reggae.',
    },
    {
      videoLink: 'https://youtu.be/1hHWXhXM6_s',
      videoImage: celebrationThumbnail2,
      songTitle: 'Requebra',
      artistName: 'Fabiana',
      videoTags: [
        {
          tag: 'Brazilian Carnival',
        },
        {
          tag: 'Festa Junina',
        },
      ],
      songDescription: '“Requebra” was written by the samba school Olodum in Bahia, Brazil. In the middle of the 1980s, the group’s head drummer, Neguinho do Samba, mixed Afro-Caribbean rhythms with the traditional Brazilian samba. The result was a new style of samba that came to be known as samba reggae.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Fabiana',
      artistId: 'fabiana',
      artistImage: fabianaImage,
    },
  ],
};
