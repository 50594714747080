import smallHeaderImage from '../assets/hotspot/headerImages/michael-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/michael-l.jpg';
import mus1 from '../assets/hotspot/pins/qian-yi.jpg';
import mus2 from '../assets/hotspot/pins/layth.jpg';
import introImage from '../assets/hotspot/intro/michael.png';
import postcard from '../assets/hotspot/postcardPdf/Michael_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/MichaelPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/michael_concert.png';

export const michael = {
  name: 'Michael',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Michael, who sings Bluegrass music!',
    details: '',
    vimeoLink: 'https://vimeo.com/187215699',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/F6c5CRWtNGc',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Six/Michael',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Michael sing “Uncle Pen”',
      link: 'Learn “Uncle Pen”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Six/Michael/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/277715148%3Fsecret_token%3Ds-i7cUs&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Michael sing “In the Pines”',
      link: 'Learn “In the Pines”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Six/Michael/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/277715134%3Fsecret_token%3Ds-nv6g8&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Gregorio appears on stage with',
    artists: [
      {
        id: 1,
        image: mus1,
        name: 'Quan Yi',
        query: 'qian-yi',
      },
      {
        id: 2,
        image: mus2,
        name: 'Layth',
        query: 'layth',
      },
    ],
    linkOutText: 'Learn more about Michael in Musical Explorers Program Six.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Six/',
  },
};
