// @flow

import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import {
  Modal,
  ModalOverlay,
  ModalClose,
  ModalCloseIcon,
  ModalContent,
} from './style';

// eslint-disable-next-line
const modalRoot = document.getElementById('modal-root');

type Props = {
  children: any,
  closeModal: Function,
  ariaLabel: string,
}

export const ModalComponent = ({ children, closeModal, ariaLabel }: Props) => {
  const hotspotEl = document.querySelector('#hotspot');

  const keyHandler = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  const closeRef = useRef(null);

  useEffect(() => {
    // $FlowFixMe
    closeRef.current.focus();
    // Stop scrolling on ios since overflow: hidden is ignored
    if (hotspotEl) disableBodyScroll(hotspotEl);

    return () => { clearAllBodyScrollLocks(); };
  });

  const renderContainer = () => (
    <FocusLock>
      <Modal
        role='dialog'
        aria-label={ariaLabel}
        aria-modal='true'
        tabIndex='-1'
        onKeyUp={keyHandler}
      >
        <ModalOverlay>
          <ModalClose
            type='button'
            onClick={closeModal}
            aria-label='Close Modal'
            // $FlowFixMe
            ref={closeRef}
          >
            <ModalCloseIcon
              xmlns='http://www.w3.org/2000/svg'
              width='23'
              height='23'
              fill='none'
              viewBox='0 0 23 23'
            >
              <path
                fill='#fff'
                fillRule='evenodd'
                d='M20.86 1.06a1.5 1.5 0 010 2.122L3.182 20.86a1.5 1.5 0 11-2.121-2.122L18.738 1.061a1.5 1.5 0 012.122 0z'
                clipRule='evenodd'
              />
              <path
                fill='#fff'
                fillRule='evenodd'
                d='M20.982 21.06a1.5 1.5 0 01-2.121 0L1.183 3.384a1.5 1.5 0 112.121-2.121l17.678 17.677a1.5 1.5 0 010 2.122z'
                clipRule='evenodd'
              />
            </ModalCloseIcon>
          </ModalClose>
          <ModalContent>
            {children}
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </FocusLock>
  );
  return ReactDOM.createPortal(
    renderContainer(),
    // $FlowFixMe
    document.body,
  );
};
