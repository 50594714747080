import coverPhoto from '../assets/hotspot/celebrationCover/full-moon-festival.jpg';
import vanAnhImage from '../assets/hotspot/headerImages/van-anh-l.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/van-anh-co-doi.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/vananh_codoithuong_concert.png';

export const fullMoonFestival = {
  celebrationTitle: 'Full Moon Festival',
  startDate: '09-15',
  endDate: '10-10',
  filterValue: 'fullMoonFestival',
  displayDate: 'Mid-September to Mid-October',
  celebrationImage: coverPhoto,
  description: 'The Full Moon Festival is an important day in Vietnam for children and adults alike. Celebrating the autumn harvest, adults spend time with loved ones. The celebrations spread to the streets with lanterns, colorful masks, songs, and dancing.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/Wh48vzIbKzo',
      videoImage: celebrationThumbnail1,
      songTitle: 'Cô Đôi Thượng Ngàn',
      artistName: 'Vân-Ánh',
      videoTags: [
        {
          tag: 'Lunar New Year',
        },
        {
          tag: 'International Children’s Day',
        },
        {
          tag: 'Full Moon Festival',
        },
      ],
      songDescription: 'The title of the song “Cô Đôi Thượng Ngàn” literally means “Queen of the Forest,” and it is a Northern Vietnamese folk song. The song celebrates the Queen of the Forest, an immortal figure who looks after everyone who needs her help. This song is sung at ceremonies, festivals, rituals at temples, and even at home.',
    },
    {
      videoLink: 'https://youtu.be/jDsP_oNBFYM',
      videoImage: celebrationThumbnail2,
      songTitle: 'Cô Đôi Thượng Ngàn',
      artistName: 'Vân-Ánh',
      videoTags: [
        {
          tag: 'Lunar New Year',
        },
        {
          tag: 'International Children’s Day',
        },
        {
          tag: 'Full Moon Festival',
        },
      ],
      songDescription: 'The title of the song “Cô Đôi Thượng Ngàn” literally means “Queen of the Forest,” and it is a Northern Vietnamese folk song. The song celebrates the Queen of the Forest, an immortal figure who looks after everyone who needs her help. This song is sung at ceremonies, festivals, rituals at temples, and even at home.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Vân-Ánh',
      artistId: 'vanAnh',
      artistImage: vanAnhImage,
    },
  ],
};
