import smallHeaderImage from '../assets/hotspot/headerImages/zulal-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/zulal-l.jpg';
import mus1 from '../assets/hotspot/pins/gregorio.jpg';
import mus2 from '../assets/hotspot/pins/soul-science-lab.jpg';
import introImage from '../assets/hotspot/intro/zulal.png';
import postcard from '../assets/hotspot/postcardPdf/Zulal_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/ZulalPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/zulal_concert.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/zulal_tamzara_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/zulal_donizar_video.png';
import celebrationThumbnail3 from '../assets/hotspot/celebrationPerformances/zulal_doniyar_concert.png';
import celebrationThumbnail4 from '../assets/hotspot/celebrationPerformances/zulal_tamzara_concert.png';

export const zulal = {
  name: 'Zulal',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Zulal, who sing Armenian folk music!',
    details: '',
    vimeoLink: 'https://vimeo.com/202970367',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=sOcEiupK37Q',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Zulal',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Zulal sing “Doni Yar”',
      link: 'Learn “Doni Yar”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Zulal/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/277715088%3Fsecret_token%3Ds-yGBFn&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Zulal sing “Tamzara”',
      link: 'Learn “Tamzara”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Five/Zulal/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/277715092%3Fsecret_token%3Ds-TkmoJ&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Zulal appears on stage with',
    artists: [
      {
        id: 1,
        image: mus1,
        name: 'Gregorio',
        query: 'gregorio',
      },
      {
        id: 2,
        image: mus2,
        name: 'Soul Science Lab',
        query: 'soul-science-lab',
      },
    ],
    linkOutText: 'Learn more about Zulal in Musical Explorers Program Five.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Five/',
  },
  traditionBlurb: 'Armenian folk songs tell stories of traditional village life in Armenia: Girls cast fortunes by moonlight, morning smoke rises from the hearth, young brides weave golden threads through their hair as others spin wool into gossip. They were traditionally sung in unison, accompanied by one instrument.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/wwP8CLv571Y',
      videoImage: celebrationThumbnail1,
      songTitle: 'Tamzara',
      artistName: 'Zulal',
      videoTags: [
        {
          tag: 'Hampartsoum',
        },
      ],
      songDescription: '“Tamzara” is a song about girls and boys dancing together. The Tamzara is a dance with a distinctive 9/8 rhythm. The strong and weak beats fall in a surprising, unexpected pattern, making the dance feel special.',
    },
    {
      videoLink: 'https://youtu.be/hwvuqobRg7Us',
      videoImage: celebrationThumbnail2,
      songTitle: 'Doni Yar',
      artistName: 'Zulal',
      videoTags: [
        {
          tag: 'Trndez',
        },
      ],
      songDescription: '“Doni Yar” isn Armenian folk song that celebrates what people in a community have in common. The lyrics paint a picture of a community that is able to thrive because they share the fruits and vegetables that grow in their gardens.',
    },
    {
      videoLink: 'https://youtu.be/zwiiQYzRrjM',
      videoImage: celebrationThumbnail3,
      songTitle: 'Tamzara',
      artistName: 'Zulal',
      videoTags: [
        {
          tag: 'Hampartsoum',
        },
      ],
      songDescription: '“Tamzara” is a song about girls and boys dancing together. The Tamzara is a dance with a distinctive 9/8 rhythm. The strong and weak beats fall in a surprising, unexpected pattern, making the dance feel special.',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=sOcEiupK37Q',
      videoImage: celebrationThumbnail4,
      songTitle: 'Doni Yar',
      artistName: 'Zulal',
      videoTags: [
        {
          tag: 'Trndez',
        },
      ],
      songDescription: '“Doni Yar” isn Armenian folk song that celebrates what people in a community have in common. The lyrics paint a picture of a community that is able to thrive because they share the fruits and vegetables that grow in their gardens.',
    },
  ],
};
