import coverPhoto from '../assets/hotspot/celebrationCover/trndez.jpg';
import zulalImage from '../assets/hotspot/headerImages/zulal-l.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/zulal_donizar_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/zulal_doniyar_concert.png';

export const trndez = {
  celebrationTitle: 'Trndez',
  startDate: '02-01',
  endDate: '02-28',
  filterValue: 'trndez',
  displayDate: 'Dates Vary Each Year',
  celebrationImage: coverPhoto,
  description: 'Trndez is a feast of purification that celebrates the coming of spring. Today, Armenian Christians celebrate Trndez through bonfires that honor the sun and fire, an Armenian tradition that precedes Christianity.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/hwvuqobRg7Us',
      videoImage: celebrationThumbnail1,
      songTitle: 'Doni Yar',
      artistName: 'Zulal',
      videoTags: [
        {
          tag: 'Trndez',
        },
      ],
      songDescription: '“Doni Yar” isn Armenian folk song that celebrates what people in a community have in common. The lyrics paint a picture of a community that is able to thrive because they share the fruits and vegetables that grow in their gardens.',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=sOcEiupK37Q',
      videoImage: celebrationThumbnail2,
      songTitle: 'Doni Yar',
      artistName: 'Zulal',
      videoTags: [
        {
          tag: 'Trndez',
        },
      ],
      songDescription: '“Doni Yar” isn Armenian folk song that celebrates what people in a community have in common. The lyrics paint a picture of a community that is able to thrive because they share the fruits and vegetables that grow in their gardens.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Zulal',
      artistId: 'zulal',
      artistImage: zulalImage,
    },
  ],
};
