export const getFilterSections = (filterItems) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const sixWeeksLater = new Date(currentDate.getTime() + 42 * 24 * 60 * 60 * 1000); // 6 weeks from now

  return filterItems.reduce(
    (sections, item) => {
      if (!item.startDate || !item.endDate) {
        // Include items without a start or end date in the upcoming section
        sections.upcoming.push(item);
        return sections;
      }

      const [startMonth, startDay] = item.startDate.split('-').map(Number);
      const [endMonth, endDay] = item.endDate.split('-').map(Number);

      const startDate = new Date(currentYear, startMonth - 1, startDay);
      const endDate = new Date(currentYear, endMonth - 1, endDay);

      const isHappeningNow = currentDate >= startDate && currentDate <= endDate;
      const isComingUp = startDate <= sixWeeksLater && startDate > currentDate;

      if (isHappeningNow || isComingUp) {
        sections.happening.push(item);
      } else {
        sections.upcoming.push(item);
      }

      return sections;
    },
    { upcoming: [], happening: [] },
  );
};
