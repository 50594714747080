import coverPhoto from '../assets/hotspot/celebrationCover/dominican-independence.jpg';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/yasser-palo-bonito.png';
import yasserImage from '../assets/hotspot/headerImages/yasser-l.jpg';

export const dominicanIndependenceDay = {
  celebrationTitle: 'Dominican Independence Day',
  startDate: '02-27',
  endDate: '02-27',
  filterValue: 'dominicanIndependenceDay',
  celebrationImage: coverPhoto,
  displayDate: 'February 27',
  description: 'On February 27, 1844, the Dominican Republic officially became independent from the occupying forces of Haiti, after previously freeing itself from Spanish colonial rule. Every year on this day Dominicans display  their country’s flag and celebrate with carnaval parades.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/NC03T1p3JK4',
      videoImage: celebrationThumbnail,
      songTitle: 'Palo Bonito',
      artistName: 'Yasser',
      videoTags: [
        {
          tag: 'Dominican Independence Day',
        },
      ],
      songDescription: '“Palo Bonito” is based on a musical style called palo, a national rhythm and celebration that can be found throughout the Dominican Republic. The rhythms of palo are rooted in traditions from the Congo region of central Africa. This is a sacred tradition, often played at religious ceremonies and on special occasions.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Yasser',
      artistId: 'yasser',
      artistImage: yasserImage,
    },
  ],
};
