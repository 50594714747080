export const initialState = {
  // components
  wrapperComponent: null,
  contentComponent: null,
  svgContentComponent: null,
  svgContentComponentDimensions: null,
  // settings
  limitToBounds: true,
  disabled: false,
  // position
  transformEnabled: true,
  defaultPositionX: 0,
  defaultPositionY: 0,
  positionX: 0,
  positionY: 0,
  // scale
  zoomingEnabled: true,
  defaultScale: 1,
  scale: 1,
  maxScale: 2.5,
  minScale: 1,
  previousScale: 1,
  // wheel
  wheelStep: 4,
  enableWheel: true,
  enableTouchPadPinch: true,
  // pinch
  pinchEnabled: true,
  startPinchDistance: null,
  // panning
  panningEnabled: true,
  enableZoomedOutPanning: false,
  lockAxisX: false,
  lockAxisY: false,
  // controls
  dbClickEnabled: true,
  dbClickMode: 'zoomIn',
  zoomInStep: 16,
  zoomOutStep: 16,
  dbClickStep: 200,
  // zoomPosition
  zoomScale: 1,
  zoomPositionX: 0,
  zoomPositionY: 0,
  activeRef: null,
  shouldReset: false,
  // controlStates
  controlXMin: false,
  controlXMax: false,
  controlYMin: false,
  controlYMax: false,
  // resetMap
  shouldFilterResetMap: false,
};
