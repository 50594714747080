import styled from 'styled-components/macro';

export const CardTitle = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-family: ${({ theme }) => theme.fontFamily2};
`;

export const CardImage = styled.img`
  width: 100%; 
  height: 180px;
  object-fit: cover; 
  transition: transform 0.3s ease-in-out;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors.primaryBlue};
  border-radius: 16px;
  overflow: hidden; 
  width: calc(50% - 8px);

  &:hover {
    cursor: pointer;

    ${CardTitle} {
        text-decoration: underline;
    }

    ${CardImage} {
        transform: scale(1.05);
    }
  }

  ${({ theme: { media } }) => media.desktop`
  width: 100%;
`}
`;

export const CardImageContainer = styled.div`
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 14px 14px 0 0;
  flex-shrink: 0;

  ${({ theme: { media } }) => media.desktop`
  height: 180px;
  `}
`;

export const CardDate = styled.p`
  margin: 0;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.primaryDarkTeal};
`;

export const TextContainer = styled.div`
  padding: 10px 12px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 11px;
`;
