// @flow

import React from 'react';
import { HotspotVideo } from '../HotspotVideo';
import { VideoIntroVideo } from '../HotspotIntroVideo/style';
import { ConcertVideoContainer, Headline, HeadlineFlex } from './style';


type Props = {
  video: {
    vimeoLink: string,
    videoImage: any,
  },
}

export const HotspotConcertVideo = ({
  video: {
    vimeoLink,
    videoImage,
  },
}: Props) => (
  <ConcertVideoContainer>
    <HeadlineFlex>
      <Headline>Concert Video</Headline>
    </HeadlineFlex>
    <VideoIntroVideo>
      <HotspotVideo videoImage={videoImage} vimeoLink={vimeoLink} />
    </VideoIntroVideo>
  </ConcertVideoContainer>
);
