// @flow
export const typography = {
  fontFamily1: 'Cadiz',
  fontFamily2: 'CadizBlack',
  fontSize: {
    f1: 10,
  },
  fontWeight: {
    regular: 400,
    semiBold: 500,
  },
};
