/* eslint-disable */
import { calculateBoundingArea } from './utils';
import { checkPositionBounds } from './_zoom';

function getSizes(wrapperComponent, contentComponent, svgContentComponentDimensions) {
  const wrapperRect = wrapperComponent.getBoundingClientRect();
  const contentRect = contentComponent.getBoundingClientRect();

  const wrapperWidth = wrapperRect.width;
  const wrapperHeight = wrapperRect.height;
  const contentWidth = contentRect.width;
  const contentHeight = contentRect.height;
  const diffWidth = wrapperWidth - contentWidth;
  const diffHeight = wrapperHeight - contentHeight;
  const svgContentWidth = svgContentComponentDimensions.width;
  const svgContentHeight = svgContentComponentDimensions.height;

  return {
    wrapperWidth,
    wrapperHeight,
    contentWidth,
    diffWidth,
    contentHeight,
    diffHeight,
    svgContentWidth,
    svgContentHeight,
  };
}

function handleCalculateBounds(wrapperComponent, contentComponent, enableZoomedOutPanning, svgContentComponentDimensions) {
  const {
    wrapperWidth,
    contentWidth,
    diffWidth,
    wrapperHeight,
    contentHeight,
    diffHeight,
    svgContentWidth,
    svgContentHeight,
  } = getSizes(wrapperComponent, contentComponent, svgContentComponentDimensions);
  return calculateBoundingArea(
    wrapperWidth,
    contentWidth,
    diffWidth,
    wrapperHeight,
    contentHeight,
    diffHeight,
    enableZoomedOutPanning,
    svgContentWidth,
    svgContentHeight,
  );
}

export function getClientPosition(event) {
  const { touches } = event;

  // Mobile points
  if (touches && touches.length === 1) {
    return { clientX: touches[0].clientX, clientY: touches[0].clientY };
  }
  // Desktop points
  if (!touches) {
    return { clientX: event.clientX, clientY: event.clientY };
  }
  return null;
}

// Recalculates the bounds on resize
export function handleResizeEnd() {
  const {
    wrapperComponent,
    contentComponent,
    svgContentComponentDimensions,
    enableZoomedOutPanning,
    scale,
  } = this.state;

  const newSvgContentDimensions = {
    width: svgContentComponentDimensions.width * scale,
    height: svgContentComponentDimensions.height * scale,
  };

  const calculatedBounds = handleCalculateBounds(
    wrapperComponent,
    contentComponent,
    enableZoomedOutPanning,
    newSvgContentDimensions,
  );
  this.bounds = calculatedBounds;
}

export function handlePanningControls(coordinate, positionChange) {
  const {
    wrapperComponent,
    contentComponent,
    svgContentComponentDimensions,
    enableZoomedOutPanning,
    limitToBounds,
    positionX,
    positionY,
  } = this.state;

  let { bounds } = this;

  if (!bounds) {
    // console.log('NO BOUNDS');
    const calculatedBounds = handleCalculateBounds(
      wrapperComponent,
      contentComponent,
      enableZoomedOutPanning,
      svgContentComponentDimensions,
    );
    this.bounds = calculatedBounds;
    bounds = calculatedBounds;
  }

  const newPositionX = coordinate === 'x' ? positionChange : positionX;
  const newPositionY = coordinate === 'y' ? positionChange : positionY;

  const calculatedPosition = checkPositionBounds(newPositionX, newPositionY, bounds, limitToBounds);

  // Save panned position
  this.setState({
    positionX: calculatedPosition.x,
    positionY: calculatedPosition.y,
    controlXMin: calculatedPosition.controlXMin,
    controlXMax: calculatedPosition.controlXMax,
    controlYMin: calculatedPosition.controlYMin,
    controlYMax: calculatedPosition.controlYMax,
  });
}

export function handlePanning(event) {
  const {
    wrapperComponent,
    contentComponent,
    svgContentComponentDimensions,
    enableZoomedOutPanning,
    limitToBounds,
    positionX,
    positionY,
    lockAxisX,
    lockAxisY,
  } = this.state;

  if (!this.startCoords) return;
  const { x, y } = this.startCoords;

  const positions = getClientPosition(event);
  if (!positions) return console.error('Cannot find mouse client positions');
  const { clientX, clientY } = positions;

  // Calculate bounding area
  let { bounds } = this;

  // console.log('🐛', bounds);

  // If panning is fired before scaling and there are no bounds calculated
  if (!bounds) {
    const calculatedBounds = handleCalculateBounds(
      wrapperComponent,
      contentComponent,
      enableZoomedOutPanning,
      svgContentComponentDimensions,
    );
    this.bounds = calculatedBounds;
    bounds = calculatedBounds;
  }

  // Get Position
  const newPositionX = lockAxisX ? positionX : (clientX - x);
  const newPositionY = lockAxisY ? positionY : (clientY - y);

  // If position didn't change
  if (newPositionX === positionX && newPositionY === positionY) {
    return;
  }

  const calculatedPosition = checkPositionBounds(newPositionX, newPositionY, bounds, limitToBounds);

  // Save panned position
  this.setState({
    positionX: calculatedPosition.x,
    positionY: calculatedPosition.y,
    controlXMin: calculatedPosition.controlXMin,
    controlXMax: calculatedPosition.controlXMax,
    controlYMin: calculatedPosition.controlYMin,
    controlYMax: calculatedPosition.controlYMax,
  });
}

export function handleCentering() {
  const MOBILE_SIZE = 720;
  const isMobile = window.innerWidth <= MOBILE_SIZE;
  const {
    positionX, positionY, activeRef,
  } = this.state;
  const markerBoundings = activeRef.getBoundingClientRect();
  // MARKER WIDTH AND HEIGHT WHEN SCALED AT 2.5 then divided by 2 to get the center points
  const markerWidthCenter = markerBoundings.width / 2;
  const markerHeightCenter = markerBoundings.height / 2;

  // X and Y coordinates of where the marker should center too
  const centerY = isMobile ? 80 + markerHeightCenter : window.innerHeight / 2;
  const centerX = isMobile ? window.innerWidth / 2 : (window.innerWidth - 500) / 2;

  // Minus the center points from the bottom and right points so that we have center coordinates of the marker rather than top and bottom
  const activeXCenter = markerBoundings.right - markerWidthCenter;
  const activeYCenter = markerBoundings.bottom - markerHeightCenter;

  // Minus the marker activeX position from the current position X so we know the distance between the current transform position
  const posXDiffFromOrigin = activeXCenter - positionX;

  // Minus the marker activeY position from the current position Y so we know the distance between the current transform position
  const posYDiffFromOrigin = activeYCenter - positionY;

  // Minus the position where it should be centered(centerY/centerX) from position diff(posXDiffFromOrigin/posYDiffFromOrigin)
  const originXDiffFromCenter = centerX - posXDiffFromOrigin;
  const originYDiffFromCenter = centerY - posYDiffFromOrigin;

  this.setState({
    positionX: originXDiffFromCenter,
    positionY: originYDiffFromCenter,
  });
}
