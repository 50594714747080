import coverPhoto from '../assets/hotspot/celebrationCover/mes-de-la-hispanidad.jpg';
import juanJuliaConcertThumbnail1 from '../assets/hotspot/celebrationPerformances/junajulia_oladelamar_video.png';
import juanJuliaConcertThumbnail2 from '../assets/hotspot/celebrationPerformances/juanjulia_oladelamar_concert.png';
import sofiasConcertThumbnail1 from '../assets/hotspot/celebrationPerformances/sofias_chacarera_video.png';
import sofiasConcertThumbnail2 from '../assets/hotspot/celebrationPerformances/sofias_chacarera_concert.png';
import sofiasImage from '../assets/hotspot/headerImages/sofia-l.jpg';
import juanJuliaImage from '../assets/hotspot/headerImages/juan-l.jpg';

export const hispanicHeritageMonth = {
  celebrationTitle: 'Mes de la Hispanidad (Hispanic Heritage Month)',
  startDate: '09-15',
  endDate: '10-15',
  filterValue: 'hispanicHeritageMonth',
  displayDate: 'September 15–October 15',
  celebrationImage: coverPhoto,
  description: 'Each year from September 15 to October 15, people in the US recognize and honor the cultures and histories of American citizens whose ancestors came from Spain, Mexico, the Caribbean, and Central and South America.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/Vj_5rIR1uY0',
      videoImage: juanJuliaConcertThumbnail1,
      songTitle: 'Ola de la Mar',
      artistName: 'Juan and Julia',
      videoTags: [
        {
          tag: 'Mes de la Hispanidad (Hispanic Heritage Month)',
        },
      ],
      songDescription: '“Ola de la Mar” is a plena song meaning “Wave of the Sea.” In plenas, the singer always improvises and leads the dancers. Lyrics are often inspired by current events, and thus plena has gotten the nickname “el periódico cantado,” or “the sung newspaper.”',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=wWGx-Ts2BsI',
      videoImage: sofiasConcertThumbnail1,
      songTitle: 'Chacarera del Ranchos',
      artistName: 'Sofía R. and Sofia T.',
      videoTags: [
        {
          tag: 'Mes de la Hispanidad (Hispanic Heritage Month)',
        },
      ],
      songDescription: '“Chacarera del Rancho” is a well known Argentinian song and dance. The song depicts someone who is preparing her home for celebrations.  With a musical instrument, unique decorations, and a kitchen, the singer appreciates what she has and looks forward to a bright future.',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=e3OQ3hJa-0k&t=2s',
      videoImage: juanJuliaConcertThumbnail2,
      songTitle: 'Ola de la Mar',
      artistName: 'Juan and Julia',
      videoTags: [
        {
          tag: 'Mes de la Hispanidad (Hispanic Heritage Month)',
        },
      ],
      songDescription: '“Ola de la Mar” is a plena song meaning “Wave of the Sea.” In plenas, the singer always improvises and leads the dancers. Lyrics are often inspired by current events, and thus plena has gotten the nickname “el periódico cantado,” or “the sung newspaper.”',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=n5VF3o2rh4o',
      videoImage: sofiasConcertThumbnail2,
      songTitle: 'Chacarera del Ranchos',
      artistName: 'Sofía R. and Sofia T.',
      videoTags: [
        {
          tag: 'Mes de la Hispanidad (Hispanic Heritage Month)',
        },
      ],
      songDescription: '“Chacarera del Rancho” is a well known Argentinian song and dance. The song depicts someone who is preparing her home for celebrations.  With a musical instrument, unique decorations, and a kitchen, the singer appreciates what she has and looks forward to a bright future.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Juan and Julia',
      artistId: 'juan-julia',
      artistImage: juanJuliaImage,
    },
    {
      artistName: 'Sofía R. and Sofia T.',
      artistId: 'sofia-sofia',
      artistImage: sofiasImage,
    },
  ],
};
