import qianImage from '../assets/hotspot/headerImages/qian-yi-l.jpg';
import vanAnhImage from '../assets/hotspot/headerImages/van-anh-l.png';
import coverPhoto from '../assets/hotspot/celebrationCover/lunar-new-year.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/van-anh-co-doi.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/qian-yi-gong-xi.png';
import celebrationThumbnail3 from '../assets/hotspot/celebrationPerformances/vananh_codoithuong_concert.png';
import celebrationThumbnail4 from '../assets/hotspot/celebrationPerformances/qianye_gongxi_concert.png';


export const lunarNewYear = {
  celebrationTitle: 'Lunar New Year',
  startDate: '01-29',
  endDate: '02-12',
  filterValue: 'lunarNewYear',
  celebrationImage: coverPhoto,
  displayDate: 'Dates Vary Each Year',
  description: 'The Lunar New Year takes place on the first new moon of the calendar year and is celebrated by billions of people all over the world.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/Wh48vzIbKzo',
      videoImage: celebrationThumbnail1,
      songTitle: 'Cô Đôi Thượng Ngàn',
      artistName: 'Vân-Ánh',
      videoTags: [
        {
          tag: 'Lunar New Year',
        },
      ],
      songDescription: '“Cô Đôi Thượng Ngàn” is a Vietnamese folk song performed at ceremonies and festivals such as New Year\'s celebrations. It\'s a folk song that celebrates the Queen of the Forest who rules all of the land. The queen is an immortal character living on top of mountains and forests, known for her magical ability to take care of those who suffered from sickness.',
    },
    {
      videoLink: 'https://youtu.be/Yq8I-_bK4sU',
      videoImage: celebrationThumbnail2,
      songTitle: 'Gong Xi, Gong Xi',
      artistName: 'Qian Yi',
      videoTags: [
        {
          tag: 'Lunar New Year',
        },
      ],
      songDescription: 'The song “Gong Xi, Gong Xi” is most often associated with the Lunar New Year in China. While the lyrics are celebratory in nature, the original composer, Chen Ge Xin, actually wrote this song at the culmination of the Second Sino-Japanese War in 1945. The references to the “snow melting” and “time for spring” are allegories to the end of a difficult time of turmoil in China, with hope for starting over again. Because of these lyrics, the song became a part of the Chinese New Year repertoire.',
    },
    {
      videoLink: 'https://youtu.be/jDsP_oNBFYM',
      videoImage: celebrationThumbnail3,
      songTitle: 'Cô Đôi Thượng Ngàn',
      artistName: 'Vân-Ánh',
      videoTags: [
        {
          tag: 'Lunar New Year',
        },
      ],
      songDescription: '“Cô Đôi Thượng Ngàn” is a Vietnamese folk song performed at ceremonies and festivals such as New Year\'s celebrations. It\'s a folk song that celebrates the Queen of the Forest who rules all of the land. The queen is an immortal character living on top of mountains and forests, known for her magical ability to take care of those who suffered from sickness.',
    },
    {
      videoLink: 'https://youtu.be/ODKQNw8djqs',
      videoImage: celebrationThumbnail4,
      songTitle: 'Gong Xi, Gong Xi',
      artistName: 'Qian Yi',
      videoTags: [
        {
          tag: 'Lunar New Year',
        },
      ],
      songDescription: 'The song “Gong Xi, Gong Xi” is most often associated with the Lunar New Year in China. While the lyrics are celebratory in nature, the original composer, Chen Ge Xin, actually wrote this song at the culmination of the Second Sino-Japanese War in 1945. The references to the “snow melting” and “time for spring” are allegories to the end of a difficult time of turmoil in China, with hope for starting over again. Because of these lyrics, the song became a part of the Chinese New Year repertoire.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Vân-Ánh',
      artistId: 'vanAnh',
      artistImage: vanAnhImage,
    },
    {
      artistName: 'Qian Yi',
      artistId: 'qian-yi',
      artistImage: qianImage,
    },
  ],
};
