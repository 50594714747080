import styled from 'styled-components/macro';

export const Modal = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.2);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WelcomeModalOverlay = styled.div`
  width: 100%;
`;

export const WelcomeModalClose = styled.button`
  --top: 20px;
  --right: 30px;

  margin: 0;
  padding: 0;
  line-height: 1;
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  outline: 0;
  position: absolute;
  z-index: 1;
  right: var(--right);
  top: var(--top);
  cursor: pointer;

  path {
    transition: fill 200ms;
  }

  &:focus {
    outline: 1px dashed ${({ theme }) => theme.colors.alizarinCrimson};
    outline-offset: 5px;
  }

  &:hover {
    ${({ theme: { media } }) => media.desktop`
      path {
        fill: ${({ theme }) => theme.colors.alizarinCrimson};
      }
    `}
  }
`;

export const WelcomeModalContent = styled.div`
  max-width: calc(450px + 20px);
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
`;

export const WelcomeModalMessage = styled.div`
  --padding: 64px 20px 40px;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;
  padding: var(--padding);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);

  ${({ theme: { media } }) => media.hotspotColumnChange`
    --padding: 55px 38px 40px;
  `}
`;

export const WelcomeModalCloseIcon = styled.svg``;

export const WelcomeHeadline = styled.h1`
  --fs: 24px;
  font-size: var(--fs);
  font-family: ${({ theme }) => theme.fontFamily2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  margin: 0;
  position: relative;

  ${({ theme: { media } }) => media.hotspotColumnChange`
    --fs: 34px;
  `}
`;

export const WelcomeText = styled.p`
  --fs: 12px;
  --lh: 1.67;
  --mt: 20px;

  font-size: var(--fs);
  line-height: var(--lh);
  max-width: 323px;
  margin: var(--mt) auto 0;

  ${({ theme: { media } }) => media.hotspotColumnChange`
    --fs: 16px;
    --lh: 1.25;
    --mt: 30px;
  `}
`;
