// @flow

import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Views
import { MapView } from '../views/map';

export const Router = () => (
  <Switch>
    <Route
      exact
      path='/'
      component={MapView}
    />
  </Switch>
);
