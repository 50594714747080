import styled from 'styled-components';

export const HeaderImage = styled.img`
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
`;

export const HeaderImageContainer = styled.div`
  width: 100%;
  height: 228px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const TitleBanner = styled.div`
  padding: 8px 20px;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  display: flex;
  justify-content: space-between;
`;

export const CelebrationTitle = styled.h1`
  font-size: 31px;
  letter-spacing: 0.062px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin: 0;
`;

export const DateRange = styled.p`
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.primaryLightTeal};
  text-align: center;
  margin: 0;
`;

export const Description = styled.div`
  padding: 24px 20px;
`;


export const ArtistHeadline = styled.span`
  font-size: 24px;
  letter-spacing: -0.24px;
  padding: 0 20px 24px 20px;
  font-family: ${({ theme }) => theme.fontFamily2};
`;

export const ArtistsContainer = styled.div`
  padding-left: 20px;
  padding-bottom: 24px;
  margin-top: 24px;
  display: flex;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

export const ArtistInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
`;

export const ArtistImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 150px;
`;

export const ArtistName = styled.span`
  font-family: ${({ theme }) => theme.fontFamily2};
  font-size: 14px;
`;

export const CelebrationsContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 150px;
`;

export const PerformanceHeadline = styled.span`
  font-size: 24px;
  letter-spacing: -0.24px;
  font-family: ${({ theme }) => theme.fontFamily2};
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const VideoTags = styled.div`
  display: flex;
  gap: 11px;
`;

export const Tag = styled.span`
  display: flex;
  align-items: center;  
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.primaryBlue};
  padding: 6px 10px;
  border-radius: 38px;
  border: 1px solid  ${({ theme }) => theme.colors.primaryBlue};
  min-height: 23px;
  text-align: center;

  &.active {
    border: 2px solid ${({ theme }) => theme.colors.primaryBlue};
    background-color: ${({ theme }) => theme.colors.primaryBrightBlue};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const SongDescription = styled.p``;
