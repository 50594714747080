import coverPhoto from '../assets/hotspot/celebrationCover/arab-american-history.jpg';
import farahImage from '../assets/hotspot/headerImages/farah-l.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/farah-reedaha.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/farah_reedaha_concert.png';

export const arabAmericanHistoryMonth = {
  celebrationTitle: 'Arab American History Month',
  startDate: '04-01',
  endDate: '04-30',
  filterValue: 'arabAmericanHistoryMonth',
  celebrationImage: coverPhoto,
  displayDate: 'The Month of April',
  description: 'Arab American History Month is celebrated in April and recognizes the significant Arab population in America, as well as the cultural contributions of Arab Americans.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/sHtuXCGTX-8',
      videoImage: celebrationThumbnail1,
      songTitle: 'Reedaha” / “Ya Khayal il Zarga',
      artistName: 'Farah',
      videoTags: [
        {
          tag: 'Arab American History Month',
        },
        {
          tag: 'Eid al-Fitr',
        },
        {
          tag: 'Jerash Festival',
        },
      ],
      songDescription: '“Reedaha” is a very old song passed orally through generations, and this one folds in the song “Ya Khayal il Zarga.” Both songs are ghazals, a type of love song that might express flirtation, longing, or a loss of love.',
    },
    {
      videoLink: 'https://youtu.be/wjY2pMyJr1U',
      videoImage: celebrationThumbnail2,
      songTitle: 'Reedaha” / “Ya Khayal il Zarga',
      artistName: 'Farah',
      videoTags: [
        {
          tag: 'Arab American History Month',
        },
        {
          tag: 'Eid al-Fitr',
        },
        {
          tag: 'Jerash Festival',
        },
      ],
      songDescription: '“Reedaha” is a very old song passed orally through generations, and this one folds in the song “Ya Khayal il Zarga.” Both songs are ghazals, a type of love song that might express flirtation, longing, or a loss of love.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Farah',
      artistId: 'farah',
      artistImage: farahImage,
    },
  ],
};
