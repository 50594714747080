// @flow

import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { WELCOME_DATA } from '../../constants/welcome';

import {
  Modal,
  WelcomeModalOverlay,
  WelcomeModalClose,
  WelcomeModalCloseIcon,
  WelcomeModalContent,
  WelcomeModalMessage,
  WelcomeHeadline,
  WelcomeText,
} from './style';

type Props = {
  closeModal: Function,
}

export const WelcomeModal = ({ closeModal }: Props) => {
  const hotspotEl = document.querySelector('#hotspot');

  const keyHandler = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  const closeRef = useRef(null);

  useEffect(() => {
    // $FlowFixMe
    closeRef.current.focus();
    // Stop scrolling on ios since overflow: hidden is ignored
    if (hotspotEl) disableBodyScroll(hotspotEl);

    return () => { clearAllBodyScrollLocks(); };
  });

  const renderContainer = () => (
    <FocusLock>
      <Modal
        role='dialog'
        aria-label='Welcome to the Musical Explorers Map'
        aria-modal='true'
        tabIndex='-1'
        onKeyUp={keyHandler}
      >
        <WelcomeModalOverlay>
          <WelcomeModalContent>
            <WelcomeModalClose
              type='button'
              onClick={closeModal}
              aria-label='Close Welcome Modal'
              // $FlowFixMe
              ref={closeRef}
            >
              <WelcomeModalCloseIcon
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                fill='none'
                viewBox='0 0 18 18'
              >
                <path fill='#000' d='M.719 0L17.97 16.998l-.719.709L0 .708z' />
                <path fill='#000' d='M18 1.002L.749 18l-.72-.708L17.282.294z' />
              </WelcomeModalCloseIcon>
            </WelcomeModalClose>
            <WelcomeModalMessage>
              <WelcomeHeadline>{WELCOME_DATA.headline}</WelcomeHeadline>
              <WelcomeText>{WELCOME_DATA.text}</WelcomeText>
            </WelcomeModalMessage>
          </WelcomeModalContent>
        </WelcomeModalOverlay>
      </Modal>
    </FocusLock>
  );
  return ReactDOM.createPortal(
    renderContainer(),
    // $FlowFixMe
    document.body,
  );
};
