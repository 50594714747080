// @flow

import React, { useState } from 'react';
import { ModalComponent } from '../Modal';

import {
  ImageContent,
  ImageHeadline,
  ImageLessonLink,
  ImageContainer,
  ImageInner,
  Image,
  LargeImageWrapper,
  LargeImage,
} from './style';

type Props = {
  lesson: {
    headline: string,
    linkTitle: string,
    linkUrl: string,
    image: any,
    imageAlt: string,
  }
};

export const HotspotLessonImage = ({
  lesson: {
    headline,
    linkTitle,
    linkUrl,
    image,
    imageAlt,
  },
}: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleImageClick = () => setModalOpen(true);

  const handleModalClose = () => setModalOpen(false);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setModalOpen(true);
    }
  };

  return (
    <>
      <ImageContent>
        <ImageHeadline>{headline}</ImageHeadline>
        <ImageLessonLink
          href={linkUrl}
          target='_blank'
          rel='noreferrer'
          title={linkTitle}
        >{linkTitle}
        </ImageLessonLink>
      </ImageContent>
      <ImageContainer>
        <ImageInner
          role='button'
          tabIndex='0'
          onClick={handleImageClick}
          onKeyPress={(event) => handleKeyPress(event)}
        >
          <Image src={image} alt={imageAlt} />
        </ImageInner>
      </ImageContainer>
      {isModalOpen && (
        <ModalComponent
          closeModal={() => handleModalClose()}
          ariaLabel='Hotspot enlarged image modal'
        >
          <LargeImageWrapper>
            <LargeImage src={image} alt={imageAlt} />
          </LargeImageWrapper>
        </ModalComponent>
      )}
    </>
  );
};
