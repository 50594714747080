// @flow

import React from 'react';

import {
  StateWrapper,
} from './style';

type Props = {
  text: string,
  classname: string,
}

export const StateTooltip = ({ text, classname }: Props) => (
  <StateWrapper className={classname}>
    {text}
  </StateWrapper>
);
