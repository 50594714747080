
import smallHeaderImage from '../assets/hotspot/headerImages/emeline-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/emeline-l.jpg';
import ilushaPin from '../assets/hotspot/pins/ilusha.jpg';
import imaniPin from '../assets/hotspot/pins/imani.jpg';
import briannaPin from '../assets/hotspot/pins/brianna.jpg';
import tanyaradzwaPin from '../assets/hotspot/pins/tanyaradzwa.jpg';
import introImage from '../assets/hotspot/intro/emeline.png';
import postcard from '../assets/hotspot/postcardPdf/Emeline_Postcard_2.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/EmelinePostcardThumbnail2.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/emeline_concert.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/emeline-panama-mwen.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/emeline_lakaridad_video.png';
import celebrationThumbnail3 from '../assets/hotspot/celebrationPerformances/emeline_lakaridad_concert.png';
import celebrationThumbnail4 from '../assets/hotspot/celebrationPerformances/emeline_panama_concert.png';

export const emeline = {
  name: 'Emeline',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Emeline, who sings Haitian Song Traditions!',
    vimeoLink: 'https://vimeo.com/239526267',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://youtu.be/XWST9b40zcc',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Emeline',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Emeline Sing “A.K.I.K.O.”',
      link: 'Learn “A.K.I.K.O.”',
      linkUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-One/Emeline/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/326227361%3Fsecret_token%3Ds-vQbNM&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Emeline Sing “La Karidad”',
      link: 'Learn “La Karidad”',
      linkUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-One/Emeline/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/326227352%3Fsecret_token%3Ds-iunuK&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 3,
      type: 'audio',
      headline: 'Listen to Emeline sing "Panama Mwen Tombe"',
      link: 'Learn “Panama Mwen Tombe”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Nine/Emeline/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1109240908%3Fsecret_token%3Ds-XLJC6lcDxam&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'In Program One, Emeline appears on stage with',
    artists: [
      {
        id: 1,
        image: ilushaPin,
        name: 'Ilusha',
        query: 'ilusha',
      },
      {
        id: 2,
        image: imaniPin,
        name: 'Imani Uzuri ',
        query: 'imani',
      },
    ],
    linkOutText: 'Learn more about Emeline in Musical Explorers Program One.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-One/',
  },
  relatedArtist2: {
    title: 'In NYC Fall 2021, Emeline appears on stage with',
    artists: [
      {
        id: 1,
        image: briannaPin,
        name: 'Brianna',
        query: 'brianna',
      },
      {
        id: 2,
        image: tanyaradzwaPin,
        name: 'Dr. Tanyaradzwa Uzuri ',
        query: 'tanyaradzwa',
      },
    ],
    linkOutText: 'Learn more about Emeline in Musical Explorers NYC Fall 2021.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/',
  },
  traditionBlurb: 'Haiti’s music blends French, Spanish, African, and indigenous influences, spawning diverse styles. There are many different styles of Haitian music. Perhaps the most popular and culturally significant is compas, a complex dance music characterized by the signature tanbou beat. These popular genres grow directly out of Haitian folk traditions, with many folk elements incorporated into contemporary pop music.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/Oh9pcJiYnmc',
      videoImage: celebrationThumbnail1,
      songTitle: 'Panama Mwen Tombe',
      artistName: 'Emeline',
      videoTags: [
        {
          tag: 'Haitian Independence Day',
        },
      ],
      songDescription: 'The song “Panama Mwen Tombe” features the tanbou drum, the national instrument of Haiti.',
    },
    {
      videoLink: 'https://youtu.be/0hYpD0rFk38',
      videoImage: celebrationThumbnail2,
      songTitle: 'La Karidad',
      artistName: 'Emeline',
      videoTags: [
        {
          tag: 'Rara',
        },
      ],
      songDescription: 'Emeline’s original song “La Karidad” is in the style of compas, one of the most popular styles of music in Haiti. Compas features the tanbou drum that propels the dance. The basic movement of compas is simple. You step to the right and then to the left, while swishing your hips.',
    },
    {
      videoLink: 'https://youtu.be/Oh9pcJiYnmc',
      videoImage: celebrationThumbnail3,
      songTitle: 'Panama Mwen Tombe',
      artistName: 'Emeline',
      videoTags: [
        {
          tag: 'Haitian Independence Day',
        },
      ],
      songDescription: 'The song “Panama Mwen Tombe” features the tanbou drum, the national instrument of Haiti.',
    },
    {
      videoLink: 'https://youtu.be/eiHB1-MhMt8',
      videoImage: celebrationThumbnail4,
      songTitle: 'La Karidad',
      artistName: 'Emeline',
      videoTags: [
        {
          tag: 'Rara',
        },
      ],
      songDescription: 'Emeline’s original song “La Karidad” is in the style of compas, one of the most popular styles of music in Haiti. Compas features the tanbou drum that propels the dance. The basic movement of compas is simple. You step to the right and then to the left, while swishing your hips.',
    },
  ],
};
