// @flow

import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { ModalComponent } from '../Modal';

import {
  VideoWrapper,
  VideoButton,
  VideoImage,
  VideoPlay,
  VideoPlayText,
  VideoPlayIcon,
  VideoTitle,
} from './style';

type Props = {
  vimeoLink: string,
  videoImage: any,
  songTitle?: string | null,
  artistName?: string | null,
}

export const HotspotVideo = ({
  vimeoLink, videoImage, artistName, songTitle,
}: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const closeRef = useRef(null);
  const handleVideoClick = () => setModalOpen(true);

  const handleModalClose = () => {
    setModalOpen(false);
    // $FlowFixMe
    setTimeout(() => closeRef.current.focus(), 0);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setModalOpen(true);
    }
  };

  return (
    <>
      <VideoButton
        role='button'
        tabIndex='0'
        onClick={handleVideoClick}
        onKeyPress={(event) => handleKeyPress(event)}
        // $FlowFixMe
        ref={closeRef}
      >
        <VideoImage src={videoImage} alt='fill me in' />
        {artistName && songTitle
       && <VideoTitle>{`Listen to ${artistName} Sing “${songTitle}”`}</VideoTitle>}
        <VideoPlay>
          <VideoPlayIcon
            xmlns='http://www.w3.org/2000/svg'
            width='8'
            height='9'
            fill='none'
            viewBox='0 0 8 9'
          >
            <path fill='#fff' d='M7.872 4.843L.85 8.897V.79l7.022 4.054z' />
          </VideoPlayIcon>
          <VideoPlayText>Play Video</VideoPlayText>
        </VideoPlay>
      </VideoButton>
      {isModalOpen && (
        <ModalComponent
          closeModal={() => handleModalClose()}
          ariaLabel='Hotspot video modal'
        >
          <VideoWrapper>
            <ReactPlayer
              url={vimeoLink}
              playing
              controls
              className='react-player'
              width='100%'
              height='auto'
            />
          </VideoWrapper>
        </ModalComponent>
      )}
    </>
  );
};

HotspotVideo.defaultProps = {
  songTitle: null,
  artistName: null,
};
