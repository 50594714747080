import styled from 'styled-components/macro';
import arrow from '../../assets/icons/arrow.svg';
import close from '../../assets/icons/close.svg';

export const HotspotContainer = styled.aside`
  --top: 250px;
  --width: 100%;
  background: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: var(--top);
  width: var(--width);
  z-index: 2;
  min-height: 100%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  transform: translate3d(0%, 0, 0);
  will-change: transform;

  ${({ theme: { media } }) => media.hotspotPositionChange`
    --top: 60px;
    --width: 500px;
    height: calc(100% - 60px);
    right: 0;
    min-height: auto;
    transform: translate3d(0%, 0, 0);
  `}

  &:focus {
    ${({ theme: { media } }) => media.hotspotPositionChange`
      outline: 1px dashed ${({ theme }) => theme.colors.alizarinCrimson};
    }
  `}

  &.hotspot-enter {
    transform: translate3d(0%, 90%, 0);
    ${({ theme: { media } }) => media.hotspotPositionChange`
      transform: translate3d(100%, 0, 0);
    `}
  }

  &.hotspot-enter-active {
    transform: translate3d(0%, 0, 0);
    transition: transform 600ms;
  }

  &.hotspot-exit {
    transform: translate3d(0%, 0, 0);
  }

  &.hotspot-exit-active {
    transform: translate3d(0%, 90%, 0);
    transition: transform 600ms;
    ${({ theme: { media } }) => media.hotspotPositionChange`
      transform: translate3d(100%, 0, 0);
    `}
  }

  &.celebrations {
    top: 60px;
  }
`;

export const HotspotContent = styled.article`
  overflow: hidden;

  ${({ theme: { media } }) => media.hotspotPositionChange`
    overflow: scroll;
    position: absolute;
    height: 100%;
    width: 100%;
  `}
`;

export const HotspotCloseButton = styled.button`
  --width: 38px;
  --height: 38px;
  --background: url(${close});
  --right: 0;

  margin: 0;
  padding: 0;
  line-height: 1;
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  outline: 0;
  position: absolute;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.alizarinCrimson};
  background-image: var(--background);
  background-repeat: no-repeat;
  background-position: center;
  width: var(--width);
  height: var(--height);
  font-size: 0;
  right: var(--right);

  &:focus {
    ${({ theme: { media } }) => media.hotspotPositionChange`
    outline: 1px dashed ${({ theme }) => theme.colors.white};
    `}
  }

  ${({ theme: { media } }) => media.hotspotPositionChange`
    --width: 32px;
    --height: 62px;
    --background: url(${arrow});
    --right: 100%;

    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: -5px 2px 6px -5px rgba(0, 0, 0, 0.25);
  `}
`;


export const HotspotCTA = styled.a`
  border: 1px solid ${({ theme }) => theme.colors.alizarinCrimson};
  padding: 8px 14px;
  color: ${({ theme }) => theme.colors.alizarinCrimson};
  text-decoration: none;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.032px;
  text-align: center;


  ${({ theme: { media } }) => media.hotspotPositionChange`
    width: fit-content;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.044px;
  `}


  &:hover {
    text-decoration: underline;
  }
`;

export const ExploreButtonContainer = styled.div`
  margin: 24px auto 40px auto;
  display: flex;
  justify-content: center;
  width: fit-content;
`;
