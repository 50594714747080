import styled from 'styled-components/macro';

export const VideoIntro = styled.div`
  --padding: 20px 20px 10px;
  padding: var(--padding);
  grid-gap: 24px;
  display: flex;
  flex-direction: column;

  ${({ theme: { media } }) => media.hotspotColumnChange`
    --padding: 20px 27px 60px;
    grid-template-columns: 1fr 183px;
  `}
`;

export const VideoIntroContent = styled.div`
`;

export const VideoIntroHeadline = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily2};
  font-size: 24px;
  margin: 0;

  ${({ theme: { media } }) => media.hotspotColumnChange`
    line-height: 1.5;
  `}
`;
export const VideoIntroSubline = styled.p`
  font-family: ${({ theme }) => theme.fontFamily1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 6px 0;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.alizarinCrimson};
  }
`;

export const VideoIntroVideo = styled.div`
  display: grid;
  display: grid;
  position: relative;
  width: 100%;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  ${({ theme: { media } }) => media.hotspotColumnChange`
    height: 240px;
    width: 100%;
  `}

  > * {
    grid-area: 1 / 1 / 2 / 2;
  }

  &::before {
    content:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 9"></svg>');
    grid-area: 1 / 1 / 2 / 2;

    ${({ theme: { media } }) => media.hotspotColumnChange`
      height: 106px;
      width: 100%;
    `}
  }
`;
