import styled from 'styled-components/macro';

export const Button = styled.button`
  --width: 33px;
  --transform: translate(-50%, -50%);
  --caretColor: ${({ theme }) => theme.colors.black};
  --afterWidth: 19px;
  --afterHeight: 19px;

  margin: 0;
  padding: 0;
  line-height: 1;
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  outline: 0;
  font-size: 0;
  width: var(--width);
  height: 33px;
  position: absolute;
  background: ${({ theme }) => theme.colors.white};

  ${({ IsWide }) => IsWide && `
    --width: 50%;
  `}

  &.caret {
    --afterHeight: 10px;
    --afterWidth: 18px;
  }

  &.minus {
    --afterHeight: 2px;
  }

  &::after {
    content: '';
    position: absolute;
    mask: url(${({ Mask }) => `${Mask}`});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    height: var(--afterHeight);
    width: var(--afterWidth);
    top: 50%;
    left: 50%;
    transform: var(--transform);
    background: var(--caretColor);
  }

  &:hover:not([disabled]) {
    --caretColor: ${({ theme }) => theme.colors.alizarinCrimson};
    cursor: pointer;
  }

  &:focus {
    outline: 1px dashed ${({ theme }) => theme.colors.alizarinCrimson};
    outline-offset: -1px;
  }

  &:disabled {
    opacity: 0.4;
  }
`;
