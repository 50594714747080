// @flow

import styled from 'styled-components/macro';
import { PillWrapper } from '../CelebrationPill/style';

export const HeaderContainer = styled.header`
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 60px;
  background: ${({ theme }) => theme.colors.white};
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
`;

export const HeaderInner = styled.div`
  --padding: 0 ${({ theme }) => (theme.baseSpacing * 2.5)}px; 
  --headerPadding: ${({ theme }) => (theme.baseSpacing * 2.5)}px;
  margin: 0 auto;
  display: flex;
  gap: 11px;
  justify-content: space-between;
  align-items: center;
  max-width:
    calc(${({ theme }) => (theme.baseSpacing * 339)}px +
        (var(--headerPadding) * 2));
  width: 100%;
  padding: var(--padding);

  ${({ theme: { media } }) => media.tablet`
    --padding: 0 ${({ theme }) => (theme.baseSpacing * 15)}px; // 60
  `}

  ${({ theme: { media } }) => media.desktop`
    --headerPadding: ${({ theme }) => (theme.baseSpacing * 5)}px; // 20
  `}

  ${({ theme: { media } }) => media.headerWidth`
    --padding: 0 ${({ theme }) => (theme.baseSpacing * 5)}px; // 20
  `}
`;

export const HeaderTextButton = styled.button`
  margin: 0;
  padding: 0 10px 0 0;
  line-height: 1;
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  outline: 0;
  font-size: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    color: ${({ theme }) => theme.colors.alizarinCrimson};
  }

  &:focus {
    outline: 1px dashed ${({ theme }) => theme.colors.alizarinCrimson};
    outline-offset: 3px;
  }
`;

export const HeaderTextContainer = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily2};
  font-size: 16px;
  transition: color 300ms;

  ${({ theme: { media } }) => media.desktop`
    font-size: 20px;
  `}
`;

export const HeaderTextContainerDesktop = styled.span`
  display: none;

  ${({ theme: { media } }) => media.desktop`
    display: inline-block;
    white-space: pre;
  `}
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 23px;
`;

export const ActiveCelebration = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  ${PillWrapper} {
    padding: 6px 20px 6px 12px;
  }
`;


export const ClearFiltersButton = styled.button`
  outline: 0;
  background: none;
  border: none;
`;
