import styled from 'styled-components';

export const PerformancesContainer = styled.div``;

export const SectionHeading = styled.div`
  padding 24px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.032px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

export const VideosContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const TagsWrapper = styled.div`
  display: flex;
  gap: 11px;
`;

export const Tag = styled.span`
  padding: 0 10px;
  border-radius: 38px;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.2px;
  min-height: 23px;
  display: flex;  
  align-items: center;

  &.active {
    background-color: ${({ theme }) => theme.colors.primaryBrightBlue};
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.primaryBlue};
  }
`;

export const SongDescription = styled.p``;
