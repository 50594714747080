import coverPhoto from '../assets/hotspot/celebrationCover/fiestas-de-la-calle-san-sebastian.jpg';
import concertThumbnail1 from '../assets/hotspot/celebrationPerformances/juanjulia_estoy_video.png';
import concertThumbnail2 from '../assets/hotspot/celebrationPerformances/juanjulia_estoybuscando_concert.png';
import juanJuliaImage from '../assets/hotspot/headerImages/juan-l.jpg';

export const fiestasDeLaCalleSanSebastian = {
  celebrationTitle: 'Fiestas de la Calle San Sebastián',
  startDate: '01-16',
  endDate: '01-20',
  filterValue: 'fiestasDeLaCalleSanSebastian',
  displayDate: 'The Third Week of January',
  celebrationImage: coverPhoto,
  description: 'Every year on the third week of January, Puerto Rico celebrates Saint Sebastian over multiple days. During the day artists display and sell their work, and at night, concerts and dancing fill the streets.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/kYO_xQItHO0',
      videoImage: concertThumbnail1,
      songTitle: 'Estoy Buscando un Árbol',
      artistName: 'Juan and Julia',
      videoTags: [
        {
          tag: 'Fiestas de la Calle San Sebastián',
        },
        {
          tag: 'Fiestas de Santiago Apostol / Fiestas de Loiza',
        },
      ],
      songDescription: '“Estoy Buscando un Árbol” is a popular bomba song meaning “I Am in Search of a Tree.” All bomba songs are conversations (with call and response) between the dancers and the musicians, and the dancers lead the way.',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=JDtf1YoXe_E',
      videoImage: concertThumbnail2,
      songTitle: 'Estoy Buscando un Árbol',
      artistName: 'Juan and Julia',
      videoTags: [
        {
          tag: 'Fiestas de la Calle San Sebastián',
        },
        {
          tag: 'Fiestas de Santiago Apostol / Fiestas de Loiza',
        },
      ],
      songDescription: '“Estoy Buscando un Árbol” is a popular bomba song meaning “I Am in Search of a Tree.” All bomba songs are conversations (with call and response) between the dancers and the musicians, and the dancers lead the way.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Juan and Julia',
      artistId: 'juan-julia',
      artistImage: juanJuliaImage,
    },
  ],
};
