import coverPhoto from '../assets/hotspot/celebrationCover/jamhuri-day.jpg';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/makobi_mwana_video.png';
import makobiImage from '../assets/hotspot/headerImages/makobi-l.jpg';

export const jamhuriDay = {
  celebrationTitle: 'Jamhuri Day',
  startDate: '12-12',
  endDate: '12-12',
  filterValue: 'jamhuriDay',
  displayDate: 'December 12',
  celebrationImage: coverPhoto,
  description: 'Meaning “republic” in Swahili, Jamhuri Day is a Kenyan annual holiday celebrating when Kenya became a republic. Jamhuri Day celebrations include feasts, political speeches, and dancing.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/Xo7y1SmKIfE',
      videoImage: celebrationThumbnail,
      songTitle: 'Mwana wa Mbeli',
      artistName: 'Makobi',
      videoTags: [
        {
          tag: 'Jamhuri Day',
        },
      ],
      songDescription: '“Mwana wa Mbeli” was traditionally performed to celebrate the firstborn of a family. To this day, the song continues to be sung in the spirit of pride, joy, and celebration, and now this song is performed at graduations, weddings, or any happy occasion.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Makobi',
      artistId: 'makobi',
      artistImage: makobiImage,
    },
  ],
};
