// @flow

import React from 'react';

import {
  AudioContainer,
  AudioHeadline,
  AudioLink,
  AudioEmbed,
  LinkContainer,
} from './style';

type Props = {
  lesson: {
    headline: string,
    link: string,
    linkUrl: string,
    link2: string,
    linkUrl2: string,
    soundcloudEmbed: string,
  }
}

export const HotspotLessonAudio = ({
  lesson: {
    headline, link, linkUrl, link2, linkUrl2, soundcloudEmbed,
  },
}: Props) => {
  const setSoundcloudHTML = () => ({ __html: soundcloudEmbed });

  return (
    <AudioContainer>
      <AudioHeadline>{headline}</AudioHeadline>
      <LinkContainer>
        <AudioLink
          href={linkUrl}
          target='_blank'
          rel='noreferrer'
          title={link}
        >
          {link}
        </AudioLink>
        <AudioLink
          href={linkUrl2}
          target='_blank'
          rel='noreferrer'
          title={link2}
        >
          {link2}
        </AudioLink>
      </LinkContainer>
      <AudioEmbed>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={setSoundcloudHTML()} />
      </AudioEmbed>
    </AudioContainer>
  );
};
