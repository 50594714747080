/* eslint-disable */
/**
 * Rounds number to given decimal
 * eg. roundNumber(2.34343, 1) => 2.3
 */
export const roundNumber = (num, decimal = 5) => Number(num.toFixed(decimal));

/**
 * Checks if value is number, if not it returns default value
 * 1# eg. checkIsNumber(2, 30) => 2
 * 2# eg. checkIsNumber(null, 30) => 30
 */
export const checkIsNumber = (num, defaultValue) => (typeof num === 'number' ? num : defaultValue);

/**
 * Keeps value between given bounds, used for limiting view to given boundaries
 * 1# eg. boundLimiter(2, 0, 3, true) => 2
 * 2# eg. boundLimiter(4, 0, 3, true) => 3
 * 3# eg. boundLimiter(-2, 0, 3, true) => 0
 * 4# eg. boundLimiter(10, 0, 3, false) => 10
 */

export const boundLimiter = (value, minBound, maxBound, isActive) => {
  if (!isActive) return roundNumber(value, 2);
  if (value <= minBound) return { value: roundNumber(minBound, 2), limit: 'minBound' };
  if (value >= maxBound) return { value: roundNumber(maxBound, 2), limit: 'maxBound' };
  return { value: roundNumber(value, 2), limit: 'none' };
};

/**
 * Returns relative coords of mouse on wrapper element, and provides
 * info about it's width, height, with same info about its content(zoomed component) element
 */
export const relativeCoords = (event, wrapperComponent, contentComponent, panningCase) => {
  const wrapperWidth = wrapperComponent.offsetWidth;
  const wrapperHeight = wrapperComponent.offsetHeight;
  const contentRect = contentComponent.getBoundingClientRect();
  const contentWidth = contentRect.width;
  const contentHeight = contentRect.height;
  const contentLeft = contentRect.left;
  const contentRight = contentRect.right;
  const diffHeight = wrapperHeight - contentHeight;
  const diffWidth = wrapperWidth - contentWidth;

  // mouse position x, y over wrapper component
  let x = panningCase ? event.clientX : event.clientX - contentRect.left;
  let y = panningCase ? event.clientY : event.clientY - contentRect.top;

  // Mobile touch event case
  if (isNaN(x) && event.touches && event.touches[0]) {
    x = event.touches[0].clientX;
    y = event.touches[0].clientY;
  }

  return {
    x,
    y,
    wrapperWidth,
    wrapperHeight,
    contentWidth,
    contentHeight,
    diffHeight,
    diffWidth,
    contentLeft,
    contentRight,
  };
};

/**
 * Calculate bounding area of zoomed/panned element
 */
export const calculateBoundingArea = (
  wrapperWidth,
  contentWidth,
  diffWidth,
  wrapperHeight,
  contentHeight,
  diffHeight,
  enableZoomedOutPanning,
  svgContentWidth: 0,
  svgContentHeight: 0,
) => {
  const scaleWidthFactor = wrapperWidth > contentWidth ? diffWidth * (enableZoomedOutPanning ? 1 : 0.5) : 0;
  const scaleHeightFactor = wrapperHeight > contentHeight ? diffHeight * (enableZoomedOutPanning ? 1 : 0.5) : 0;

  const svgWidthDiff = (svgContentWidth - contentWidth) / 2;
  const svgHeightDiff = (svgContentHeight - contentHeight) / 2;

  // This is to add some white space around the svg container so there is extra room to drag
  const halfWrapperWidth = wrapperWidth / 2;
  const halfWrapperHeight = wrapperHeight / 2;

  // This plugin was meant for content that is always 100% of the wrapper but in this case the svgcontainer is wider than the wrapper so i need to account for the extra space.

  // if the svgContent width is greater than than the content width then minpositionx is wrapperWidth - contentWidth - scalefactor minus the the extra width of the svgWidthDiff - 50% of the wrapper
  const minPositionX = svgContentWidth > contentWidth
    ? (wrapperWidth - contentWidth - scaleWidthFactor) - svgWidthDiff - halfWrapperWidth
    : wrapperWidth - contentWidth - scaleWidthFactor - halfWrapperWidth;
  const maxPositionX = svgContentWidth > contentWidth
    ? (0 + scaleWidthFactor) + svgWidthDiff + halfWrapperWidth
    : 0 + scaleWidthFactor + halfWrapperWidth;

  // In this case the height of the svgcontainer is shorter than the wrapper so we need to find the different between the heights(contentHeight-svgContentHeight) then divide that by two. This number gets added to the minPositionY and subtracted from the maxPositionY.
  const SvgHeightDiffFromWrapper = ((contentHeight - svgContentHeight) / 2);

  const minPositionY = svgContentHeight > contentHeight
    ? (wrapperHeight - contentHeight - scaleHeightFactor) - (svgHeightDiff) - (halfWrapperHeight) + SvgHeightDiffFromWrapper
    : wrapperHeight - contentHeight - scaleHeightFactor - (halfWrapperHeight) + SvgHeightDiffFromWrapper;
  const maxPositionY = svgContentHeight > contentHeight
    ? (0 + scaleHeightFactor) + (svgHeightDiff) + (halfWrapperHeight) + SvgHeightDiffFromWrapper
    : 0 + scaleHeightFactor + (halfWrapperHeight) - SvgHeightDiffFromWrapper;
  return {
    minPositionX, maxPositionX, minPositionY, maxPositionY,
  };
};

/**
 * Returns middle coordinates x,y of two points
 * Used to get middle point of two fingers pinch
 */

export const getMiddleCoords = (firstPoint, secondPoint, contentComponent, scale) => {
  const contentRect = contentComponent.getBoundingClientRect();

  return {
    x: ((firstPoint.clientX + secondPoint.clientX) / 2 - contentRect.left) / scale,
    y: ((firstPoint.clientY + secondPoint.clientY) / 2 - contentRect.top) / scale,
  };
};

/**
 * Returns middle position of PageX for touch events
 */
export const getMidPagePosition = (firstPoint, secondPoint) => {
  if (!firstPoint || !secondPoint) return console.warn('There are no points provided');
  return {
    x: (firstPoint.clientX + secondPoint.clientX) / 2,
    y: (firstPoint.clientY + secondPoint.clientY) / 2,
  };
};

/**
 * Returns distance between two points x,y
 */
export const getDistance = (firstPoint, secondPoint) => Math.sqrt(
  Math.pow(firstPoint.pageX - secondPoint.pageX, 2)
      + Math.pow(firstPoint.pageY - secondPoint.pageY, 2),
);

/**
 * Delete undefined values from object keys
 * Used for deleting empty props
 */

export const deleteUndefinedProps = (value) => {
  const newObject = { ...value };
  Object.keys(newObject).forEach((key) => newObject[key] == undefined && delete newObject[key]);
  return newObject;
};

/**
 * Fire callback if it's function
 */

export const handleCallback = (callback, props) => {
  if (callback && typeof callback === 'function') {
    callback(props);
  }
};
