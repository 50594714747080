import smallHeaderImage from '../assets/hotspot/headerImages/tanyaradzwa-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/tanyaradzwa-l.jpg';
import briannaPin from '../assets/hotspot/pins/brianna.jpg';
import emelinePin from '../assets/hotspot/pins/emeline.jpg';
import introImage from '../assets/hotspot/intro/tanyaradzwa.png';
import postcard from '../assets/hotspot/postcardPdf/Tanyaradzwa_Postcard_2.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/TanyaradzwaPostcardThumbnail2.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/tanyaradzwa_concert.png';

export const tanyaradzwa = {
  name: 'Dr. Tanyaradzwa',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Dr. Tanyaradzwa, who sings Zimbabwean mbira music!',
    details: '',
    vimeoLink: 'https://vimeo.com/619368568',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=uwnbk27_61w',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/Tanyaradzwa',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Dr. Tanyaradzwa sing "Hurombo Gara Wega"',
      link: 'Learn “Hurombo Gara Wega”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Nine/Tanyaradzwa/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1082431090%3Fsecret_token%3Ds-Bs0qpYTSRak&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Dr. Tanyaradzwa sing "Pamuromo paHaruna"',
      link: 'Learn “Pamuromo paHaruna”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Nine/Tanyaradzwa/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1082416774%3Fsecret_token%3Ds-arQsW3wmicg&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Dr. Tanyaradzwa appears on stage with',
    artists: [
      {
        id: 1,
        image: briannaPin,
        name: 'Brianna',
        query: 'brianna',
      },
      {
        id: 2,
        image: emelinePin,
        name: 'Emeline',
        query: 'emeline',
      },
    ],
    linkOutText: 'Learn more about Dr. Tanyaradzwa in Musical Explorers NYC Fall 2021',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/',
  },
};
