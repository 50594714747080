import smallHeaderImage from '../assets/hotspot/headerImages/starr-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/starr-l.jpg';
import juanJuliaPin from '../assets/hotspot/pins/juan-julia.jpg';
import faluPin from '../assets/hotspot/pins/falu.jpg';
import introImage from '../assets/hotspot/intro/starr.png';
import postcard from '../assets/hotspot/postcardPdf/Starr_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/StarrPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/starr_concert.png';

export const starr = {
  name: 'Starr',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Starr, who sings freedom songs!',
    details: '',
    vimeoLink: 'https://vimeo.com/663156103/4031522ea0',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=QAD4QCZEqcI',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Ten/Starr',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Starr sing "Everybody Wants Freedom"',
      link: 'Learn “Everybody Wants Freedom”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Ten/Starr/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1082445211%3Fsecret_token%3Ds-aJgkD2y6c0a&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Starr sing "Freedom Highway"',
      link: 'Learn “Freedom Highway”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Ten/Starr/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1082444599%3Fsecret_token%3Ds-c5gYsEo6AhS&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Starr appears on stage with',
    artists: [
      {
        id: 1,
        image: juanJuliaPin,
        name: 'Juan and Julia',
        query: 'juan-julia',
      },
      {
        id: 2,
        image: faluPin,
        name: 'Falu',
        query: 'falu',
      },
    ],
    linkOutText: 'Learn more about Starr in Musical Explorers NYC Spring 2022.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/New-York-City/',
  },
};
