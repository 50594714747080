
import styled from 'styled-components';

export const Header = styled.div`
  padding: 8px 24px;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

export const CelebrationText = styled.span`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.036px;
  margin-bottom: 4px;
`;

export const ArtistText = styled.span`
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0.05px;
`;

export const CelebrationHotspotContainer = styled.div`
  width: 100%;
`;

export const Headline = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  font-family: ${({ theme }) => theme.fontFamily2};
  margin-bottom: 14px;
`;

export const IntroSection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primaryLightGray};
`;

export const TextContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderImage = styled.img`
  width: 100%
  height: 184px;
  object-fit: cover;
  object-position: center;
`;

export const Description = styled.p`
  margin: 0 0 20px 0;
`;

export const ExploreButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
  padding 8px 32px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.032px;
  text-decoration: none;
  background: none;
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-family: ${({ theme }) => theme.fontFamily1};
  margin: 0 auto;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
  `;
