import styled from 'styled-components/macro';

export const VideoWrapper = styled.div`
  max-width: 893px;
  margin: 0 auto;
  width: 100%;
  height: 100%;

  .react-player {
    position: relative;
    padding-top: 56.25%;
    }

  .react-player > div {
    position: absolute; 
    top: 0;
  }
`;

export const VideoButton = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;

  &:focus {
    outline: 1px dashed ${({ theme }) => theme.colors.alizarinCrimson};
  }
`;

export const VideoImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 240px;
  object-fit: cover;
  object-position: center;
  vertical-align: bottom;
`;

export const VideoPlay = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 19px 13px 14px;
  background-color: rgba(0,0,0, 0.2);
  border: 1px solid ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: end;
  margin: 0 0 10px 10px;
`;

export const VideoPlayText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  margin-left: 5px;
`;

export const VideoPlayIcon = styled.svg``;

export const VideoTitle = styled.span`
  position: absolute;
  top: 25px;
  left: 25px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
  font-weight: 700;
`;
