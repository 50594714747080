import largeHeaderImage from '../assets/hotspot/headerImages/kalani-l.jpg';
import smallHeaderImage from '../assets/hotspot/headerImages/kalani-s.jpg';
import introImage from '../assets/hotspot/intro/kalani.png';
import postcard from '../assets/hotspot/postcardPdf/Kalani_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/KalaniPostcardThumbnail.jpg';
import celebration1Thumbnail from '../assets/hotspot/celebrationPerformances/kalani-pea-e-na-kini.png';

export const kalani = {
  name: 'Kalani',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Kalani, who sings Mele Hawai‘i!',
    details: '',
    vimeoLink: 'https://vimeo.com/854404297',
    videoImage: introImage,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Artists/Kalani',
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Kalani sing “E Nā Kini”',
      link: 'Learn “E Nā Kini”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Artists/Kalani/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1605372987%3Fsecret_token%3Ds-OsZTNKb6Ikh&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Kalani sing “‘Ōpae Ē, ‘Opae Ē”',
      link: 'Learn “‘Ōpae Ē, ‘Opae Ē”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Artists/Kalani/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1605373116%3Fsecret_token%3Ds-bsfjAxRWvCf&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>',
    },
  ],
  traditionBlurb: 'The traditional songs of Hawai‘i include a variety of mele (poetic recitation) and music meant for the highly ritualized dance called hula. In early Hawai‘i, mele were the most important means for indigenous Hawaiians to remember the stories of gods or the heroic deeds of their leaders, and they were passed down orally from generation to generation.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/SweNj30zsC0',
      videoImage: celebration1Thumbnail,
      songTitle: 'E Nā Kini',
      artistName: 'Kalani',
      videoTags: [
        {
          tag: 'Merrie Monarch Festival',
        },
        {
          tag: 'Hawaiian Independence Day',
        },
        {
          tag: 'Kamehameha Day Parade',
        },
      ],
      songDescription: '“E Nā Kini” was written during a difficult time in Hawaiian history when thousands of individuals isolated in order to stop the spread of Hansen’s disease (also known as leprosy). This song became an anthem of dignity for the native people, and a rallying call to come together with the land during life\'s challenges',
    },
  ],
};
