/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { StateProvider } from '../store/StateContext';
import { deleteUndefinedProps } from '../store/utils';

const TransformWrapper = ({
  children,
  scale,
  positionX,
  positionY,
  maxScale,
  minScale,
  resetAnimationSpeed,
  limitToBounds,
  zoomingEnabled,
  panningEnabled,
  transformEnabled,
  pinchEnabled,
  enableZoomedOutPanning,
  disabled,
  zoomOutStep,
  zoomInStep,
  dbClickStep,
  dbClickEnabled,
  onWheelStart,
  onWheel,
  onWheelStop,
  onPanningStart,
  onPanning,
  onPanningStop,
  onPinchingStart,
  onPinching,
  onPinchingStop,
  defaultPositionX,
  defaultPositionY,
  defaultScale,
  zoomScale,
  zoomPositionX,
  zoomPositionY,
  activeRef,
  shouldReset,
  controlXMin,
  controlXMax,
  controlYMin,
  controlYMax,
  shouldFilterResetMap,
}) => (
  <StateProvider
    defaultValues={deleteUndefinedProps({
      defaultPositionX,
      defaultPositionY,
      defaultScale,
    })}
    dynamicValues={deleteUndefinedProps({
      scale,
      positionX,
      positionY,
      maxScale,
      minScale,
      resetAnimationSpeed,
      limitToBounds,
      zoomingEnabled,
      panningEnabled,
      transformEnabled,
      pinchEnabled,
      enableZoomedOutPanning,
      disabled,
      zoomOutStep,
      zoomInStep,
      dbClickStep,
      dbClickEnabled,
      zoomScale,
      zoomPositionX,
      zoomPositionY,
      activeRef,
      shouldReset,
      controlXMin,
      controlXMax,
      controlYMin,
      controlYMax,
      shouldFilterResetMap,
    })}
    onWheelStart={onWheelStart}
    onWheel={onWheel}
    onWheelStop={onWheelStop}
    onPanningStart={onPanningStart}
    onPanning={onPanning}
    onPanningStop={onPanningStop}
    onPinchingStart={onPinchingStart}
    onPinching={onPinching}
    onPinchingStop={onPinchingStop}
  >
    {children}
  </StateProvider>
);

TransformWrapper.propTypes = {
  children: PropTypes.any,
  scale: PropTypes.number,
  positionX: PropTypes.number,
  positionY: PropTypes.number,
  sensitivity: PropTypes.number,
  maxScale: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  minScale: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  resetAnimationSpeed: PropTypes.number,
  limitToBounds: PropTypes.bool,
  zoomingEnabled: PropTypes.bool,
  panningEnabled: PropTypes.bool,
  transformEnabled: PropTypes.bool,
  pinchEnabled: PropTypes.bool,
  enableZoomedOutPanning: PropTypes.bool,
  disabled: PropTypes.bool,
  zoomOutStep: PropTypes.number,
  zoomInStep: PropTypes.number,
  dbClickStep: PropTypes.number,
  dbClickEnabled: PropTypes.bool,
  onWheelStart: PropTypes.func,
  onWheel: PropTypes.func,
  onWheelStop: PropTypes.func,
  onPanningStart: PropTypes.func,
  onPanning: PropTypes.func,
  onPanningStop: PropTypes.func,
  onPinchingStart: PropTypes.func,
  onPinching: PropTypes.func,
  onPinchingStop: PropTypes.func,
  defaultPositionX: PropTypes.number,
  defaultPositionY: PropTypes.number,
  defaultScale: PropTypes.number,
  zoomScale: PropTypes.number,
  zoomPositionX: PropTypes.number,
  zoomPositionY: PropTypes.number,
  activeRef: PropTypes.any,
  shouldReset: PropTypes.bool,
  controlXMin: PropTypes.bool,
  controlXMax: PropTypes.bool,
  controlYMin: PropTypes.bool,
  controlYMax: PropTypes.bool,
  shouldFilterResetMap: PropTypes.bool,
};

export default TransformWrapper;
