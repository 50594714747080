import styled from 'styled-components/macro';

export const HotspotTwoColumnContainer = styled.div`
  --padding: 14px 20px 10px;
  --columns: 183px 1fr;
  padding: var(--padding);

  > *:first-child {
    ${({ theme: { media } }) => media.hotspotColumnChange`
      grid-column: 2 / -1;
    `}
  }

  & + & {
    --columns: 1fr 183px;

    > *:first-child {
      ${({ theme: { media } }) => media.hotspotColumnChange`
        grid-column: 1;
      `}
    }

    > *:last-child {
      ${({ theme: { media } }) => media.hotspotColumnChange`
        grid-column: 2 / -1;
      `}
    }
  }

  ${({ theme: { media } }) => media.hotspotColumnChange`
    --padding: 14px 27px 10px;
    display: grid;
    grid-template-columns: var(--columns);
    grid-gap: 20px;
    grid-auto-flow: dense;
    align-items: center;
  `}
`;
