/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import { initialState } from './InitialState';
import { getDistance, handleCallback } from './utils';
import {
  handleZoomWheel,
  handleZoomControls,
  handleZoomDbClick,
  handleActiveZoom,
  resetTransformations,
} from './_zoom';
import { handleZoomPinch } from './_pinch';
import {
  handlePanning,
  handlePanningControls,
  handleCentering,
  handleResizeEnd,
} from './_pan';
import makePassiveEventOption from './makePassiveEventOption';

const Context = React.createContext({});

let timer = null;
const timerTime = 50;

class StateProvider extends Component {
  state = {
    ...initialState,
    ...this.props.defaultValues,
    ...this.props.dynamicValues,
    previousScale: initialState.scale,
    startAnimation: false,
  };

  pinchStartDistance = null;

  lastDistance = null;

  pinchStartScale = null;

  distance = null;

  bounds = null;

  lastMousePosition = null;

  animate = false;

  offsetX = null;

  offsetY = null;

  resizeTimer = null;

  componentDidMount() {
    const passiveOption = makePassiveEventOption(false);

    // Panning on window to allow panning when mouse is out of wrapper
    window.addEventListener('mousedown', throttle(this.handleStartPanning), passiveOption);
    window.addEventListener('mousemove', throttle(this.handlePanning), passiveOption);
    window.addEventListener('mouseup', throttle(this.handleStopPanning), passiveOption);
    window.addEventListener('keydown', throttle(this.handleKeyDown, 250), false);
    window.addEventListener('resize', throttle(this.handleResize), false);
  }

  componentDidUpdate(oldProps, oldState) {
    const {
      wrapperComponent, enableZoomedOutPanning, scale, activeRef, shouldReset, shouldFilterResetMap,
    } = this.state;
    const { dynamicValues } = this.props;
    if (!oldState.wrapperComponent && wrapperComponent) {
      // Zooming events on wrapper
      const passiveOption = makePassiveEventOption(false);
      wrapperComponent.addEventListener('wheel', throttle(this.handleWheel), passiveOption);
      wrapperComponent.addEventListener('dblclick', this.handleDbClick, passiveOption);
      wrapperComponent.addEventListener('touchstart', throttle(this.handleTouchStart), passiveOption);
      wrapperComponent.addEventListener('touchmove', throttle(this.handleTouch), passiveOption);
      wrapperComponent.addEventListener('touchend', throttle(this.handleTouchStop), passiveOption);
    }
    // eslint-disable-next-line react/no-did-update-set-state
    if (oldProps.dynamicValues !== dynamicValues) this.setState({ ...dynamicValues });

    if (
      this.bounds && oldState.enableZoomedOutPanning
      !== enableZoomedOutPanning) { this.bounds = null; }

    if (oldState.activeRef !== activeRef) {
      if (activeRef !== '') {
        if (scale !== 2.5) {
          this.handleActiveMarkerNewPosition();
        } else {
          handleCentering.bind(this)();
        }
      }
    }

    if (oldState.scale !== scale) {
      // Setting scale in state was making the app jumpy, this way a rerender is avoided
      const documentEl = document.documentElement;
      // $FlowFixMe
      documentEl.style.setProperty('--scaleValueNormal', 1 / scale);
      // $FlowFixMe
      documentEl.style.setProperty('--scaleValueLarge', 1.6 / scale);
    }

    if (shouldReset) this.resetTransform();

    if (shouldFilterResetMap) this.resetTransform();
  }

  componentWillUnmount() {
    const passiveOption = makePassiveEventOption(false);
    window.removeEventListener('mousedown', this.handleStartPanning, passiveOption);
    window.removeEventListener('mousemove', this.handlePanning, passiveOption);
    window.removeEventListener('mouseup', this.handleStopPanning, passiveOption);
    window.removeEventListener('resize', this.handleResize, false);
  }

  // ////////
  // Resize
  // ////////
  // When resizing has stopped recalculate the bounds
  handleResize = () => {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      handleResizeEnd.bind(this)();
    }, 200);
  }

  // ////////
  // Keydown
  // ////////

  handleKeyDown = (event) => {
    const { key } = event;
    const { positionY, positionX } = this.state;

    switch (key) {
      case 'ArrowUp':
        handlePanningControls.bind(this, 'y', positionY + 50)();
        break;
      case 'ArrowDown':
        handlePanningControls.bind(this, 'y', positionY - 50)();
        break;
      case 'ArrowLeft':
        handlePanningControls.bind(this, 'x', positionX + 50)();
        break;
      case 'ArrowRight':
        handlePanningControls.bind(this, 'x', positionX - 50)();
        break;
      default:
        break;
    }
  }

  // ////////
  // Wheel
  // ////////

  handleWheel = (event) => {
    const { enableWheel, enableTouchPadPinch } = this.state;
    const { onWheelStart, onWheel, onWheelStop } = this.props;

    // ctrlKey detects if touchpad execute wheel or pinch gesture
    if (!enableWheel && !event.ctrlKey) return;
    if (!enableTouchPadPinch && event.ctrlKey) return;

    if (!timer) {
      // Wheel start event
      handleCallback(onWheelStart, this.getCallbackProps());
    }

    // Wheel event
    handleZoomWheel.bind(this, event)();
    handleCallback(onWheel, this.getCallbackProps());

    // Wheel stop event
    clearTimeout(timer);
    timer = setTimeout(() => {
      handleCallback(onWheelStop, this.getCallbackProps());
      timer = null;
    }, timerTime);
  };

  // ////////
  // Panning
  // ////////

  checkIsPanningActive = (event) => {
    const { panningEnabled, disabled } = this.state;
    return (
      !this.isDown
      || !panningEnabled
      || disabled
      || (event.touches
        && (event.touches.length !== 1 || Math.abs(this.startCoords.x - event.touches[0].clientX) < 1))
    );
  };

  handleSetUpPanning = (x, y) => {
    const { positionX, positionY } = this.state;
    const { onPanningStart } = this.props;
    this.isDown = true;
    this.startCoords = { x: x - positionX, y: y - positionY };
    handleCallback(onPanningStart, this.getCallbackProps());
  };

  handleStartPanning = (event) => {
    const { panningEnabled, disabled, wrapperComponent } = this.state;
    const { target, touches } = event;
    if (!panningEnabled || disabled || !wrapperComponent.contains(target)) return;
    // Mobile points
    if (touches && touches.length === 1) {
      this.handleSetUpPanning(touches[0].clientX, touches[0].clientY);
    }
    // Desktop points
    if (!touches) {
      this.handleSetUpPanning(event.clientX, event.clientY);
    }
  };

  handlePanning = (event) => {
    const { onPanning } = this.props;
    event.preventDefault();
    if (this.checkIsPanningActive(event)) return;
    event.stopPropagation();
    handlePanning.bind(this, event)();
    handleCallback(onPanning, this.getCallbackProps());
  };

  handleStopPanning = () => {
    if (this.isDown) {
      const { onPanningStop } = this.props;
      this.isDown = false;
      handleCallback(onPanningStop, this.getCallbackProps());
    }
  };

  handleActiveMarkerNewPosition = () => {
    handleActiveZoom.bind(this)();
  }

  // ////////
  // Pinch
  // ////////

  handlePinchStart = (event) => {
    const { scale } = this.state;
    const { onPinchingStart } = this.props;
    event.preventDefault();
    event.stopPropagation();

    const distance = getDistance(event.touches[0], event.touches[1]);
    this.pinchStartDistance = distance;
    this.lastDistance = distance;
    this.pinchStartScale = scale;

    handleCallback(onPinchingStart, this.getCallbackProps());
  };

  handlePinch = (event) => {
    const { onPinching } = this.props;
    handleZoomPinch.bind(this, event)();
    handleCallback(onPinching, this.getCallbackProps());
  };

  handlePinchStop = () => {
    if (typeof pinchStartScale === 'number') {
      const { onPinchingStop } = this.props;
      this.pinchStartDistance = null;
      this.lastDistance = null;
      this.pinchStartScale = null;
      handleCallback(onPinchingStop, this.getCallbackProps());
    }
  };

  // ////////
  // Touch Events
  // ////////

  handleTouchStart = (event) => {
    const { disabled } = this.state;
    const { touches } = event;
    if (disabled) return;
    if (touches && touches.length === 1) this.handleStartPanning(event);
    if (touches && touches.length === 2) this.handlePinchStart(event);
  };

  handleTouch = (event) => {
    const { panningEnabled, pinchEnabled, disabled } = this.state;
    if (disabled) return;
    if (panningEnabled && event.touches.length === 1) this.handlePanning(event);
    if (pinchEnabled && event.touches.length === 2) this.handlePinch(event);
  };

  handleTouchStop = (event) => {
    this.handlePinchStop();
    this.handleStopPanning(event);
  };

  // ////////
  // Controls
  // ////////

  resetLastMousePosition = () => this.setState({ lastMouseEventPosition: null });

  zoomIn = (event) => {
    const { zoomingEnabled, disabled, zoomInStep } = this.state;
    const { onWheel } = this.props;
    if (!event) throw Error('Zoom in function require event prop');
    if (!zoomingEnabled || disabled) return;
    handleZoomControls.bind(this, event, 1, zoomInStep)();
    setTimeout(() => {
      handleCallback(onWheel, this.getCallbackProps());
    }, 0);
  };

  zoomOut = (event) => {
    const { zoomingEnabled, disabled, zoomOutStep } = this.state;
    const { onWheel } = this.props;
    if (!event) throw Error('Zoom out function require event prop');
    if (!zoomingEnabled || disabled) return;
    handleZoomControls.bind(this, event, -1, zoomOutStep)();
    setTimeout(() => {
      handleCallback(onWheel, this.getCallbackProps());
    }, 0);
  };

  handleDbClick = (event) => {
    const {
      zoomingEnabled, disabled, dbClickStep, dbClickEnabled,
    } = this.state;
    if (!event) throw Error('Double click function require event prop');
    if (!zoomingEnabled || disabled || !dbClickEnabled) return;
    handleZoomDbClick.bind(this, event, 1, dbClickStep)();
  };

  setScale = (scale) => {
    this.setState({ scale });
  };

  setPositionX = (positionX) => {
    handlePanningControls.bind(this, 'x', positionX)();
  };

  setPositionY = (positionY) => {
    handlePanningControls.bind(this, 'y', positionY)();
  };

  setTransform = (positionX, positionY, scale) => {
    const { transformEnabled } = this.state;
    if (!transformEnabled) return;
    !isNaN(scale) && this.setScale(scale);
    !isNaN(positionX) && this.setPositionX(positionX);
    !isNaN(positionY) && this.setPositionY(positionY);
  };

  resetTransform = () => {
    const { disabled } = this.state;
    if (disabled) return;
    resetTransformations.bind(this)();
  };

  // ////////
  // Setters
  // ////////

  setWrapperComponent = (wrapperComponent) => {
    this.setState({ wrapperComponent });
  };

  setContentComponent = (contentComponent) => {
    this.setState({ contentComponent });
  };

  setSvgContentComponent = (svgContentComponent) => {
    this.setState({
      svgContentComponent,
      svgContentComponentDimensions: {
        width: svgContentComponent.getBoundingClientRect().width,
        height: svgContentComponent.getBoundingClientRect().height,
      },
    });
  };

  // ////////
  // Props
  // ////////
  getCallbackProps = () => ({
    positionX: this.state.positionX,
    positionY: this.state.positionY,
    scale: this.state.scale,
    maxScale: this.state.maxScale,
    minScale: this.state.minScale,
    minPositionX: this.state.minPositionX,
    minPositionY: this.state.minPositionY,
    maxPositionX: this.state.maxPositionX,
    maxPositionY: this.state.maxPositionY,
    limitToBounds: this.state.limitToBounds,
    zoomingEnabled: this.state.zoomingEnabled,
    panningEnabled: this.state.panningEnabled,
    transformEnabled: this.state.transformEnabled,
    pinchEnabled: this.state.pinchEnabled,
    enableZoomedOutPanning: this.state.enableZoomedOutPanning,
    disabled: this.state.disabled,
    zoomOutStep: this.state.zoomOutStep,
    zoomInStep: this.state.zoomInStep,
    dbClickStep: this.state.dbClickStep,
    dbClickEnabled: this.state.dbClickEnabled,
    previousScale: this.state.previousScale,
    zoomScale: this.state.zoomScale,
    zoomPositionX: this.state.zoomPositionX,
    zoomPositionY: this.state.zoomPositionY,
    activeRef: this.state.activeRef,
    controlXMin: this.state.controlXMin,
    controlXMax: this.state.controlXMax,
    controlYMin: this.state.controlYMin,
    controlYMax: this.state.controlYMax,
    shouldFilterResetMap: this.state.shouldFilterResetMap,
  });

  render() {
    /**
     * Context provider value
     */
    const value = {
      state: this.getCallbackProps(),
      dispatch: {
        setScale: this.setScale,
        setPositionX: this.setPositionX,
        setPositionY: this.setPositionY,
        zoomIn: this.zoomIn,
        zoomOut: this.zoomOut,
        setTransform: this.setTransform,
        resetTransform: this.resetTransform,
      },
      nodes: {
        setWrapperComponent: this.setWrapperComponent,
        setContentComponent: this.setContentComponent,
        setSvgContentComponent: this.setSvgContentComponent,
      },
      internal: {
        handleZoom: this.handleZoom,
        handleStartPanning: this.handleStartPanning,
        handlePanning: this.handlePanning,
        handleStopPanning: this.handleStopPanning,
        handleDbClick: this.handleDbClick,
        handleTouchStart: this.handleTouchStart,
        handleTouch: this.handleTouch,
        handleTouchStop: this.handleTouchStop,
      },
    };
    const { children } = this.props;
    const content = typeof children === 'function' ? children({ ...value.state, ...value.dispatch }) : children;

    return <Context.Provider value={value}>{content}</Context.Provider>;
  }
}

StateProvider.defaultProps = {
  defaultValues: {},
  dynamicValues: {},
  onWheelStart: null,
  onWheel: null,
  onWheelStop: null,
  onPanningStart: null,
  onPanning: null,
  onPanningStop: null,
  onPinchingStart: null,
  onPinching: null,
  onPinchingStop: null,
};

StateProvider.propTypes = {
  children: PropTypes.any,
  defaultValues: PropTypes.object,
  dynamicValues: PropTypes.object,
  onWheelStart: PropTypes.func,
  onWheel: PropTypes.func,
  onWheelStop: PropTypes.func,
  onPanningStart: PropTypes.func,
  onPanning: PropTypes.func,
  onPanningStop: PropTypes.func,
  onPinchingStart: PropTypes.func,
  onPinching: PropTypes.func,
  onPinchingStop: PropTypes.func,
};

export { Context, StateProvider };
