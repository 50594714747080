import jazzCover from '../assets/hotspot/celebrationCover/jazz-appreciation.png';
import hipHopCover from '../assets/hotspot/celebrationCover/hip-hop.jpg';
import carnivalBrazilCover from '../assets/hotspot/celebrationCover/carnival-1.jpg';
import carnivalTrinidadCover from '../assets/hotspot/celebrationCover/carnival-2.jpg';
import cincoDeMayoCover from '../assets/hotspot/celebrationCover/cinco-de-mayo.jpg';
import navaratriCover from '../assets/hotspot/celebrationCover/navaratri.png';
import lunarNewYearCover from '../assets/hotspot/celebrationCover/lunar-new-year.jpg';
import merrieMonarchCover from '../assets/hotspot/celebrationCover/merrie-monarch-festival.jpg';
import haitianIndependenceCover from '../assets/hotspot/celebrationCover/haitian-independence.jpg';
import dominicanIndependenceCover from '../assets/hotspot/celebrationCover/dominican-independence.jpg';
import arabAmericanHistoryCover from '../assets/hotspot/celebrationCover/arab-american-history.jpg';
import jordanianIndependenceCover from '../assets/hotspot/celebrationCover/jordanian-independence.jpg';
import heritageDayCover from '../assets/hotspot/celebrationCover/heritage-day.jpg';
import nativeAmericanHeritageCover from '../assets/hotspot/celebrationCover/native-american-heritage.jpg';
import greenCornCeremonyCover from '../assets/hotspot/celebrationCover/green-corn-ceremony-2.jpg';
import hawaiianIndependenceCover from '../assets/hotspot/celebrationCover/hawaiian-independence-day.jpg';
import kamehamehaParadeCover from '../assets/hotspot/celebrationCover/kamehameha-day-parade.jpg';
import fiestasDeLaCalleSanSebastianCover from '../assets/hotspot/celebrationCover/fiestas-de-la-calle-san-sebastian.jpg';
import fiestasDeSantiagoApostolCover from '../assets/hotspot/celebrationCover/fiestas-de-santiago-apostol-fiestas-de-loiza.jpg';
import hispanicHeritageMonthCover from '../assets/hotspot/celebrationCover/mes-de-la-hispanidad.jpg';
import womensDaySouthAfricaCover from '../assets/hotspot/celebrationCover/womens-day-south-africa.jpg';
import internationalChildrensDayCover from '../assets/hotspot/celebrationCover/international-childrens-day.jpg';
import kingQuangTrungCover from '../assets/hotspot/celebrationCover/king-quang-trung-festival.jpg';
import fullMoonFestivalCover from '../assets/hotspot/celebrationCover/full-moon-festival.jpg';
import raraCarnivalCover from '../assets/hotspot/celebrationCover/rara-carnival.jpg';
import fesaJuninaCover from '../assets/hotspot/celebrationCover/festa-junina.jpg';
import eidAlFitrCover from '../assets/hotspot/celebrationCover/eid-al-fitr.jpg';
import jerashFestyivalCover from '../assets/hotspot/celebrationCover/jerash-festival.jpg';
import christmasOrthodoxCover from '../assets/hotspot/celebrationCover/christmas-orthodox.jpg';
import mariamobaCover from '../assets/hotspot/celebrationCover/mariamoba.jpg';
import internationalWomensDayCover from '../assets/hotspot/celebrationCover/international-womens-day.jpg';
import festivalChacareraCover from '../assets/hotspot/celebrationCover/festival-de-la-chacarera-santiago.jpg';
import hampartsoumCover from '../assets/hotspot/celebrationCover/hampartsoumm.jpg';
import trndezCover from '../assets/hotspot/celebrationCover/trndez.jpg';
import africaDayCover from '../assets/hotspot/celebrationCover/africa-day.jpg';
import utamaduniCover from '../assets/hotspot/celebrationCover/utamaduni-day.jpg';
import jamhuriDayCover from '../assets/hotspot/celebrationCover/jamhuri-day.jpg';

// data files
import { cincoDeMayo } from '../celebrationData/cinco-de-mayo';
import { hipHopCelebrationDay } from '../celebrationData/hip-hop-celebration-day';
import { navaratri } from '../celebrationData/navaratri';
import { lunarNewYear } from '../celebrationData/lunar-new-year';
import { cumbiaDay } from '../celebrationData/cumbia-day';
import { valentinesDay } from '../celebrationData/valentines-day';
import { jazzAppreciationMonth } from '../celebrationData/jazz-appreciation-month';
import { haitianIndependenceDay } from '../celebrationData/haitian-independence-day';
import { arabAmericanHistoryMonth } from '../celebrationData/arab-american-history';
import { jordanianIndependenceDay } from '../celebrationData/jordanian-independence-day';
import { heritageDay } from '../celebrationData/heritage-day';
import { nativeAmericanHeritageMonth } from '../celebrationData/native-american-heritage';
import { greenCornCeremony } from '../celebrationData/green-corn-ceremony';
import { trinidadTobagoCarnival } from '../celebrationData/trinidad-tobago-carnival';
import { dominicanIndependenceDay } from '../celebrationData/dominican-independence-day';
import { merrieMonarchFestival } from '../celebrationData/merrie-monarch-festival';
import { brazilianCarnival } from '../celebrationData/carnival-of-brazil';
import { hawaiianIndependenceDay } from '../celebrationData/hawaiian-independence-day';
import { kamehamehaDayParade } from '../celebrationData/kamehameha-day-parade';
import { fiestasDeLaCalleSanSebastian } from '../celebrationData/fiestas-de-la-calle-san-sebastian';
import { fiestasDeSantiagoApostol } from '../celebrationData/fiestas-de-santiago-apostol';
import { hispanicHeritageMonth } from '../celebrationData/hispanic-heritage-month';
import { womensDaySouthAfrica } from '../celebrationData/womens-day-south-africa';
import { internationalChildrensDay } from '../celebrationData/international-childrens-day';
import { kingQuangTrungFestival } from '../celebrationData/king-quang-trung-festival';
import { fullMoonFestival } from '../celebrationData/full-moon-festival';
import { raraCarnival } from '../celebrationData/rara-carnival';
import { festaJunina } from '../celebrationData/festa-junina';
import { eidAlFitr } from '../celebrationData/eid-al-fitr';
import { christmasOrthodox } from '../celebrationData/christmas-orthodox';
import { mariamoba } from '../celebrationData/mariamoba';
import { festivalChacarera } from '../celebrationData/festival-de-la-chacarera';
import { hampartsoum } from '../celebrationData/hampartsoum';
import { trndez } from '../celebrationData/trndez';
import { africaDay } from '../celebrationData/africa-day';
import { jamhuriDay } from '../celebrationData/jamhuri-day';
import { utamaduniDay } from '../celebrationData/utamaduni-day';
import { jerashFestival } from '../celebrationData/jerash-festival';
import { internationalWomensDay } from '../celebrationData/international-womens-day';

export const CELEBRATIONS_FILTERS = [
  {
    sectionTitle: 'Fall',
    sectionDateRange: 'September 22–December 21',
    filterItems: [
      {
        celebrationTitle: navaratri.celebrationTitle,
        startDate: navaratri.startDate,
        endDate: navaratri.endDate,
        filterValue: navaratri.filterValue,
        displayDate: navaratri.displayDate,
        celebrationImage: navaratriCover,
        data: navaratri,
      },
      {
        celebrationTitle: heritageDay.celebrationTitle,
        startDate: heritageDay.startDate,
        endDate: heritageDay.endDate,
        filterValue: heritageDay.filterValue,
        displayDate: heritageDay.displayDate,
        celebrationImage: heritageDayCover,
        data: heritageDay,
      },
      {
        celebrationTitle: nativeAmericanHeritageMonth.celebrationTitle,
        startDate: nativeAmericanHeritageMonth.startDate,
        endDate: nativeAmericanHeritageMonth.endDate,
        filterValue: nativeAmericanHeritageMonth.filterValue,
        displayDate: nativeAmericanHeritageMonth.displayDate,
        celebrationImage: nativeAmericanHeritageCover,
        data: nativeAmericanHeritageMonth,
      },
      {
        celebrationTitle: hawaiianIndependenceDay.celebrationTitle,
        startDate: hawaiianIndependenceDay.startDate,
        endDate: hawaiianIndependenceDay.endDate,
        filterValue: hawaiianIndependenceDay.filterValue,
        displayDate: hawaiianIndependenceDay.displayDate,
        celebrationImage: hawaiianIndependenceCover,
        data: hawaiianIndependenceDay,
      },
      {
        celebrationTitle: hispanicHeritageMonth.celebrationTitle,
        startDate: hispanicHeritageMonth.startDate,
        endDate: hispanicHeritageMonth.endDate,
        filterValue: hispanicHeritageMonth.filterValue,
        displayDate: hispanicHeritageMonth.displayDate,
        celebrationImage: hispanicHeritageMonthCover,
        data: hispanicHeritageMonth,
      },
      {
        celebrationTitle: fullMoonFestival.celebrationTitle,
        startDate: fullMoonFestival.startDate,
        endDate: fullMoonFestival.endDate,
        filterValue: fullMoonFestival.filterValue,
        displayDate: fullMoonFestival.displayDate,
        celebrationImage: fullMoonFestivalCover,
        data: fullMoonFestival,
      },
      {
        celebrationTitle: utamaduniDay.celebrationTitle,
        startDate: utamaduniDay.startDate,
        endDate: utamaduniDay.endDate,
        filterValue: utamaduniDay.filterValue,
        displayDate: utamaduniDay.displayDate,
        celebrationImage: utamaduniCover,
        data: utamaduniDay,
      },
      {
        celebrationTitle: jamhuriDay.celebrationTitle,
        startDate: jamhuriDay.startDate,
        endDate: jamhuriDay.endDate,
        filterValue: jamhuriDay.filterValue,
        displayDate: jamhuriDay.displayDate,
        celebrationImage: jamhuriDayCover,
        data: jamhuriDay,
      },
    ],
  },
  {
    sectionTitle: 'Winter',
    sectionDateRange: 'December 22–March 19',
    filterItems: [
      {
        celebrationTitle: haitianIndependenceDay.celebrationTitle,
        startDate: haitianIndependenceDay.startDate,
        endDate: haitianIndependenceDay.endDate,
        filterValue: haitianIndependenceDay.filterValue,
        displayDate: haitianIndependenceDay.displayDate,
        celebrationImage: haitianIndependenceCover,
        data: haitianIndependenceDay,
      },
      {
        celebrationTitle: dominicanIndependenceDay.celebrationTitle,
        startDate: dominicanIndependenceDay.startDate,
        endDate: dominicanIndependenceDay.endDate,
        filterValue: dominicanIndependenceDay.filterValue,
        displayDate: dominicanIndependenceDay.displayDate,
        celebrationImage: dominicanIndependenceCover,
        data: dominicanIndependenceDay,
      },
      {
        celebrationTitle: cumbiaDay.celebrationTitle,
        startDate: cumbiaDay.startDate,
        endDate: cumbiaDay.endDate,
        filterValue: cumbiaDay.filterValue,
        displayDate: cumbiaDay.displayDate,
        celebrationImage: lunarNewYearCover,
        data: cumbiaDay,
      },
      {
        celebrationTitle: valentinesDay.celebrationTitle,
        startDate: valentinesDay.startDate,
        endDate: valentinesDay.endDate,
        filterValue: valentinesDay.filterValue,
        displayDate: valentinesDay.displayDate,
        celebrationImage: lunarNewYearCover,
        data: valentinesDay,
      },
      {
        celebrationTitle: lunarNewYear.celebrationTitle,
        startDate: lunarNewYear.startDate,
        endDate: lunarNewYear.endDate,
        filterValue: lunarNewYear.filterValue,
        displayDate: lunarNewYear.displayDate,
        celebrationImage: lunarNewYearCover,
        data: lunarNewYear,
      },
      {
        celebrationTitle: fiestasDeLaCalleSanSebastian.celebrationTitle,
        startDate: fiestasDeLaCalleSanSebastian.startDate,
        endDate: fiestasDeLaCalleSanSebastian.endDate,
        filterValue: fiestasDeLaCalleSanSebastian.filterValue,
        displayDate: fiestasDeLaCalleSanSebastian.displayDate,
        celebrationImage: fiestasDeLaCalleSanSebastianCover,
        data: fiestasDeLaCalleSanSebastian,
      },
      {
        celebrationTitle: kingQuangTrungFestival.celebrationTitle,
        startDate: kingQuangTrungFestival.startDate,
        endDate: kingQuangTrungFestival.endDate,
        filterValue: kingQuangTrungFestival.filterValue,
        displayDate: kingQuangTrungFestival.displayDate,
        celebrationImage: kingQuangTrungCover,
        data: kingQuangTrungFestival,
      },
      {
        celebrationTitle: christmasOrthodox.celebrationTitle,
        startDate: christmasOrthodox.startDate,
        endDate: christmasOrthodox.endDate,
        filterValue: christmasOrthodox.filterValue,
        displayDate: christmasOrthodox.displayDate,
        celebrationImage: christmasOrthodoxCover,
        data: christmasOrthodox,
      },
      {
        celebrationTitle: festivalChacarera.celebrationTitle,
        startDate: festivalChacarera.startDate,
        endDate: festivalChacarera.endDate,
        filterValue: festivalChacarera.filterValue,
        displayDate: festivalChacarera.displayDate,
        celebrationImage: festivalChacareraCover,
        data: festivalChacarera,
      },
      {
        celebrationTitle: trndez.celebrationTitle,
        startDate: trndez.startDate,
        endDate: trndez.endDate,
        filterValue: trndez.filterValue,
        displayDate: trndez.displayDate,
        celebrationImage: trndezCover,
        data: trndez,
      },
      {
        celebrationTitle: brazilianCarnival.celebrationTitle,
        startDate: brazilianCarnival.startDate,
        endDate: brazilianCarnival.endDate,
        filterValue: brazilianCarnival.filterValue,
        displayDate: brazilianCarnival.displayDate,
        celebrationImage: carnivalBrazilCover,
        data: brazilianCarnival,
      },
      {
        celebrationTitle: internationalWomensDay.celebrationTitle,
        startDate: internationalWomensDay.startDate,
        endDate: internationalWomensDay.endDate,
        filterValue: internationalWomensDay.filterValue,
        displayDate: internationalWomensDay.displayDate,
        celebrationImage: internationalWomensDayCover,
        data: internationalWomensDay,
      },
      {
        celebrationTitle: trinidadTobagoCarnival.celebrationTitle,
        startDate: trinidadTobagoCarnival.startDate,
        endDate: trinidadTobagoCarnival.endDate,
        filterValue: trinidadTobagoCarnival.filterValue,
        displayDate: trinidadTobagoCarnival.displayDate,
        celebrationImage: carnivalTrinidadCover,
        data: trinidadTobagoCarnival,
      },
    ],
  },
  {
    sectionTitle: 'Spring',
    sectionDateRange: 'March 20–June 19',
    filterItems: [
      {
        celebrationTitle: jazzAppreciationMonth.celebrationTitle,
        startDate: jazzAppreciationMonth.startDate,
        endDate: jazzAppreciationMonth.endDate,
        filterValue: jazzAppreciationMonth.filterValue,
        displayDate: jazzAppreciationMonth.displayDate,
        celebrationImage: jazzCover,
        data: jazzAppreciationMonth,
      },
      {
        celebrationTitle: cincoDeMayo.celebrationTitle,
        startDate: cincoDeMayo.startDate,
        endDate: cincoDeMayo.endDate,
        filterValue: cincoDeMayo.filterValue,
        displayDate: cincoDeMayo.displayDate,
        celebrationImage: cincoDeMayoCover,
        data: cincoDeMayo,
      },
      {
        celebrationTitle: merrieMonarchFestival.celebrationTitle,
        startDate: merrieMonarchFestival.startDate,
        endDate: merrieMonarchFestival.endDate,
        filterValue: merrieMonarchFestival.filterValue,
        displayDate: merrieMonarchFestival.displayDate,
        celebrationImage: merrieMonarchCover,
        data: merrieMonarchFestival,
      },
      {
        celebrationTitle: arabAmericanHistoryMonth.celebrationTitle,
        startDate: arabAmericanHistoryMonth.startDate,
        endDate: arabAmericanHistoryMonth.endDate,
        filterValue: arabAmericanHistoryMonth.filterValue,
        displayDate: arabAmericanHistoryMonth.displayDate,
        celebrationImage: arabAmericanHistoryCover,
        data: arabAmericanHistoryMonth,
      },
      {
        celebrationTitle: jordanianIndependenceDay.celebrationTitle,
        startDate: jordanianIndependenceDay.startDate,
        endDate: jordanianIndependenceDay.endDate,
        filterValue: jordanianIndependenceDay.filterValue,
        displayDate: jordanianIndependenceDay.displayDate,
        celebrationImage: jordanianIndependenceCover,
        data: jordanianIndependenceDay,
      },
      {
        celebrationTitle: raraCarnival.celebrationTitle,
        startDate: raraCarnival.startDate,
        endDate: raraCarnival.endDate,
        filterValue: raraCarnival.filterValue,
        displayDate: raraCarnival.displayDate,
        celebrationImage: raraCarnivalCover,
        data: raraCarnival,
      },
      {
        celebrationTitle: eidAlFitr.celebrationTitle,
        startDate: eidAlFitr.startDate,
        endDate: eidAlFitr.endDate,
        filterValue: eidAlFitr.filterValue,
        displayDate: eidAlFitr.displayDate,
        celebrationImage: eidAlFitrCover,
        data: eidAlFitr,
      },
      {
        celebrationTitle: hampartsoum.celebrationTitle,
        startDate: hampartsoum.startDate,
        endDate: hampartsoum.endDate,
        filterValue: hampartsoum.filterValue,
        displayDate: hampartsoum.displayDate,
        celebrationImage: hampartsoumCover,
        data: hampartsoum,
      },
      {
        celebrationTitle: africaDay.celebrationTitle,
        startDate: africaDay.startDate,
        endDate: africaDay.endDate,
        filterValue: africaDay.filterValue,
        displayDate: africaDay.displayDate,
        celebrationImage: africaDayCover,
        data: africaDay,
      },
      {
        celebrationTitle: internationalChildrensDay.celebrationTitle,
        startDate: internationalChildrensDay.startDate,
        endDate: internationalChildrensDay.endDate,
        filterValue: internationalChildrensDay.filterValue,
        displayDate: internationalChildrensDay.displayDate,
        celebrationImage: internationalChildrensDayCover,
        data: internationalChildrensDay,
      },
      {
        celebrationTitle: festaJunina.celebrationTitle,
        startDate: festaJunina.startDate,
        endDate: festaJunina.endDate,
        filterValue: festaJunina.filterValue,
        displayDate: festaJunina.displayDate,
        celebrationImage: fesaJuninaCover,
        data: festaJunina,
      },
      {
        celebrationTitle: kamehamehaDayParade.celebrationTitle,
        startDate: kamehamehaDayParade.startDate,
        endDate: kamehamehaDayParade.endDate,
        filterValue: kamehamehaDayParade.filterValue,
        displayDate: kamehamehaDayParade.displayDate,
        celebrationImage: kamehamehaParadeCover,
        data: kamehamehaDayParade,
      },
    ],
  },
  {
    sectionTitle: 'Summer',
    sectionDateRange: 'June 20–September 21',
    filterItems: [
      {
        celebrationTitle: hipHopCelebrationDay.celebrationTitle,
        startDate: hipHopCelebrationDay.startDate,
        endDate: hipHopCelebrationDay.endDate,
        filterValue: hipHopCelebrationDay.filterValue,
        displayDate: hipHopCelebrationDay.displayDate,
        celebrationImage: hipHopCover,
        data: hipHopCelebrationDay,
      },
      {
        celebrationTitle: greenCornCeremony.celebrationTitle,
        startDate: greenCornCeremony.startDate,
        endDate: greenCornCeremony.endDate,
        filterValue: greenCornCeremony.filterValue,
        displayDate: greenCornCeremony.displayDate,
        celebrationImage: greenCornCeremonyCover,
        data: greenCornCeremony,
      },
      {
        celebrationTitle: fiestasDeSantiagoApostol.celebrationTitle,
        startDate: fiestasDeSantiagoApostol.startDate,
        endDate: fiestasDeSantiagoApostol.endDate,
        filterValue: fiestasDeSantiagoApostol.filterValue,
        displayDate: fiestasDeSantiagoApostol.displayDate,
        celebrationImage: fiestasDeSantiagoApostolCover,
        data: fiestasDeSantiagoApostol,
      },
      {
        celebrationTitle: womensDaySouthAfrica.celebrationTitle,
        startDate: womensDaySouthAfrica.startDate,
        endDate: womensDaySouthAfrica.endDate,
        filterValue: womensDaySouthAfrica.filterValue,
        displayDate: womensDaySouthAfrica.displayDate,
        celebrationImage: womensDaySouthAfricaCover,
        data: womensDaySouthAfrica,
      },
      {
        celebrationTitle: jerashFestival.celebrationTitle,
        startDate: jerashFestival.startDate,
        endDate: jerashFestival.endDate,
        filterValue: jerashFestival.filterValue,
        displayDate: jerashFestival.displayDate,
        celebrationImage: jerashFestyivalCover,
        data: jerashFestival,
      },
      {
        celebrationTitle: mariamoba.celebrationTitle,
        startDate: mariamoba.startDate,
        endDate: mariamoba.endDate,
        filterValue: mariamoba.filterValue,
        displayDate: mariamoba.displayDate,
        celebrationImage: mariamobaCover,
        data: mariamoba,
      },
    ],
  },

];
