import soulScienceImage from '../assets/hotspot/headerImages/soul-science-lab-l.jpg';
import coverPhoto from '../assets/hotspot/celebrationCover/hip-hop.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/soul-science-hip-hop.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/soul-science-lab-hiphophooray-concert.png';

export const hipHopCelebrationDay = {
  celebrationTitle: 'Hip Hop Celebration Day',
  startDate: '08-11',
  endDate: '08-11',
  filterValue: 'hipHopCelebrationDay',
  celebrationImage: coverPhoto,
  displayDate: 'August 11',
  description: 'In 2021 the United States Senate passed a bill to commemorate the history of hip hop, paying tribute to its founding in the Bronx and its characteristic elements of disk jockey, a master of ceremonies, music, art, fashion, and dance. ',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/nhQCR6mrAaw?si=HZ7KEnu0ovkViRTA',
      videoImage: celebrationThumbnail1,
      songTitle: 'Hip Hop Hooray',
      artistName: 'Soul Science Lab',
      videoTags: [
        {
          tag: 'Hip Hop Celebration Day',
        },
      ],
      songDescription: '“Hip Hop Hooray” is a famous hip hop song recorded by Naughty by Nature in 1992. Chen Lo and Asanté from Soul Science Lab took the hook and underlying beat from this song and adapted it, adding their own lyrics. The lyrics acknowledge hip hop’s roots in jazz, blues, and rock ‘n’ roll, and social justice.',
    },
    {
      videoLink: 'https://youtu.be/c0wRraErszg',
      videoImage: celebrationThumbnail2,
      songTitle: 'Hip Hop Hooray',
      artistName: 'Soul Science Lab',
      videoTags: [
        {
          tag: 'Hip Hop Celebration Day',
        },
      ],
      songDescription: '“Hip Hop Hooray” is a famous hip hop song recorded by Naughty by Nature in 1992. Chen Lo and Asanté from Soul Science Lab took the hook and underlying beat from this song and adapted it, adding their own lyrics. The lyrics acknowledge hip hop’s roots in jazz, blues, and rock ‘n’ roll, and social justice.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Soul Science Lab',
      artistId: 'soul-science-lab',
      artistImage: soulScienceImage,
    },
  ],
};
