import coverPhoto from '../assets/hotspot/celebrationCover/africa-day.jpg';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/makobi_kothbiro_video.png';
import makobiImage from '../assets/hotspot/headerImages/makobi-l.jpg';

export const africaDay = {
  celebrationTitle: 'Africa Day',
  startDate: '05-25',
  endDate: '05-25',
  filterValue: 'africaDay',
  displayDate: 'May 25',
  celebrationImage: coverPhoto,
  description: 'Africa Day is an annual celebration of the founding of the Organization of African Unity (now replaced by the African Union). Every year the African Union sets a theme for the upcoming year and goals for a better quality of life for all Africans.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/TSbQc4Fci6k',
      videoImage: celebrationThumbnail,
      songTitle: 'Koth Biro',
      artistName: 'Makobi',
      videoTags: [
        {
          tag: 'Africa Day',
        },
        {
          tag: 'Utamaduni Day',
        },
      ],
      songDescription: 'Sung in Dholuo, the language of the Luo people of Western Kenya, “Koth Biro” is one of the most well known songs by legendary Kenyan musician Ayub Ogada. The song reimagines traditional Kenyan  melodies and rhythm, accompanied by the nyatiti, a plucked lyre prominently played by the Luo people of Western Kenya.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Makobi',
      artistId: 'makobi',
      artistImage: makobiImage,
    },
  ],
};
