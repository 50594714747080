// @flow

import React, { useEffect } from 'react';
import { Filter } from '../Filter';


import {
  FilterContainer,
  HeaderContainer,
  HeaderInner,
  HeaderTextButton,
  HeaderTextContainer,
  HeaderTextContainerDesktop,
  ActiveCelebration,
  ClearFiltersButton,
} from './style';
import { CelebrationsFilter } from '../Filter/CelebrationsFilter';
import { CelebrationPill } from '../CelebrationPill';

type Props = {
  history: Object,
  handleHeaderClick: Function,
  handleFilterClick: Function,
  selectedTraditionsFilter: string[],
  setSelectedTraditionsFilter: Function,
  selectedCelebrationsFilter: Object,
  setSelectedCelebrationsFilter: Function,
  setActiveFilter: Function,
  activeArtist: Object,
}

const HeaderText = 'Musical Explorers';
const HeaderTextDesktop = ' Around the World Map';

export const Header = ({
  history,
  handleHeaderClick,
  handleFilterClick,
  selectedTraditionsFilter,
  setSelectedTraditionsFilter,
  selectedCelebrationsFilter,
  setSelectedCelebrationsFilter,
  setActiveFilter,
  activeArtist,
}: Props) => {
  const [showFilters, setShowFilters] = React.useState(true);
  const MOBILE_SIZE = 720;
  const isMobile = window.innerWidth <= MOBILE_SIZE;
  const container = document.getElementById('react-transform-element');


  const showSelectedCelebration = (filter) => {
    if (filter.celebrationTitle === 'all') {
      setShowFilters(true);
    } else {
      setShowFilters(false);
    }
  };

  const shouldShowHeaderText = !isMobile || selectedCelebrationsFilter.filterValue === 'all';


  useEffect(() => {
    showSelectedCelebration(selectedCelebrationsFilter);
  }, [selectedCelebrationsFilter]);

  useEffect(() => {
    if (selectedTraditionsFilter.length > 0 && selectedTraditionsFilter[0] !== 'all') {
      setActiveFilter('traditions');
    } else if (selectedCelebrationsFilter.filterValue !== 'all') {
      setActiveFilter('celebrations');
    } else {
      setActiveFilter('none');
    }
  }, [selectedTraditionsFilter, selectedCelebrationsFilter, setActiveFilter]);


  const handleResetFilters = () => {
    setSelectedCelebrationsFilter({
      celebrationTitle: 'all', dateRange: '', filterValue: 'all', displayDate: '',
    });
    setSelectedTraditionsFilter(['all']);
    setActiveFilter('none');
    if (container && !activeArtist) {
      // Get the current z value
      const matrix = window.getComputedStyle(container).transform;
      const values = matrix.split('(')[1].split(')')[0].split(',');
      const z = values.length > 2 ? parseFloat(values[2]) : 0;

      // Reset x and y, keep z the same
      container.style.transform = `translate3d(0, 0, ${z}px)`;
    }
  };

  const resetTraditionsFilter = () => {
    setSelectedTraditionsFilter(['all']);
    handleFilterClick();
    setActiveFilter('none');
  };


  return (
    <HeaderContainer>
      <HeaderInner>
        <FilterContainer>
          {showFilters ? (
            <>
              <Filter
                selectedFilter={selectedTraditionsFilter}
                setSelectedFilter={setSelectedTraditionsFilter}
                handleFilterResetClick={handleFilterClick}
              />
              <CelebrationsFilter
                selectedFilter={selectedCelebrationsFilter}
                setSelectedFilter={setSelectedCelebrationsFilter}
                handleFilterResetClick={handleFilterClick}
                resetTraditionsFilter={resetTraditionsFilter}
              />
            </>
          ) : (
            <ActiveCelebration>
              <ClearFiltersButton onClick={handleResetFilters} type='button'>
                <svg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none'>
                  <path fillRule='evenodd' clipRule='evenodd' d='M14.3195 2.43132L12.7285 0.840332L7.15975 6.4091L1.59099 0.840345L0 2.43134L5.56876 8.00009L0.000593781 13.5683L1.59158 15.1592L7.15975 9.59108L12.7279 15.1593L14.3189 13.5683L8.75074 8.00009L14.3195 2.43132Z' fill='#076174' />
                </svg>
              </ClearFiltersButton>
              <CelebrationPill
                celebrationTitle={selectedCelebrationsFilter.celebrationTitle}
                startDate={selectedCelebrationsFilter.startDate}
                endDate={selectedCelebrationsFilter.endDate}
                displayDate={selectedCelebrationsFilter.displayDate}
                isSelected
              />
            </ActiveCelebration>
          )}
        </FilterContainer>
        {shouldShowHeaderText && (
        <HeaderTextButton
          onClick={() => {
            handleHeaderClick();
            history.push('/');
          }}
          aria-label='Reset Musical Explorers map'
        >
          <HeaderTextContainer>
            {HeaderText}
            <HeaderTextContainerDesktop>
              {HeaderTextDesktop}
            </HeaderTextContainerDesktop>
          </HeaderTextContainer>
        </HeaderTextButton>
        )}
      </HeaderInner>
    </HeaderContainer>
  );
};
