import coverPhoto from '../assets/hotspot/celebrationCover/international-childrens-day.jpg';
import vanAnhImage from '../assets/hotspot/headerImages/van-anh-l.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/van-anh-co-doi.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/vananh_codoithuong_concert.png';

export const internationalChildrensDay = {
  celebrationTitle: 'International Children’s Day',
  startDate: '06-01',
  endDate: '06-01',
  filterValue: 'internationalChildrensDay',
  displayDate: 'June 1',
  celebrationImage: coverPhoto,
  description: 'On June 1, countries around the world celebrate International Children’s Day. In Vietnam, families focus on creating fun and relaxing experiences for their children, eating out, going to amusement parks, playing games, and more.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/Wh48vzIbKzo',
      videoImage: celebrationThumbnail1,
      songTitle: 'Cô Đôi Thượng Ngàn',
      artistName: 'Vân-Ánh',
      videoTags: [
        {
          tag: 'International Children’s Day',
        },
      ],
      songDescription: 'The title of the song “Cô Đôi Thượng Ngàn” literally means “Queen of the Forest,” and it is a Northern Vietnamese folk song. The song celebrates the Queen of the Forest, an immortal figure who looks after everyone who needs her help. This song is sung at ceremonies, festivals, rituals at temples, and even at home.',
    },
    {
      videoLink: 'https://youtu.be/jDsP_oNBFYM',
      videoImage: celebrationThumbnail2,
      songTitle: 'Cô Đôi Thượng Ngàn',
      artistName: 'Vân-Ánh',
      videoTags: [
        {
          tag: 'International Children’s Day',
        },
      ],
      songDescription: 'The title of the song “Cô Đôi Thượng Ngàn” literally means “Queen of the Forest,” and it is a Northern Vietnamese folk song. The song celebrates the Queen of the Forest, an immortal figure who looks after everyone who needs her help. This song is sung at ceremonies, festivals, rituals at temples, and even at home.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Vân-Ánh',
      artistId: 'vanAnh',
      artistImage: vanAnhImage,
    },
  ],
};
