// @flow

import React from 'react';
import { HotspotLessonVideo } from '../HotspotLessonVideo';
import { HotspotLessonAudio } from '../HotspotLessonAudio';
import { HotspotLessonImage } from '../HotspotLessonImage';
import { HotspotTwoColumns } from '../HotspotTwoColumn';

import {
  HotspotLessonContainer,
} from './style';

type Props = {
  lessons: Array<any>,
}

// Three types: video, audio, image

export const HotspotLesson = ({ lessons }: Props) => (
  <HotspotLessonContainer>
    {lessons.map((lesson) => {
      if (lesson.type === 'audio') {
        return <HotspotLessonAudio key={lesson.id} lesson={lesson} />;
      }
      if (lesson.type === 'video') {
        return (
          <HotspotTwoColumns key={lesson.id}>
            <HotspotLessonVideo lesson={lesson} />
          </HotspotTwoColumns>
        );
      }
      if (lesson.type === 'image') {
        return (
          <HotspotTwoColumns key={lesson.id}>
            <HotspotLessonImage lesson={lesson} />
          </HotspotTwoColumns>
        );
      }
      return null;
    })}
  </HotspotLessonContainer>
);
