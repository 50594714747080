// @flow

import React, {
  useEffect, useState, useRef,
} from 'react';


import {
  HotspotContainer,
  HotspotContent,
  HotspotCloseButton,
} from './style';
import { TraditionHotspot } from '../TraditionHotspot';
import { CelebrationArtistHotspot } from '../CelebrationArtistHotspot';
import { CelebrationHotspot } from '../CelebrationHotspot';
import { findArtistData } from '../../constants/artistDataMap';

type Props = {
  activeArtist: string,
  onClose: Function,
  activeFilter: 'traditions' | 'celebrations' | 'none',
  setActiveFilter: Function,
  selectedCelebration: Object,
  handleNextCelebration: Function,
  handlePrevCelebration: Function,
  setActiveArtist: Function,
};

export const HotSpot = ({
  activeArtist,
  onClose,
  activeFilter,
  setActiveFilter,
  selectedCelebration,
  handleNextCelebration,
  handlePrevCelebration,
  setActiveArtist,
}: Props) => {
  const [activeData, setActiveData] = useState();
  const closeRef = useRef(null);
  const hotSpotRef = useRef(null);
  const hotSpotContentRef = useRef(null);
  const isMobile = window.innerWidth < 720;


  useEffect(() => {
    if (activeArtist && selectedCelebration && activeFilter === 'celebrations') {
      setActiveData(findArtistData(activeArtist));
    } else if (activeArtist && activeFilter !== 'celebrations') {
      setActiveData(findArtistData(activeArtist));
    } else if (selectedCelebration && activeFilter === 'celebrations') {
      setActiveData(selectedCelebration);
    } else {
      setActiveData(null);
    }

    window.scrollTo(0, 0);
    try {
      if (hotSpotContentRef.current) {
        hotSpotContentRef.current.scrollTo(0, 0);
      }
    } catch (err) {
      // handle errors for older browsers or when ref is not available
    }
  }, [activeArtist, selectedCelebration, activeFilter]);

  const handleClose = () => {
    if (activeFilter === 'celebrations' && selectedCelebration) {
      setActiveFilter('celebrations');
      onClose();
    } else if (activeFilter === 'traditions' && selectedCelebration) {
      setActiveFilter('celebrations');
      onClose();
    } else {
      onClose();
    }
  };


  return (
    <HotspotContainer
      role='dialog'
      aria-label='Hotspot'
      id='hotspot'
      ref={hotSpotRef}
      className={isMobile && activeFilter === 'celebrations' ? 'celebrations' : ''}
    >
      {/* {(activeArtist) && ( */}
      <HotspotCloseButton onClick={handleClose} ref={closeRef} aria-label='Close Hotspot'>
          Close Hotspot
      </HotspotCloseButton>
      {/* )} */}
      <HotspotContent ref={hotSpotContentRef}>
        {activeData && activeFilter === 'celebrations' && activeArtist && (
          <CelebrationArtistHotspot
            activeData={activeData}
            selectedCelebration={selectedCelebration.celebrationTitle}
            handleExploreClick={() => setActiveFilter('traditions')}
          />
        )}
        {activeData && (activeFilter === 'traditions' || activeFilter === 'none') && (
          <TraditionHotspot activeData={activeData} />
        )}
        {selectedCelebration && activeFilter === 'celebrations' && activeData !== findArtistData(activeArtist) && (
          <CelebrationHotspot
            selectedCelebration={selectedCelebration}
            handleNextCelebration={handleNextCelebration}
            handlePrevCelebration={handlePrevCelebration}
            setActiveArtist={setActiveArtist}
          />
        )}
      </HotspotContent>
    </HotspotContainer>
  );
};
