import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  ${({ theme: { media } }) => media.desktop`
    position: relative;
    overflow: hidden;
    height: 100%;
    max-width: 1586px;
    width: 100%;
    margin: 0 auto;
  `}
`;
