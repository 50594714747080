// @flow

import React from 'react';
import {
  PillWrapper, CelebrationTitle, DateRange, TextContainer,
} from './style';

type Props = {
  celebrationTitle: string,
  isSelected?: boolean,
  happeningNow?: boolean,
  displayDate?: string,
};

export const CelebrationPill = ({
  celebrationTitle,
  displayDate,
  isSelected,
  happeningNow,
}: Props) => (
  <PillWrapper className={isSelected ? 'selected' : ''}>
    <TextContainer>
      <CelebrationTitle>{celebrationTitle}</CelebrationTitle>
      <DateRange className={happeningNow ? 'active' : ''}>{displayDate}</DateRange>
    </TextContainer>
    {isSelected && (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='20' viewBox='0 0 16 20' fill='none'>
      <path d='M8.56592 17.6059L15.3486 10.8491L8.56592 4.0923L6.57254 6.08568L10.0415 9.37347L0.462954 9.37347L0.462954 12.3247L10.0415 12.3247L6.57254 15.6125L8.56592 17.6059Z' fill='white' />
    </svg>
    )}
  </PillWrapper>
);

CelebrationPill.defaultProps = {
  isSelected: false,
  happeningNow: false,
  displayDate: '',
};
