// @flow

import React from 'react';
import { HotspotVideo } from '../HotspotVideo';

import {
  VideoContent,
  VideoHeadline,
  VideoLessonLink,
  Video,
} from './style';

type Props = {
  lesson: {
    headline: string,
    linkTitle: string,
    linkUrl: string,
    vimeoLink: string,
    videoImage: any,
  }
};

export const HotspotLessonVideo = ({
  lesson: {
    headline,
    linkTitle,
    linkUrl,
    vimeoLink,
    videoImage,
  },
}: Props) => (
  <>
    <VideoContent>
      <VideoHeadline>{headline}</VideoHeadline>
      <VideoLessonLink
        href={linkUrl}
        target='_blank'
        rel='noreferrer'
        title={linkTitle}
      >
        {linkTitle}
      </VideoLessonLink>
    </VideoContent>
    <Video>
      <HotspotVideo
        vimeoLink={vimeoLink}
        videoImage={videoImage}
      />
    </Video>
  </>
);
