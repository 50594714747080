import coverPhoto from '../assets/hotspot/celebrationCover/merrie-monarch-festival.jpg';
import celebrationThumbnail from '../assets/hotspot/celebrationPerformances/kalani-pea-e-na-kini.png';
import kalaniImage from '../assets/hotspot/headerImages/kalani-l.jpg';


export const merrieMonarchFestival = {
  celebrationTitle: 'Merrie Monarch Festival',
  startDate: '03-31',
  endDate: '04-06',
  filterValue: 'merrieMonarchFestival',
  celebrationImage: coverPhoto,
  displayDate: 'Week after Easter',
  description: 'The Merrie Monarch Festival is a week-long Hawaiian cultural festival that takes place the week after Easter. The festival is a celebration of Hawaiian arts and culture, especially hula, with pageantry and the most prestigious hula competition in the world.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/SweNj30zsC0',
      videoImage: celebrationThumbnail,
      songTitle: 'E Nā Kini',
      artistName: 'Kalani',
      videoTags: [
        {
          tag: 'Kamehameha Day Parade',
        },
        {
          tag: 'Merrie Monarch Festival',
        },
        {
          tag: 'Hawaiian Independence Day',
        },
      ],
      songDescription: '“E Nā Kini” was written during a difficult time in Hawaiian history when thousands of individuals isolated in order to stop the spread of Hansen’s disease (also known as leprosy). This song became an anthem of dignity for the native people, and a rallying call to come together with the land during life\'s challenges',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Kalani',
      artistId: 'kalani',
      artistImage: kalaniImage,
    },
  ],
};
