import coverPhoto from '../assets/hotspot/celebrationCover/mariamoba.jpg';
import ilushaImage from '../assets/hotspot/headerImages/ilusha-l.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/ilusha_shinavorgil_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/ilusha_shinavorgil_concert.png';

export const mariamoba = {
  celebrationTitle: 'Mariamoba',
  startDate: '08-28',
  endDate: '08-28',
  filterValue: 'mariamoba',
  displayDate: 'August 28',
  celebrationImage: coverPhoto,
  description: 'Mariamoba is a public Georgian Orthodox holiday that celebrates Saint Mary. For two weeks prior to the holiday, Georgians do not eat meat, fish, dairy, and eggs, and then they break the fast in a huge feast on Mariamoba.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/bSoc5tECE9c',
      videoImage: celebrationThumbnail1,
      songTitle: 'Shina Vorgil',
      artistName: 'Ilusha',
      videoTags: [
        {
          tag: 'Mariamoba',
        },
      ],
      songDescription: 'Although Georgia is a very small country, the regions within it have their own distinct identities. “Shina Vorgil” comes from Svaneti, a mountainous region with a long tradition of polyphonic music. The Svan language is only spoken by a handful of people today. So while the music still survives, the lyrics often cannot be translated.',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=MOGzwyXGuYc&t=5s',
      videoImage: celebrationThumbnail2,
      songTitle: 'Shina Vorgil',
      artistName: 'Ilusha',
      videoTags: [
        {
          tag: 'Mariamoba',
        },
      ],
      songDescription: 'Although Georgia is a very small country, the regions within it have their own distinct identities. “Shina Vorgil” comes from Svaneti, a mountainous region with a long tradition of polyphonic music. The Svan language is only spoken by a handful of people today. So while the music still survives, the lyrics often cannot be translated.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Ilusha',
      artistId: 'ilusha',
      artistImage: ilushaImage,
    },
  ],
};
