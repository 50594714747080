import coverPhoto from '../assets/hotspot/celebrationCover/international-womens-day.jpg';
import ilushaImage from '../assets/hotspot/headerImages/ilusha-l.jpg';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/ilusha_shengenatsvale_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/ilusha_shengenatsvale_concert.png';

export const internationalWomensDay = {
  celebrationTitle: 'International Women’s Day',
  startDate: '03-08',
  endDate: '03-08',
  filterValue: 'internationalWomensDay',
  displayDate: 'March 8',
  celebrationImage: coverPhoto,
  description: 'While International Women’s Day is recognized globally, it is treated as a particularly celebrated holiday in Georgia. On this day in Georgia, women get showered in gifts and compliments, and there are feasts, concerts, and speeches in celebration of women.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/My5KlIFWzb0',
      videoImage: celebrationThumbnail1,
      songTitle: 'Shen Genatsvale',
      artistName: 'Ilusha',
      videoTags: [
        {
          tag: 'International Women’s Day',
        },
      ],
      songDescription: 'Ilusha wrote “Shen Genatsvale” for his friends and relatives whom he only gets to see once in a while when he visits Georgia. The refrain “shen genatsvale” embodies a feeling of caring and connection between people that remains strong even when they are separated.',
    },
    {
      videoLink: 'https://youtu.be/MV222TpFBKc',
      videoImage: celebrationThumbnail2,
      songTitle: 'Shen Genatsvale',
      artistName: 'Ilusha',
      videoTags: [
        {
          tag: 'International Women’s Day',
        },
      ],
      songDescription: 'Ilusha wrote “Shen Genatsvale” for his friends and relatives whom he only gets to see once in a while when he visits Georgia. The refrain “shen genatsvale” embodies a feeling of caring and connection between people that remains strong even when they are separated.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Ilusha',
      artistId: 'ilusha',
      artistImage: ilushaImage,
    },
  ],
};
