// @flow

import React from 'react';
import {
  PostcardSectionContainer,
  ThumbnailContainer,
  Image,
  Overlay,
  PostcardCta,
  Subtitle,
} from './style';

type Props = {
  postcard: {
    pdf: string,
    thumbnail?: string,
  },
  name: string,
};

export const HotspotPostcard = ({
  postcard: { pdf, thumbnail },
  name,
}: Props) => (
  <PostcardSectionContainer>
    <ThumbnailContainer>
      <a href={pdf} target='_blank' rel='noopener noreferrer'>
        <Image src={thumbnail} alt='artist postcard preview thumbnail' />
        <Overlay>
          <PostcardCta href={pdf}>{name} Postcard (PDF)</PostcardCta>
          <Subtitle>
            Introduce students to {name} with this digital resource.
          </Subtitle>
        </Overlay>
      </a>
    </ThumbnailContainer>
  </PostcardSectionContainer>
);
