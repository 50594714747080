import styled from 'styled-components/macro';

export const HeaderPicture = styled.picture`
  display: block;

  ${({ theme: { media } }) => media.hotspotPositionChange`
    position: sticky;
    top: 0;
    z-index: 1;
  `}
`;

export const HeaderImg = styled.img`
  width: 100%;
  vertical-align: bottom;
`;
