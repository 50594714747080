import styled from 'styled-components/macro';

export const VideoContent = styled.div`
`;

export const VideoHeadline = styled.h3`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin: 0;
`;

export const VideoLessonLink = styled.a`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-bottom: 1px;
  margin-bottom: 10px;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.alizarinCrimson};
    position: absolute;
    bottom: 0;
  }

  &:focus {
    outline: 1px dashed ${({ theme }) => theme.colors.alizarinCrimson};
  }

  ${({ theme: { media } }) => media.hotspotColumnChange`

  `}
`;

export const Video = styled.div`
  display: grid;

  > * {
    grid-area: 1 / 1 / 2 / 2;
  }

  &::before {
    content:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 9"></svg>');
    grid-area: 1 / 1 / 2 / 2;

    ${({ theme: { media } }) => media.hotspotColumnChange`
      height: 106px;
      width: 100%;
    `}
  }

  ${({ theme: { media } }) => media.hotspotColumnChange`
    height: 106px;
    width: 100%;
  `}
`;
