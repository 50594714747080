// @flow

import React from 'react';

import { HotspotTwoColumnContainer } from './style';

type Props = {
  children: any,
};

export const HotspotTwoColumns = ({ children }: Props) => (
  <HotspotTwoColumnContainer>
    { children }
  </HotspotTwoColumnContainer>
);
