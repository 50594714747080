// @flow

import React from 'react';
import { EXTERNAL_DATA } from '../../constants/external';

import {
  ExternalWrapper,
  ExternalLinkTag,
  ExternalText,
  ExternalTextInner,
  ExternalIcon,
  ExternalSvg,
} from './style';

export const ExternalLink = () => (
  <ExternalWrapper>
    <ExternalLinkTag
      href={EXTERNAL_DATA.link}
      target='_blank'
      rel='noreferrer'
      title={EXTERNAL_DATA.headline}
    >
      <ExternalText>
        <ExternalTextInner>{EXTERNAL_DATA.headline}</ExternalTextInner>
        <ExternalIcon>
          <ExternalSvg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='12'
            fill='none'
            viewBox='0 0 18 12'
          >
            <path stroke='#e22237' strokeWidth='3' d='M0 6h16' />
            <path
              fill='#e22237'
              stroke='#e22237'
              d='M11 1.759L17.122 6 11 10.242V1.758z'
            />
          </ExternalSvg>
        </ExternalIcon>
      </ExternalText>
    </ExternalLinkTag>
  </ExternalWrapper>
);
