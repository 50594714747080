import styled from 'styled-components/macro';

export const HotspotLessonContainer = styled.div`
  & > * {
    :first-child {
      background-color: ${({ theme }) => theme.colors.alabaster};
    }
  }

  ${({ theme: { media } }) => media.hotspotColumnChange``}
`;
