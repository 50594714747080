import smallHeaderImage from '../assets/hotspot/headerImages/magda-s.jpg';
import largeHeaderImage from '../assets/hotspot/headerImages/magda-l.jpg';
import yacoubaPin from '../assets/hotspot/pins/yacouba.jpg';
import faluPin from '../assets/hotspot/pins/falu.jpg';
import introImage from '../assets/hotspot/intro/magda.png';
import postcard from '../assets/hotspot/postcardPdf/Magda_Postcard.pdf';
import postcardThumbnail from '../assets/hotspot/postcardImages/MagdaPostcardThumbnail.jpg';
import concertThumbnail from '../assets/hotspot/concertThumbnail/magda_concert.png';

export const magda = {
  name: 'Magda',
  headerImages: [smallHeaderImage, largeHeaderImage],
  introVideo: {
    headline: 'Meet Magda, who sings Greek folk music!',
    details: '',
    vimeoLink: 'https://vimeo.com/291190565',
    videoImage: introImage,
  },
  concertVideo: {
    videoLink: 'https://www.youtube.com/watch?v=kM_4CQxSeaY',
    videoImage: concertThumbnail,
  },
  exploreLink: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Three/Magda',
  lessons: [
    {
      id: 1,
      type: 'audio',
      headline: 'Listen to Magda Sing “Trata”',
      link: 'Learn “Trata”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Three/Magda/Lesson-1',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/216882578%3Fsecret_token%3Ds-PXTJy&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
    {
      id: 2,
      type: 'audio',
      headline: 'Listen to Magda Sing “Thalassaki Mou”',
      link: 'Learn “Thalassaki Mou”',
      linkUrl: 'https://www.carnegiehall.org/Education/Programs/Musical-Explorers/Digital/Program-Three/Magda/Lesson-2',
      soundcloudEmbed: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/425707596%3Fsecret_token%3Ds-K0lRy&color=%23e12237&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"></iframe>',
    },
  ],
  postCard: {
    pdf: postcard,
    thumbnail: postcardThumbnail,
  },
  relatedArtist: {
    title: 'Magda appears on stage with',
    artists: [
      {
        id: 1,
        image: yacoubaPin,
        name: 'Yacouba',
        query: 'yacouba',
      },
      {
        id: 2,
        image: faluPin,
        name: 'Falu',
        query: 'falu',
      },
    ],
    linkOutText: 'Learn more about Magda in Musical Explorers Program Three.',
    linkOutUrl: 'https://www.carnegiehall.org/Education/Educators/Musical-Explorers/Digital/Program-Three/',
  },
};
