// @flow

import React from 'react';

import {
  Button,
} from './style';

type Props = {
  handleClick: Function,
  text: string,
  mask: string,
  isWide?: boolean,
  state?: boolean,
  classname?: string,
}

export const ControlButton = ({
  handleClick,
  text,
  mask,
  isWide,
  state,
  classname,
}: Props) => (
  <Button
    type='button'
    onClick={(e) => handleClick(e)}
    Mask={mask}
    IsWide={isWide}
    disabled={state}
    className={classname}
  >
    {text}
  </Button>
);

ControlButton.defaultProps = {
  isWide: false,
  state: false,
  classname: '',
};
