// @flow

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import TagManager from 'react-gtm-module';
import { GlobalStyle } from './theme/global';
import { Router } from './router';

import { MapView } from './views/map';

import theme from './theme';

import './main.css';

const tagManagerArgs = {
  gtmId: 'GTM-5FB4VLG',
};

TagManager.initialize(tagManagerArgs);


function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <BrowserRouter>
          <Router>
            {/* $FlowFixMe */}
            <MapView />
          </Router>
        </BrowserRouter>
      </>
    </ThemeProvider>
  );
}

export default App;
