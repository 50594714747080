// @flow

import React from 'react';

import {
  HeaderPicture,
  HeaderImg,
} from './style';

type Props = {
  headerImages: Array<any>,
  name: string,
}

export const HotspotHeader = ({ headerImages, name }: Props) => {
  const mobileImage = headerImages[0];
  const desktopImage = headerImages[1];
  return (
    <HeaderPicture>
      <source type='image/png' media='(min-width: 501px)' srcSet={desktopImage} />
      <source type='image/png' media='(max-width: 500px)' srcSet={mobileImage} />
      <HeaderImg src={desktopImage} alt={name} />
    </HeaderPicture>
  );
};
