import coverPhoto from '../assets/hotspot/celebrationCover/king-quang-trung-festival.jpg';
import vanAnhImage from '../assets/hotspot/headerImages/van-anh-l.png';
import celebrationThumbnail1 from '../assets/hotspot/celebrationPerformances/vananh_hohuihue_video.png';
import celebrationThumbnail2 from '../assets/hotspot/celebrationPerformances/vananh_hohuihue_concert.png';

export const kingQuangTrungFestival = {
  celebrationTitle: 'King Quang Trung Festival',
  startDate: '01-05',
  endDate: '01-05',
  filterValue: 'kingQuangTrungFestival',
  displayDate: 'January 5',
  celebrationImage: coverPhoto,
  description: 'On the fifth day of the Lunar Year, Vietnam honors the victory of King Quang Trung over invaders. The festival includes a procession with colorful palanquins and beating drums, as well as dances and games.',
  celebrationVideos: [
    {
      videoLink: 'https://youtu.be/Tpo6AtJRRo8',
      videoImage: celebrationThumbnail1,
      songTitle: 'Hò Hụi Huế',
      artistName: 'Vân-Ánh',
      videoTags: [
        {
          tag: 'King Quang Trung Festival',
        },
      ],
      songDescription: '“Hò Hụi Huế” is a Central Vietnamese folk song about working collaboratively on the land to build a better future for all. In this song, one person starts to sing and then the others join in.',
    },
    {
      videoLink: 'https://youtu.be/hZwpHiX9LTw',
      videoImage: celebrationThumbnail2,
      songTitle: 'Hò Hụi Huế',
      artistName: 'Vân-Ánh',
      videoTags: [
        {
          tag: 'King Quang Trung Festival',
        },
      ],
      songDescription: '“Hò Hụi Huế” is a Central Vietnamese folk song about working collaboratively on the land to build a better future for all. In this song, one person starts to sing and then the others join in.',
    },
  ],
  artistsWhoCelebrate: [
    {
      artistName: 'Vân-Ánh',
      artistId: 'vanAnh',
      artistImage: vanAnhImage,
    },
  ],
};
