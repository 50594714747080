// @flow

import React from 'react';
import { HotspotHeader } from '../HotspotHeader';
import { HotspotIntroVideo } from '../HotspotIntroVideo';
import { HotspotLesson } from '../HotspotLesson';
import { HotspotPostcard } from '../HotspotPostcard';
import { HotspotConcertVideo } from '../HotspotConcertVideo';

type Props = {
    activeData: Object,
};

export const TraditionHotspot = ({
  activeData,
}: Props) => (
  <>
    {activeData.headerImages
    && (
    <HotspotHeader
      headerImages={activeData.headerImages}
      name={activeData.name}
    />
    )}
    {activeData.introVideo
    && (
    <HotspotIntroVideo
      intro={activeData.introVideo}
      name={activeData.name}
      exploreLink={activeData.exploreLink}
    />
    )}
    {activeData.lessons
    && <HotspotLesson lessons={activeData.lessons} />}
    {activeData.postCard && (
      <HotspotPostcard
        postcard={activeData.postCard}
        name={activeData.name}
      />
    )}
    {activeData.concertVideo && (
      <HotspotConcertVideo
        video={{
          vimeoLink: activeData.concertVideo.videoLink,
          videoImage: activeData.concertVideo.videoImage,
        }}
      />
    )}
  </>
);
